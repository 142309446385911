import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { get } from 'lodash';
import { MultiLangPipe } from 'src/app/pipes/multi-lang.pipe';
import { ApiService } from 'src/app/services/api.service';
import { FcmService } from 'src/app/services/fcm.service';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';

@Component({
  selector: 'app-push-notifications-access',
  templateUrl: './push-notifications-access.component.html',
  styleUrls: ['./push-notifications-access.component.scss'],
})
export class PushNotificationsAccessComponent implements OnInit {
  @Input() data: any = {};
  @Input() config: any = {};
  @Input() controllerCode: string = null;
  @Input() ditemplateId: string = null;
  @Input() environment: string = null;
  prompt: any = 'Push Notifications';
  topics = [];
  form: UntypedFormGroup;
  _token: string = null;
  topicNamePrefix = '';
  permissionGranted: boolean = false; //user has already granted permissions
  topicLoadingMap = new Map();
  inProgress:boolean = false;
  LOCAL_STORAGE_KEY = 'qliktag-push-notifications-subscriptions';

  get topicsFormArray(): UntypedFormArray {
    return this.form.get('topics') as UntypedFormArray;
  }
  constructor(public modalCtrl: ModalController,
    public multiLangPipe: MultiLangPipe,
    public fb: UntypedFormBuilder,
    private fcm: FcmService,
    private pushNotificationService: PushNotificationsService,
    private apiService: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private toast: ToastController) { }

  ngOnInit() {
    //console.log('modal', this.config, this.fcm)

    //console.log('data', this.data);
  
    const subscribedTopics = this.getSubscibedTopics(this.ditemplateId);
    
    this.permissionGranted = this.fcm.isSubscribed;

    //console.log('NOTIFICATION STATUS:', Notification.permission);
    
    this.topicNamePrefix = this.getPrefix(this.controllerCode, this.environment, this.ditemplateId);

    this.form = this.fb.group({
      topics: this.fb.array([])
    });

    const { displayDescription = '', topics = [] } = this.config;

    if (displayDescription) {
      this.prompt = this.multiLangPipe.transform(displayDescription);
    }

    if (topics) {

      this.topics = topics.map(({ topicCode, displayDescription = '', identifiers }) => {
        let topic = {
          description: this.multiLangPipe.transform(displayDescription),
          topicCode,
          identifiers
        };
        const fa = this.topicsFormArray as UntypedFormArray;
        let value = false;

        const topicName = this.getTopicName({ topicCode, identifiers}, this.data);
        //console.log({topicName})

        if(subscribedTopics.indexOf(topicName) != -1) {
          value = true;
        }
        fa.push(this.fb.control(value));
        return topic;

      })
    }

   
    //console.log(this.form)

  }

  getPrefix(controllerCode, env, templateId) {
    let prefix = '';
    prefix = `${controllerCode}_${env}_${templateId}`;
    return prefix;
  }

  dismissModal() {

    this.modalCtrl.dismiss();
    this.pushNotificationService.isPushNotificationModalOpen = false;

  }

  onSubmit() {
    const values = this.topicsFormArray.value;
    this.inProgress = true;

    //console.log({values})

    
    //FIRST CHECK FOR TOKEN AND REQUEST PERMISSION
    this.fcm.requestPermission()
      .subscribe((token) => {
        this._token = token;
        let topicNames = [];
        const topicCodes = [];

        this.fcm.isSubscribed = true;
        //console.log(this.topics)
        values.forEach((value, index) => {
          if (value) {
            const topic = this.topics[index];
            topicCodes.push(topic['topicCode']);
            topicNames.push(this.getTopicName(topic, this.data));
          }
        });

        //console.log(this.data);

        if (topicNames.length > 0) {
          this.notificationSubscribe(topicNames, 'FCM', topicCodes);
        } else {
          this.removeTopicFromStorage(this.ditemplateId, []);
        }

        setTimeout(() => {
          this.inProgress = false;
          this.modalCtrl.dismiss();
         
         
        })
        

      }, (error) => {
        console.log("PERMISSION DENIED")
        console.log(error)
        this.modalCtrl.dismiss();
        this._token = null;
        this.inProgress = false;
      });

    //GET TOKEN AND FORMAT TOPIC SUBSCRIPTION
    //SAVE TOKEN AND TOPIC SUBSCRIPTION IN LOCAL STORAGE

  }


  getTopicName({ topicCode, identifiers = [] }, data) {

    
    let topicName = `${this.topicNamePrefix}_${topicCode}`;
   
    if (identifiers) { //topic has identifiers
     
      let names = identifiers.reduce((acc, path) => {

        let value = get(data, path);
        
        if (value != undefined && value != null) {
          //TRANSFORM VALUE (REMOVE SPACES)
          //COMMENTED BY NEIL C
          // ONLY KEYS TO BE USED AS IDENTIFIERS HENCE ELIMINATING THE NEED FOR REMOVAL OF SPACES
          // const valueArr = value.toString().split(' ')
          // if(valueArr) {
          //   acc.push(valueArr.join('_'));
          // }
          
          acc.push(value);

        }
        return acc;

      }, []);

      

      if (names.length) {
        topicName += `_${names.join('_')}`;
      }

    }
    return topicName;
  }




  updateSubscription($event) {
    
    if(!this.permissionGranted) {
      return;
    }

    const code = $event.target.value;
    const index = this.topics.findIndex(({ topicCode }) => topicCode == code);
    let prevStatus = false;
    if(index != -1) {
      prevStatus = this.topicsFormArray.at(index).value
    }
   
    if (code) {

      this.topicLoadingMap.set(code, true);

      const topicNames = [this.getTopicName(this.topics[index], this.data)];

      this.changeDetectorRef.detectChanges();

      

      this.fcm.requestPermission()
        .subscribe((token) => {
          this._token = token;
          const currentStatus = !prevStatus;
          if (currentStatus) {
            //subscribe
            this.notificationSubscribe(topicNames, 'FCM' , [code]);
          } else {
            //unsubscribe
            this.notificationUnsubscribe(topicNames, 'FCM' , code);
          }

         

        }, (err) => {
          console.log('Error', err)
        });


    }

  }



  notificationSubscribe(topicNames = [], serviceType = 'FCM', topicCodes = []) {

    //console.log(topicNames, topicCodes)
    const [topicCode] = topicCodes;
    this.apiService.post('notification/subscribe', {
      'data': {
        deviceToken: this._token,
        serviceType,
        topicNames
      }
    }).subscribe((res) => {

      this.addTopicToStorage(this.ditemplateId, topicNames);
  
      if(topicCode) {
        this.topicLoadingMap.set(topicCode, false);
        this.changeDetectorRef.detectChanges();
      }

      this.makeToast('Subscribed Successfully')

    },(err) => {console.log(err)
      if(topicCode) {
        this.resetTopic(topicCode);
        this.topicLoadingMap.set(topicCode, false);
        this.changeDetectorRef.detectChanges();
      }
    })

  }

  notificationUnsubscribe(topicNames, serviceType = 'FCM', topicCode?) {

    //console.log({topicNames })
    this.apiService.post('notification/unsubscribe', {
      'data': {
        deviceToken: this._token,
        serviceType,
        topicNames
      }
    }).subscribe((res) => {
      if(topicCode) {
        this.topicLoadingMap.set(topicCode, false);
        this.changeDetectorRef.detectChanges();
        this.removeTopicFromStorage(this.ditemplateId, topicNames);
        this.makeToast('Unsubscribed Successfully');
      }
    },(err) => {
     
      if(topicCode) {
        this.resetTopic(topicCode);
        this.topicLoadingMap.set(topicCode, false);
        this.changeDetectorRef.detectChanges();
       
      }
    })

  }



  isTopicLoading(topicCode) {
    return this.topicLoadingMap.get(topicCode) ? true : false;
  }


  onCancel() {
    this.modalCtrl.dismiss();
  }

  resetTopic(code) {
   
    const index = this.topics.findIndex(({ topicCode }) => topicCode == code);
   
    if(index != -1) {
      const fa = this.topicsFormArray.at(index);
      const value = fa.value;
      fa.setValue(!value);
      this.changeDetectorRef.detectChanges();
    }
  }


  async makeToast(message, config?) {
    const settings = {
      duration: 3000,
      position: "top",
      message,
      ...config
    }
    const toast = await this.toast.create(settings);

    toast.present();
    

  }

  getSubscibedTopics(templateId) {
      let data = [];
      const storage = localStorage.getItem(this.LOCAL_STORAGE_KEY);
      if(storage) {

        const item = JSON.parse(storage);

        if(item.hasOwnProperty(templateId)) {
          data = item[templateId]['topics'];
        }

      }

      return data;
  }

  addTopicToStorage(templateId, topicNames = []) {

    //console.log(templateId, topicCodes);

    try {
      let storage = localStorage.getItem(this.LOCAL_STORAGE_KEY);
      if(!storage) {
        const item = {}
        localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(item));
        storage = localStorage.getItem(this.LOCAL_STORAGE_KEY);
      }

      const data = JSON.parse(storage);
      let topics  = [];
      if(data.hasOwnProperty(templateId)) {
        topics = [...data[templateId]['topics'], ...topicNames];


      } else {
        topics = [...topicNames];
      }
    
      data[templateId] = {
        topics: []
      }

      data[templateId]['topics'] = Array.from(new Set(topics));//make unique

      localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));

    } catch(e) {
      console.log("ADD STORAGE ERROR", e)
    }

    



  }

  removeTopicFromStorage(templateId, topicNames = []) {

    //console.log({templateId, topicCode});
    try {

      let storage = localStorage.getItem(this.LOCAL_STORAGE_KEY);
      if(storage) {
        const data = JSON.parse(storage);
        let topics  = [];
        if(data.hasOwnProperty(templateId)) {
          topics = data[templateId]['topics'];
          
          let updatedTopics = [];

          if(topicNames.length > 0) {
            updatedTopics = topics.filter((topic) => topicNames.indexOf(topic) == -1);
          }
         
  
          data[templateId]['topics'] = updatedTopics;
          localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
        }
      }

    } catch(e) {
      console.log("REMOVE STORAGE ERROR", e)
    }

   

    return
  }

}
