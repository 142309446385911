import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: 'root'
})
export class FcmService {


  isSubscribed: boolean = false //PERMISSION GRANTED TO RECEIVE PUSH NOTIFICATIONS

  constructor(private afMessaging: AngularFireMessaging) { }

  requestPermission() {
    return this.afMessaging.requestToken

  }


  onMessage() {
    return this.afMessaging.messages
  }




}
