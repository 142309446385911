import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../../services/page.service';
import { PopoverController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MultiLangPipe } from '../../pipes/multi-lang.pipe'


export interface LanguageLocale {
  code: string;
  value?: string; 
  name: Array<any>;
  rtl?: boolean;
}
interface SelectOptionInterface {
  label: string;
  value: string;
}

interface LanguageSelectorInterface {
  name: string;
  label: string;
  selectOptions?: Array<SelectOptionInterface>;
  class?: string;
  type?:string; //dropdown OR ...
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  @Input() config: LanguageSelectorInterface;
  selectedValue = 'en';

  langOptions: LanguageLocale[];
  options$: Observable<LanguageLocale[]>;
  searchControl: UntypedFormControl;

  languages?: any[];
  currentLang: string;
  templateLanguages: any[];
  defaultLang: string = 'en';
  
  constructor(
    private pageService: PageService, 
    private popoverController: PopoverController,
    private multiLangPipe: MultiLangPipe) {
      this.searchControl = new UntypedFormControl();
  }

  /**
   * Language Selection Component
   * @author Abhishek
   * @task QLIKTAG-2895
   */
  ngOnInit() {

    if (!this.templateLanguages.length) {
      this.templateLanguages  =  this.languages.map(function(language) {
        return language.code;
      });
    }

    this.options$       =   this.getOptions()

    this.options$.subscribe(option => this.formattedLangOptions = option)

    this.loadOptions();
  }

  /**
   * [Method to load language codes and store in temp formattedLangOptions and return observable]
   * @return Observable<Language List>
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  getOptions(): Observable<LanguageLocale[]> {
    const templateLanguages = this.templateLanguages.filter(lang => lang).map(lang => lang.toLowerCase());
    const items: LanguageLocale[]   =   this.languages.reduce((acc, language) => {
      if (language.code && templateLanguages.includes(language.code.toLowerCase())) {
        const value   = this.multiLangPipe.transform(language.name, 'en');
        if (value) {
          acc.push(language);
        }
      }
      return acc;
    }, []);
    return of(items);
  }

  set formattedLangOptions(options) {
    this.langOptions = options;
  }

  get formattedLangOptions() {
    return this.langOptions
  }

  /**
   * [Method to load language codes and subscribe to filterOptions on search value changes]
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  loadOptions() {
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe((term?: string) => {
      this.options$   = this.filterOptions(term);
    });
  }

  /**
   * [Method to filter language codes and return Observable]
   * @return Observable<Language List>
   * @param term?: string
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  private filterOptions(term?: string): Observable<LanguageLocale[]>  {

    let items: LanguageLocale[]   = this.formattedLangOptions;
    if (term) {
      items = items.filter(x => x.value.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1);
    }
    return of(items);
  }

  /**
   * [Method to get changed language from language selector]
   * @return nothing
    * @task QLIKTAG-3253
   * @author Abhishek
   */
  onSelect(languageCode: string = null) {
    this.pageService.onLanguageChange(languageCode);
    this.dismissLanguagePopover();
  }

  /**
   * [Method to dismiss popover language selector]
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  dismissLanguagePopover() {
    if (this.popoverController) {
      this.popoverController.dismiss().then(() => { this.popoverController = null; });
    }
  }


  /**
   * Method to log selected value
   * @author Abhishek
   * @task QLIKTAG-2895
   * @param SelectedValue - value from dropdown selection
   */
  onChange(SelectedValue){
    console.log("Selected Value [onChange]:", SelectedValue);
  }

}
