import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { decompressWebUri, isCompressedWebUri } from '../../assets/js/GS1DigitalLinkCompression';
import { ApiService } from '../services/api.service';
import { PageService } from '../services/page.service';
import { AssetTypePipe } from '../pipes/asset-type.pipe';
import { AppService } from '../services/app.service';

enum NFCTypes {
  hid = 'hid_trusted_tag',
  ntag = 'ntag_424_dna'
}
const nfcMockObject = {
  data: {
    authenticityStatus: false,
    authenticityCode: '0010',
    description: ''
  }
};
@Injectable({
  providedIn: 'root'
})
export class InteractionTemplateResolver implements Resolve<any> {

  route: ActivatedRouteSnapshot;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private assetTypePipe: AssetTypePipe,
    private appService: AppService,
    private pageService: PageService) {
  }

  /**
   * [Resolver to fetch vdi, till vdi is made available component will not be rendered]
   * @param  route [ snapshot object on Current Activated Route ]
   * @param  state [ snapshot object of Current Router State ]
   * @return       [ Observable to that returns vdi object ]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('interaction template');
    // console.log(route);
    //console.log({route})
    this.route = route;
    let result: any = of({});


    // Layout
    const queryParams: Params = route.queryParams;
    const sandbox = route.queryParams.sandbox;
    const iid = route.params.iid || route.queryParams.iid;
    // Note: For conflicting routes with p as eid.
    const eid = (route.params.eid && route.params.eid !== 'p') ? route.params.eid : route.queryParams.eid;

    if (!sandbox) {
      if (route.data.itype === 'lp') {
        const params: any = {};
        if (iid && (eid || queryParams)) { // OR ADDED BY NEILC eid can be optional
          params.iid = iid;
          params.eid = eid;
          result = this.getDI(params, queryParams);
        }
      } else if (route.data.itype === 'dl') {
        const params: any = {};
        const linkTypeId = queryParams.linkTypeId;
        if (eid && linkTypeId) {
          params.eid = eid;
          params.iid = linkTypeId;
          result = this.getDI(params, queryParams);
        }
      } else {

        //SHORTCODE + P/ALIAS

        //SHORTCODE + linkTypeID

        //COMPRESSED LINK

        //SHORTCODE
        const { params, queryParams } = route;
        const { shortcode } = params;
        const { linkType, linkTypeId } = queryParams;
        const [childRoute] = route.children;
        const { params: cparams } = childRoute;
        const { alias } = cparams;
        const pathname = location.pathname;
        const url = location.toString();
        // console.log('isCompressed', isCompressedWebUri(url));
        // console.log({pathname})
        //console.log(pathname.split("/"));
        const pathnameArr = pathname.split("/").filter(val => val != "");

        //console.log({pathnameArr})

        const body: Params = {};
        if (shortcode && linkTypeId) { //<instance-id>?linkTypeId=<template-id>
          body.eid = shortcode;
          body.iid = linkTypeId;
          // console.log('getDI');
          result = this.getDI(body, queryParams);
          //console.log({result})
        } else if (shortcode && alias) { //<short-code>/p/page-name
          body.shortcode = shortcode;
          result = this.getDIByShortcode(params, queryParams);
        } else if (pathnameArr.length % 2 == 0) { //<k1>/<v1>/<k2>/<v2>?linkType=<link-type>
          this.pageService.isDigitalLinkInteraction = true;
          const dlparams = {};

          pathnameArr.forEach((value, index) => {
            if (index % 2 == 0) {
              if (value != 'p') {
                dlparams[value] = pathnameArr[index + 1]
              }



            }
          });

          result = this.getDIByDigitalLink(body, { ...queryParams, ...dlparams });

        } else if (isCompressedWebUri(url)) { //<compressed-digital-link>
          const decompressedURL = decompressWebUri(url);
          decompressedURL ? location.href = decompressedURL : result = {};
        } else { //<short-code>
          body.shortcode = shortcode;
          result = this.getDIByShortcode(params, queryParams);
        }

        //DEBUG
        // console.log({route});
        // console.log({params: this.route.params});
        // console.log({children:this.route.children})
        // console.log({state})
        // console.log(state.url)

      }
    }
    return result;
  }



  /**
   * getDI - wraps apiService.getDI and concat with NFC call
   * @param params
   * @param queryParams
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
  private getDI(params, queryParams) {
    return this.apiService.getDI(params, queryParams)
      .pipe(
        map((vdi) => {
          return vdi;
        }),
        concatMap(vdi => {
          const { diLayout, diData } = vdi;
          return this.getNFCData(diLayout, diData).pipe(
            map(nfc => {
              const result = {
                vdi,
                ...(nfc ? { nfc } : {})
              };
              return result;
            })
          );
        })
      );
  }

  /**
   * getDIByShortcode - wraps apiService.getDIByShortcode and concat with NFC call
   * @param params
   * @param queryParams
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
  private getDIByShortcode(params, queryParams) {
    return this.apiService.getDIByShortCode(params, queryParams)
      .pipe(
        map((vdi) => {
          return vdi;
        }),
        concatMap(vdi => {
          const { diLayout, diData } = vdi;
          return this.getNFCData(diLayout, diData).pipe(
            map(nfc => {
              const result = {
                vdi,
                ...(nfc ? { nfc } : {})
              };
              return result;
            })
          );
        })
      );
  }

  /**
   * getDIByDigitalLink - 
   * @param params
   * @param queryParams
   */
  private getDIByDigitalLink(params: Params = {}, queryParams: Params = {}) {

    return this.apiService.getDIByDigitalLink(params, queryParams)
      .pipe(
        map((vdi) => {
          return vdi;
        }),
        concatMap(vdi => {
          const { diLayout, diData } = vdi;
          return this.getNFCData(diLayout, diData).pipe(
            map(nfc => {
              const result = {
                vdi,
                ...(nfc ? { nfc } : {})
              };
              if (result.vdi.hasOwnProperty('redirectionUrl') && result.vdi.diData) {
                const attributeRegex = /{{(.*?)}}/g;
                const attributeList = [];
                let match;
                while ((match = attributeRegex.exec(result.vdi.redirectionUrl)) !== null) {
                  attributeList.push(match[1]);
                }

                for (let i in attributeList) {
                  let path = attributeList[i].split(".");
                  let entityInformation = {
                    data: result.vdi["diData"]
                  }
                  let asset = this.appService.findObjectByPath(entityInformation, path);
                  let urlKey;
                    if (typeof (asset) == 'object' && asset?.length) {

                      urlKey = this.assetTypePipe.transform(asset[0].value);

                    } else {
                      urlKey = this.assetTypePipe.transform(asset);
                    }
                    result.vdi.redirectionUrl = result.vdi.redirectionUrl.replace(new RegExp(`{{${attributeList[i]}}}`, 'g'), urlKey);
                  
                }
              }
              return result;
            })
          );
        })
      );
  }

  /**
   * Check conditions and make nfc validation call.
   * @param route
   * @param vdi
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
  getNFCData(diLayout: { [key: string]: any } = {}, diData: { [key: string]: any } = {}): Observable<any> {
    // console.log('GET NFC DATA');
    // console.log(diLayout);
    // Params
    const { advanced = null } = diLayout || {};
    if (advanced) {
      const { nfc: { isNFCEnabled = false, nfcAuthentication = null } = {} } = advanced;
      if (isNFCEnabled && nfcAuthentication) {
        const { type, hidTagDetails, nTagDetails } = nfcAuthentication;
        if (type === NFCTypes.hid && hidTagDetails) {
          const { hidUsername, hidPassword, url } = hidTagDetails;
          if (hidUsername && hidPassword) {
            const { tac, tagID } = this.route.queryParams;
            if (tac && tagID) {
              const body = {
                data: {
                  type: type,
                  hidTagDetails: {
                    tac,
                    tagID,
                    hidUsername,
                    hidPassword,
                    ...(url ? { url: url } : {})  // dont send if falsy
                  }
                }
              };
              return this.nfcValidate(body).pipe(
                map(response => {
                  return { nfc: { [NFCTypes.hid]: response } };
                })
              );
            }
          }
        } else if (type === NFCTypes.ntag && nTagDetails) {
          const { tagIdAttributeJsonPath, piccKey, cmacKey } = nTagDetails;
          const piccData = this.route.queryParams[piccKey];
          const cmac = this.route.queryParams[cmacKey];
          const { entityCode } = diData;
          const { [`_${entityCode}Id`]: entityInstanceId } = diData;
          if (piccData && cmac && entityCode && entityInstanceId) {
            const body = {
              data: {
                type: type,
                nTagDetails: {
                  ...(tagIdAttributeJsonPath ? { tagIdAttributeJsonPath } : {}),
                  piccData,
                  cmac,
                  entityCode,
                  entityInstanceId
                }
              }
            };
            return this.nfcValidate(body).pipe(
              map(response => {
                return { nfc: { [NFCTypes.ntag]: response } };
              })
            );
          }
        }
      }
    }
    return of(null);
  }


  /**
   * NFC validate API call.
   * @param body
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
  nfcValidate(body = {}) {
    // console.log('NFC VALDATE');
    return this.apiService.post('util/security/authenticity/validation', body).pipe(
      map(response => {
        if (response.hasOwnProperty('data')) {
          return { data: response.data };
        } else {
          return nfcMockObject;
        }
      }),
      catchError(error => {
        console.log(error);
        return of(nfcMockObject);
      }));
  }
}
