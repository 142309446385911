import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DI_DATA } from '../mocks/mock';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private router: Router, private http: HttpClient, private pageService: PageService) { }

  /**
   * [getDI for the given parameters from api]
   * @param  params [description]
   * @return        [description]
   * @author SapnilP
   * @task QLIKTAG-2925
   */
  getDI(params: any, queryParams: Params = {}): Observable<any> {
    let httpParams = new HttpParams({ fromObject: queryParams });
    httpParams = httpParams.delete('eid').delete('iid');

    let urlParams = `${params.iid}`;
    if (params.eid) {
      urlParams = `${urlParams}/${params.eid}`;
    }

    //debug starts
    // const response = DI_DATA;
    // const { data } = response
    // return of(
    //   {
    //     status: response.status,
    //     diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
    //     diData: data.entityInformation,
    //     customGetAPIData: data.customGetAPI,
    //     diLanguages: data.diLanguages,
    //     diPrimaryLanguage: data.diPrimaryLanguage,
    //     environment: data.environment,
    //     controllerCode: data.controllerCode,
    //     ditemplateId: data.ditemplateId,

    //   }
    // )
    //debug ends

    return this.get(`di/data/template/${urlParams}`, httpParams)
      .pipe(
        map(response => {
          const data = response.data;
          return {
            status: response.status,
            diLayout :  queryParams['preview'] === 'true' && response.status === 'activated' ? data.draftLayout :response.status !== 'activated' ? data.inactiveLayout : data.layout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            environment: data.environment,
            controllerCode: data.controllerCode,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          };
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
  }

  /**
   * [ getDIByShortCode - Get template and entity data when shortcode is present ]
   * @param  params [ params object ]
   * @return        [ vdi as Observable ]
   * @author SapnilP
   * @task QLIKTAG-3040
   */
  getDIByShortCode(params: any, queryParams: Params = {}): Observable<any> {
    const httpParams = new HttpParams({ fromObject: queryParams });
    return this.get(`di/data/${params.shortcode}`, httpParams)
      .pipe(
        map(response => {
          const data = response.data;
          return {
            status: response.status,
            diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            environment: data.environment,
            controllerCode: data.controllerCode,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          };
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
  }

  /**
   * getDIByDigitalLink - retrieve data by digital link
   * @param params
   * @param queryParams
   * @author - SwapnilP
   */
  getDIByDigitalLink(params: Params = {}, queryParams: Params = {}): Observable<any> {
    const httpParams = new HttpParams({ fromObject: queryParams });
    return this.get(`di/data/digitallink`, httpParams)
      .pipe(
        map(response => {
          const data = response.data;
          return {
            status: response.status,
            diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            redirectionUrl: data.redirectionUrl,
            environment: data.environment,
            controllerCode: data.controllerCode,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,



          };
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
  }

  /**
   * [Common error formatting function]
   * @param  error [description]
   * @return       [Observable throwError]
   * @author SapnilP
   * @task QLIKTAG-2925
   */
  private formatErrors(error: any): Observable<any> {
    console.log({ error })

    return throwError(error.error);
  }

  /**
   * [Handle GET requests]
   * @param  path   [api endpoint stub]
   * @param  params [GET request parameters]
   * @return        [Observable]
   * @author SwapnilP
   * @task QLIKTAG-2925
   * @update - Access changed from private to public
   * @author - Abhishek
   */
  public get(path: string, params: HttpParams = new HttpParams(), token?): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    return this.http.get(`${environment.apiUrl}${path}`, { params: params, headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * GET by supplied path
   * @param path 
   * @param params 
   * @param token
   * @param headerAddOn additional http header data.
   * @author Nei C 
   * @returns 
   */
  public getByPath(path: string, params: HttpParams = new HttpParams(), token?, headerAddOn?:Record<string,string>): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    if(headerAddOn){
      Object.keys(headerAddOn).forEach(key=>{
        if(headerAddOn[key]){
          headers = headers.append(key,headerAddOn[key]);
        }
      })
    }
    return this.http.get(`${path}`, { params: params, headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * [Handle POST requests]
   * @param  path [api endpoint stub]
   * @param  body [request body]
   * @return      [Observable]
   * @author Lalitkumar Saindane
   * @task QLIKTAG-3376
   */
  post(path: string, body: Object = {}, token?): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body,
      {
        headers
      }).pipe(catchError(this.formatErrors));
  }

  /**
   * 
   * @param path 
   * @param body 
   * @returns 
   */
  patch(path: string, body: Object = {}, token?) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }

    return this.http.patch(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      {
        headers,
      }
    ).pipe(catchError(this.formatErrors));
  }
}



