//IMPORT DETAIL PAGES
//import { PRODUCT_IMAGES } from './pages/product-image';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import * as moment from 'moment-mini';

import { Subject } from 'rxjs';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { Page } from '../../interfaces/page';
import { CamelToCapitalizePipe } from '../../pipes/camel-to-capitalize.pipe';
import { MultiLangPipe } from '../../pipes/multi-lang.pipe';
import { ShortCodePipe } from "../../pipes/short-code.pipe";
import { AppService } from '../../services/app.service';
import { PageService } from '../../services/page.service';
import { SeoService } from "../../services/seo.service";
import { COC_AGENCY } from "./coc";
import { FOOD_LANG } from './FOOD_LANG';
import { GHS } from './ghs';
import { SUP_LANG } from './SUP_LANG';
import { DRUG_FACTS, FOOD, NON_FOOD, SUPPLEMENT } from './tabs';
import { LinkyPipe } from 'ngx-linky';
interface Config {
  header?: string;
  message?: string;
  target?: string;
  relativeTo?: ActivatedRoute;
  gated?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SmartLabelService {
  public smartLabelEvents$: Subject<{ [key: string]: any }> = new Subject<{ [key: string]: any }>();
  public smartLabelConfig: any; //holds SMARTLABEL's config object of current Page.
  public smartLabelData: any;
  public entityInformation: any = {};
  public isProductEntity: boolean = false;


  //paths to default values
  brandNamePath: string = 'data.modules.BasicProductInformationModuleType.brandNameInformation.brandName';

  categoryPath: string = 'data.modules.BasicProductInformationModuleType.gpcCategoryCode';

  productImageUrlPath: string = 'data.modules.BasicProductInformationModuleType.imageLink';

  productNamePath: string = 'data.modules.BasicProductInformationModuleType.productName';

  netContentPath: string = 'data.modules.ProductQuantityInformationModuleType.netContent';

  nonfoodIngredientInformationPath: string = "data.modules.NonfoodIngredientInformationModuleType";

  //required for footer controls on detail pages
  footerLinksConfig;
  footer1Config: any;
  footer2Config: any;
  headerConfig: any;
  productHeaderConfig: any;
  socialLinksConfig;
  informationAccuracyConfig: any;
  badgesConfig: any;
  constructor(
    private location: Location,
    private alertController: AlertController,
    private router: Router,
    private appService: AppService,
    private pageService: PageService,
    private camelToCapitalizePipe: CamelToCapitalizePipe,
    private multiLangPipe: MultiLangPipe,
    private seoService: SeoService,

    public shortCodePipe: ShortCodePipe,
    public safePipe: SafePipe,
    public linkyPipe: LinkyPipe
  ) { }

  /**
 * get countryOfOrigin information as list-item
 * @author Radhika Agnihotri
 * @task QLIKTAG-3897
 */
  getCountryOfOriginListItem() {
    let result;

    const productOriginInformationModuleTypePath = 'data.modules.ProductOriginInformationModuleType';
    let path: Array<string> = productOriginInformationModuleTypePath.split('.');
    let productOriginInformation = this.appService.findObjectByPath(this.smartLabelData, path);

    let productOriginStatement = '';
    let productProvenanceStatement = '';
    let countryOfOriginStatement = '';
    let statement = '';
    if (productOriginInformation) {
      if (productOriginInformation.hasOwnProperty('countryOfOrigin') && Array.isArray(productOriginInformation.countryOfOrigin)) {
        let countryArray = [];

        productOriginInformation.countryOfOrigin.forEach((country, countryIndex) => {
          let countryCode = this.shortCodePipe.transform(`{{SL.${productOriginInformationModuleTypePath}.countryOfOrigin.${countryIndex}.countryCode | mapTo:listItemName}}`);
          //Handle subdivisionCode condition
          if (country.hasOwnProperty('countrySubdivisionCode') && Array.isArray(country.countrySubdivisionCode)) {
            //if subdivisionCode and countryCode both != '' then countryCode = subdivisionCode, countryCode else countryCode = ''
            country.countrySubdivisionCode.forEach((subDivision, subDivisionIndex) => {
              let subDivisionCode = this.shortCodePipe.transform(`{{SL.${productOriginInformationModuleTypePath}.countryOfOrigin.${countryIndex}.countrySubdivisionCode.${subDivisionIndex} | mapTo:listItemName}}`)
              if (countryCode && countryCode != '' && subDivisionCode && subDivisionCode != '') {
                countryArray.push(`<p>${subDivisionCode}, ${countryCode}</p>`)
              }
            });
          } else {
            if (countryCode && countryCode != '') {
              countryArray.push(`<p>${countryCode}</p>`)
            }
          }
        });
        if (countryArray.length > 0) {
          //console.log("countryArray", countryArray)
          countryOfOriginStatement = countryArray.join('');
        }
      }
      if (productOriginInformation.hasOwnProperty('productOriginStatement')) {
        productOriginStatement = this.multiLangPipe.transform(productOriginInformation['productOriginStatement']).toString();
      }

      if (productOriginInformation.hasOwnProperty('productProvenanceStatement')) {
        productProvenanceStatement = this.multiLangPipe.transform(productOriginInformation["productProvenanceStatement"]).toString();
      }

      if (countryOfOriginStatement && countryOfOriginStatement != '') {
        statement += countryOfOriginStatement;
      }

      if (productOriginStatement && productOriginStatement != '') {
        statement += `<p>${productOriginStatement}</p>`;
      }

      if (productProvenanceStatement && productProvenanceStatement != '') {
        statement += `<p>${productProvenanceStatement}</p>`;
      }
      //console.log("statement", statement)
      //if statement is present, then return list-item
      if (statement && statement != '') {
        result = {
          "ctrlName": "list-item",
          "config": {
            "label": "Country Of Origin",
            "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0",
            "content": statement
          }
        }
      }
    }
    return result;
  }

  /**
   * [ Check if component is gated, if yes calls to presentAlert or navigate directly ]
   * @param  url  [link to navigate to ]
   * @param  optional Config to set header, message of popup
   * @author - SwapnilP
   * @task - QLIKTAG-2890
   */
  public smartLabelRouteHub(url: string, config: Config = {}) {
    // Logic here
    let gated: boolean = false;


    if (config.hasOwnProperty('gated')) {

      if (config.gated) {
        gated = this.isGated(url);
      }

    } else {
      gated = this.isGated(url);
    }

    if (gated) {

      let headerLang = [{
        "languageCode": 'en',
        "value": 'Leaving SmartLabel'
      }, {
        "languageCode": 'fr_CA',
        "value": 'Quitter SmartLabel'
      }, {
        "languageCode": 'fr',
        "value": 'Quitter SmartLabel'
      }];
      let messageLang = [{
        "languageCode": 'en',
        "value": `You are about to leave SmartLabel and be taken to a branded site. Would you like to continue?`
      }, {
        "languageCode": 'fr_CA',
        "value": `Vous êtes sur le point de quitter SmartLabel et d'être redirigé vers un site de marque. Voulez-vous continuer?`
      }, {
        "languageCode": 'fr',
        "value": `Vous êtes sur le point de quitter SmartLabel et d'être redirigé vers un site de marque. Voulez-vous continuer?`
      }];




      let defaultHeader = 'Leaving SmartLabel';
      let defaultMessage = 'You are about to leave SmartLabel and be taken to a branded site. Would you like to continue?';

      let header, message;


      header = this.multiLangPipe.transform(headerLang);
      message = this.multiLangPipe.transform(messageLang);

      console.log(header, message);

      if (!header || header == '') {
        header = defaultHeader;
      }

      if (!message || message == '') {
        message = defaultMessage;
      }

      config.header = config.header || header;
      config.message = config.message || message;
      this.confirmExit(url, config);
    } else {
      this.routeMe(url, config);
    }
  }

  /**
   * [ Naviagte application to given URL using Router]
   * @param  string url [link to navigate to]
   * @param  optional Config to set header, message of popup
   * @author - SwapnilP
   * @task - QLIKTAG-2890
   */
  private routeMe(url: string, config: any) {
    const target = config.target || '_self';
    if (url) {
      if (url.startsWith('www') || url.startsWith('http') || url.startsWith('mailto') || url.startsWith('tel')) {
        // sanitize url here
        window.open(url, target);
      } else {
        const pageQueryParams = ['resetQueryParams', 'listIndex', 'detail'];
        const segments = this.appService.getSegments(url);
        url = segments.url ? segments.url : '';
        const queryString = segments.queryParams ? segments.queryParams : '';
        const queryParams = this.buildQueryParams(queryString);
        const fragment = segments.fragment ? segments.fragment : null;
        config.queryParams = { ...config.queryParams, ...queryParams };
        if (config.queryParams.resetQueryParams) {
          for (const key of pageQueryParams) {
            config.queryParams[key] = null;
          }
        }
        const lpRoute = this.getLandingPageRoute();
        url = this.location.normalize(Location.joinWithSlash(lpRoute, `${url}`));
        this.router.navigate([url], { queryParams: config.queryParams, queryParamsHandling: 'merge', state: config.data, fragment: fragment, preserveFragment: config.preserveFragment });
      }
    }
  }

  /**
   * [ Creates and displays alert from parameters supplied to it ]
   * @param config - alert config object containing header and message etc.
   * @param url - url for redirecting to after confirmation.
   * @param  optional Config to set header, message of popup
   * @return                      [ Promise ]
   * @author - SwapnilP
   * @task - Qliktag-2890
   */
  private async confirmExit(url: string, config: Config) {

    let defaultCancelTxt = 'Cancel';
    let defaultContinueTxt = 'Continue';

    let cancelTxt, continueTxt;

    let cancelLang = [{
      "languageCode": 'en',
      "value": 'Cancel'
    }, {
      "languageCode": 'fr_CA',
      "value": 'Annuler'
    }, {
      "languageCode": 'fr',
      "value": 'Annuler'
    }];

    let continueLang = [{
      "languageCode": 'en',
      "value": 'Continue'
    }, {
      "languageCode": 'fr_CA',
      "value": 'Continuer'
    }, {
      "languageCode": 'fr',
      "value": 'Continuer'
    }];

    cancelTxt = this.multiLangPipe.transform(cancelLang);
    continueTxt = this.multiLangPipe.transform(continueLang);

    if (!cancelTxt || cancelTxt == '') {
      cancelTxt = defaultCancelTxt;
    }

    if (!cancelTxt || cancelTxt == '') {
      cancelTxt = defaultCancelTxt;
    }

    if (!continueTxt || continueTxt == '') {
      continueTxt = defaultContinueTxt;
    }



    const alert = await this.alertController.create({
      header: config.header,
      message: config.message,
      cssClass: 'smartlabel-alert',
      mode: 'md',
      buttons: [
        {
          text: cancelTxt,
          role: 'cancel',
          cssClass: 'smartlabel-alert-cancel',
          handler: () => {
            // console.log('Custom Cancelled');
          }
        }, {
          text: continueTxt,
          cssClass: 'smartlabel-alert-continue smartlabel-color-blue',
          handler: () => {
            this.routeMe(url, config);
          }
        }
      ]
    });
    await alert.present();
  }

  private isGated(url: string): boolean {
    if (url.startsWith('www') || url.startsWith('http') || url.startsWith('mailto') || url.startsWith('tel')) {
      return true;
    }
    if (this.searchAlias(url) !== this.searchAlias(this.router.url)) {
      return true;
    }
    return false;
  }
  /**
   * [in case of route Exception find alias manually from url]
   * @param  url [url string]
   * @return     [alias found in url or blank]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  searchAlias(url: string): string {
    url = url.split(/[?|#]/)[0];
    const segments = url.split('/');
    const index = segments.indexOf('p');
    let result = '';
    if (index > -1) {
      if ((index + 1) <= segments.length) {
        result = segments[index + 1];
      }
    }
    return result;
  }

  /**
   * [Function to find p in url and return path before /p/:alias]
   * @return [ full string url of a page ]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  getLandingPageRoute(url: string = this.router.url): string {
    let current = url;
    // sb/1/2/p/smartlabel#fragment?q=1 => [sb/1/2/p/smartlabel, fragment, q=1]
    // sb/1/2/p/smartlabel#fragment?q=1 => [sb/1/2/p/smartlabel, q=1]
    // sb/1/2/p/smartlabel => [sb/1/2/p/smartlabel]
    // Splits on both ? and #
    current = current.split(/[?|#]/)[0];
    const segments = current.split('/');
    const index = segments.indexOf('p');
    if (index !== -1) {
      return segments.splice(0, index).join('/');
    } else {
      return segments.join('/');
    }
  }

  /**
   * [buildQueryParams Converts query string to object]
   * @param  queryString [string a=b&c=d]
   * @return             [{a: b, c: d}]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  buildQueryParams(queryString: string) {
    let result = {};
    if (queryString) {
      result = queryString.split('&').reduce((acc, curr) => {
        const [key, val] = curr.split('=');
        acc[key] = val;
        return acc;
      }, {});
    }
    return result;
  }

  /**
   * get Array of productImage url
   * @param imageLinkPath [path to imageLink in data object]
   * @param index [index of image to be displayed as productImage]
   * @task QLIKTAG-2970
   * @author Radhika Agnihotri
   */
  getProductImages(imageLinkPath: string, index?: number): Array<object> {
    //set path as array of stirng according to imageLinkPath
    let path: Array<string> = imageLinkPath.split('.');
    const currentLang = this.pageService.appLang$.getValue();
    //find object by path in given data
    let imageLinks = this.appService.findObjectByPath(this.smartLabelData, path);


    if (imageLinks) {
      if (imageLinks.length > 0) {
        imageLinks = imageLinks.filter(
          (item) => item.hasOwnProperty('imageTypeCode') && item.imageTypeCode !== 'LOGO' && item.imageTypeCode !== 'DRUG_FACTS_PANEL'
        )
          .filter(
            (item) => {
              if (item.hasOwnProperty('languageCode')) {
                const [langCode] = item['languageCode'].filter((lang) => lang.toLowerCase() === currentLang)
                if (langCode) {
                  return item;
                }
              }
            }
          )
      }
      //if index not given send all images
      if (index) {
        return [imageLinks[--index]];
      }
      return imageLinks;
    }
    //if imageLink is undefined, return empty array
    return [];
  }

  /**
   * return array of ingredients bu listPath
   * @param ingredientListPath [nonfoodIngredient path]
   * @task QLIKTAG-2970
   * @author Radhika Agnihotri
   */
  getIngredientsByList(ingredientListPath: string): Array<any> {
    //set path as Array of Strings
    let path: Array<string> = ingredientListPath.split('.');
    //find object by path in given data
    let ingredientsList: Array<any> = this.appService.findObjectByPath(this.smartLabelData, path);
    let result = [];
    if (ingredientsList) {

      result = ingredientsList;
    }
    return result;

  }

  /**
   * return array of ingredients by statement path
   * @param ingredientStatementPath [nonfoodIngredientStatement path]
   * @task QLIKTAG-2970
   * @author Radhika Agnihotri
   */
  getIngredientsByStatement(ingredientStatementPath: string): Array<string> {
    //set path as Array of Strings
    let path: Array<string> = ingredientStatementPath.split('.');
    //find object by path in given data
    let ingredientsList = this.appService.findObjectByPath(this.smartLabelData, path);

    let result = [];
    if (ingredientsList) {
      let ingredients = this.multiLangPipe.transform(JSON.parse(JSON.stringify(ingredientsList)));
      //console.log('getIngredientsByStatement', ingredients)
      if (typeof (ingredients) === 'string') {
        result = ingredients.split(/,(?![^(]*\))/);
      }

    }
    //console.log('getIngredientsByStatement result', result)
    return result;

  }


  /**
   * return gpc category code from smartLabelData obj
   * @param gpcCategoryCodePath [gpcCategoryCode path]
   * @task QLIKTAG-2970
   * @author Radhika Agnihotri
   */
  getGpcCategoryCode(gpcCategoryCodePath: string): string {
    //set path as Array of Strings
    let path: Array<string> = gpcCategoryCodePath.split('.');
    //find object by path in given data
    let gpcCategoryCode = this.appService.findObjectByPath(this.smartLabelData, path);
    return gpcCategoryCode;
  }

  /**
   * return brand name from smartLabelData obj
   * @param brandNamePath [brand name path]
   * @task QLIKTAG-2970
   * @author Radhika Agnihotri
   */
  getBrandName(brandNamePath: string): string {
    //set path as Array of Strings
    let path: Array<string> = brandNamePath.split('.');
    //find object by path in given data
    let brandName = this.appService.findObjectByPath(this.smartLabelData, path);
    return brandName;
  }

  /**
   * [Get Privacy policy url if set in smartLabelConfig]
   * @author Neil
   * @return [description]
   */
  getPrivacyPolicyUrl(config?) {
    if (config.hasOwnProperty('privacyPolicy')) {
      if (Array.isArray(config.privacyPolicy) && config.privacyPolicy.length > 0) {
        const currentValue = this.multiLangPipe.transform(config.privacyPolicy);
        //console.log(currentValue);
        if (currentValue) {
          return {
            url: currentValue,
            text: [
              {
                "languageCode": "en",
                "value": "Privacy Policy"
              },
              {
                "languageCode": "fr",
                "value": "Politique de confidentialité"
              },
              {
                "languageCode": "en_US",
                "value": "Privacy Policy"
              },
              {
                "languageCode": "en_CA",
                "value": "Privacy Policy"
              },
              {
                "languageCode": "fr_CA",
                "value": "Politique de confidentialité"
              }
            ]
          };
        }
      }
    }
    return false;
    //console.log(this.config);
  }

  getProductHeaderConfig() {
    return this.productHeaderConfig = {
      "ctrlName": "product-header",
      "config": {
        "productImageUrl": this.getProductImages('data.modules.BasicProductInformationModuleType.imageLink', 0)[0],
        mapping: {
          brandNameKey: 'data.modules.BasicProductInformationModuleType.brandNameInformation.brandName',
          upcKey: 'data.modules.BasicProductInformationModuleType.gpcCategoryCode',
          productNameKey: 'data.modules.BasicProductInformationModuleType.productName',
          netContentKey: 'data.modules.ProductQuantityInformationModuleType.netContent',
          gtinKey: 'product.gtin'
        },
        'smartLabelData': this.smartLabelData,
        'entityInformation': this.entityInformation,
        'tooltipText': this.getTooltipText()
      }
    }
  }

  /**
   * [Returns Array of detail pages for smartlabel]
   * @return [Array of pages containing subpages]
   * @task QLIKTAG-2925
   * @author SwapnilP
   */
  getDetailPages(detailAlias: string): Array<Page> {
    console.log('detail page ' + detailAlias)
    if (this.smartLabelConfig.hasOwnProperty('footer1')) {
      this.footer1Config = this.getFooterConfig(1);
    }

    if (this.smartLabelConfig.hasOwnProperty('footer2')) {
      this.footer2Config = this.getFooterConfig(2);
    }


    let privacyPolicyUrl = this.getPrivacyPolicyUrl(this.smartLabelConfig);


    this.footerLinksConfig = {
      "ctrlName": "footer-links",
      "config": {
        "class": "smartlabel-footer-links",
        "links": [
          ...(privacyPolicyUrl ? [privacyPolicyUrl] : [])
        ]
      }
    }

    this.headerConfig = {
      "ctrlName": "header",
      "title": "Smart label header",
      "config": {
        "sticky": true,
        "isBackButtonVisible": true,
        "bgImageColor": "#ffffff",
        "isContentHeader": true,
        "class": "smartlabel-header"
      }
    }

    this.productHeaderConfig = this.getProductHeaderConfig();

    this.socialLinksConfig = this.getSocialLinksConfig();
    //console.log("this.socialLinksConfig",this.socialLinksConfig)

    this.informationAccuracyConfig = this.getInformationAccuracyConfig();
    //console.log("informationAccuracyConfig", this.informationAccuracyConfig)

    this.badgesConfig = this.getBadgeConfig();

    switch (detailAlias) {
      case 'ingredient-item':
        const INGREDIENT_ITEM = this.getIngredientPageLayout();
        return INGREDIENT_ITEM ? [INGREDIENT_ITEM] : [];
      case 'sub-ingredient-item':
        const SUB_INGREDIENT_ITEM = this.getSubIngredientPageLayout();
        return SUB_INGREDIENT_ITEM ? [SUB_INGREDIENT_ITEM] : [];
      case 'human-health':
        const EFFECTS_ON_HUMAN_HEALTH = this.getEffectsOnHumanHealthPageLayout();
        return EFFECTS_ON_HUMAN_HEALTH ? [EFFECTS_ON_HUMAN_HEALTH] : [];
      case 'storage-and-disposal':
        const STORAGE_AND_DISPOSAL = this.getStorageAndDisposalPageLayout();
        return STORAGE_AND_DISPOSAL ? [STORAGE_AND_DISPOSAL] : [];
      case 'environment':
        const EFFECTS_ON_ENVIRONMENT = this.getEffectsOnEnvironmentPageLayout();
        //console.log({EFFECTS_ON_ENVIRONMENT})
        return EFFECTS_ON_ENVIRONMENT ? [EFFECTS_ON_ENVIRONMENT] : [];
      case 'about-product':
        const ABOUT_PRODUCT = this.getAboutProductPageLayout();
        return ABOUT_PRODUCT ? [ABOUT_PRODUCT] : [];
      case 'certifications':
        const CERTIFICATIONS = this.getCertificationsPageLayout();
        return CERTIFICATIONS ? [CERTIFICATIONS] : [];
      case 'general-information':
        const GENERAL_INFO = this.getGeneralInfoPageLayout();
        return GENERAL_INFO ? [GENERAL_INFO] : [];
      case 'disclosure-fragrance':
        const DISCLOSURE_FRAGRANCE = this.getFragranceLevelOfDisclosureConfig();
        return DISCLOSURE_FRAGRANCE ? [DISCLOSURE_FRAGRANCE] : [];
      case 'disclosure-non-fragrance':
        const DISCLOSURE_NON_FRAGRANCE = this.getNonFragranceLevelOfDisclosureConfig();
        return DISCLOSURE_NON_FRAGRANCE ? [DISCLOSURE_NON_FRAGRANCE] : [];
      case 'product-images':
        const PRODUCT_IMAGES = this.getProductImagesLayout();
        return PRODUCT_IMAGES ? [PRODUCT_IMAGES] : [];
      case 'about-allergens':
        const ABOUT_ALLERGEN = this.getOtcAllergenPageLayout();
        return ABOUT_ALLERGEN ? [ABOUT_ALLERGEN] : []
      case 'about-expiration-dates':
        const ABOUT_EXPIRATION_DATES = this.getOtcExpirationPageLayout();
        return ABOUT_EXPIRATION_DATES ? [ABOUT_EXPIRATION_DATES] : []
      case 'drug-facts':
        const DRUG_FACTS_PANEL = this.getDrugFactsPanelPageLayout();
        return DRUG_FACTS_PANEL ? [DRUG_FACTS_PANEL] : []
      case 'health-safety-and-other-advisories':
        const HEALTH_SAFETY_AND_OTHER_ADVISORY = this.getOtcHealthAndSafetyPageLayout();
        return HEALTH_SAFETY_AND_OTHER_ADVISORY ? [HEALTH_SAFETY_AND_OTHER_ADVISORY] : []
      case 'designated-list':
        const DESIGNATED_LIST = this.getDesginatedListPageLayout();
        return DESIGNATED_LIST ? [DESIGNATED_LIST] : []
      case 'about-this-product':
        const ABOUT_THIS_PRODUCT = this.getAboutProductFoodPageLayout();
        return ABOUT_THIS_PRODUCT ? [ABOUT_THIS_PRODUCT] : [];
      case 'certifications-item':
        const CERTIFICATIONS_ITEM = this.getCertificationsFoodPageLayout();
        return CERTIFICATIONS_ITEM ? [CERTIFICATIONS_ITEM] : [];
      case 'health-and-safety':
        const HEALTH_AND_SAFETY = this.getHealthAndSafetyFoodPageLayout();
        return HEALTH_AND_SAFETY ? [HEALTH_AND_SAFETY] : [];
      case 'product-instructions':
        const PRODUCT_INSTRUCTIONS = this.getProductInstructionsFoodPageLayout();
        return PRODUCT_INSTRUCTIONS ? [PRODUCT_INSTRUCTIONS] : [];
      case 'allergen-item':
        const ALLERGEN_ITEM = this.getAllergensPageLayout();
        return ALLERGEN_ITEM ? [ALLERGEN_ITEM] : [];
      case 'ingredient-item-food':
        const FOOD_INGREDIENT = this.getFoodIngredientDetailPageLayout();
        return FOOD_INGREDIENT ? [FOOD_INGREDIENT] : [];
      case 'sub-ingredient-item-food':
        const FOOD_SUB_INGREDIENT = this.getFoodSubIngredientDetailPageLayout();
        return FOOD_SUB_INGREDIENT ? [FOOD_SUB_INGREDIENT] : [];
      case 'sup-about-this-product':
        const SUP_ABOUT_THIS_PRODUCT = this.getAboutProductSupPageLayout();
        return SUP_ABOUT_THIS_PRODUCT ? [SUP_ABOUT_THIS_PRODUCT] : [];
      case 'sup-certifications-item':
        const SUP_CERTIFICATIONS_ITEM = this.getCertificationsSupPageLayout();
        return SUP_CERTIFICATIONS_ITEM ? [SUP_CERTIFICATIONS_ITEM] : [];
      case 'sup-health-and-safety':
        const SUP_HEALTH_AND_SAFETY = this.getHealthAndSafetySupPageLayout();
        return SUP_HEALTH_AND_SAFETY ? [SUP_HEALTH_AND_SAFETY] : [];
      case 'sup-product-instructions':
        const SUP_PRODUCT_INSTRUCTIONS = this.getProductInstructionsSupPageLayout();
        return SUP_PRODUCT_INSTRUCTIONS ? [SUP_PRODUCT_INSTRUCTIONS] : [];
      case 'ingredient-supplement':
        const INGREDIENT_SUPPLEMENT = this.getSupIngredientDetailPageLayout();
        return INGREDIENT_SUPPLEMENT ? [INGREDIENT_SUPPLEMENT] : [];
      case 'sub-ingredient-supplement':
        const SUB_INGREDIENT_SUPPLEMENT = this.getSupSubIngredientDetailPageLayout();
        return SUB_INGREDIENT_SUPPLEMENT ? [SUB_INGREDIENT_SUPPLEMENT] : [];
      default:
        return [];
    }
  }

  /**
   * [Ingredient Page Layout]
   * @author Neil C
   * @return [description]
   */
  getIngredientPageLayout() {
    const ingredientPath: string = 'data.modules.NonfoodIngredientInformationModuleType.nonfoodIngredient.%index0%';

    let pathMap;

    pathMap = {
      "key": ingredientPath
    }

    //console.log(this.pageService.listIndex);

    pathMap = this.appService.resolveIndex(pathMap);

    let path: Array<string> = pathMap.key.split('.');
    let ingredient = this.appService.findObjectByPath(this.smartLabelData, path);

    let pageLayout = {
      "alias": "ingredient-item",
      "title": "Ingredient Details",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(0),//'ingredients-and-contents, drug-facts',
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": false
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),//'ingredients-and-contents, drug-facts',
                "title": this.getTabTitle(0),//'Ingredients & Contents',
                "icon": this.getTabIcon(0),//['fas', 'list'],
                "show": true
              },
              "controls": [
                //

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),//'health-safety-and-environment',
                "title": this.getTabTitle(1),//'Health, Safety & Environment',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1),//['fas', 'exclamation-triangle'],
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2),//'usage-and-handling',
                "title": this.getTabTitle(2),//'Usage & Handling',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),//['far', 'hand-point-up'],
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),//'feature-and-benefits',
                "title": this.getTabTitle(3),//'Features & Benefits',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                "icon": this.getTabIcon(3),//['fas', 'info-circle'],
                "show": true
              },
              "controls": [

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),//'company-brand-and-sustainability',
                "title": this.getTabTitle(4),//'Company, Brand & Sustainability',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4),//['fas', 'external-link-square-alt'],
                "show": true
              },
              "controls": [
              ]
            }

          ]
        }
      ]
    }

    const tab = pageLayout.controls[2].controls[0].controls;

    if (ingredient) {
      if (ingredient.hasOwnProperty("ingredientPurpose")) {
        if (this.isFragrance(ingredient["ingredientPurpose"])) {
          let richTextConfig = this.getFragranceDisclosureConfig();
          if (richTextConfig) {
            tab.push(richTextConfig);
          }
        }
      }


      if (ingredient.hasOwnProperty("ingredientName")) {
        let ingredientName = this.multiLangPipe.transform(ingredient.ingredientName);

        //check if multilang pipe returns a value
        if (ingredientName) {

          let ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinition') ? ingredient.ingredientDefinition : "";

          if (!ingredientDefinition) {

            ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinitionTypeCodeNonFood') ? this.shortCodePipe.transform(`{{SL.${pathMap.key}.ingredientDefinitionTypeCodeNonFood 
            | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue
            }}`) : "";

            ingredientDefinition ? ingredientDefinition : "";

          }






          let jumbotronConfig = {
            "ctrlName": "jumbotron",
            "config": {
              "title": ingredientName,
              "content": ingredientDefinition,
              "class": "smartlabel-jumbotron"
            }
          };
          let ingredientStrength = this.getIngredientStrength(ingredient, pathMap.key);
          let percentageOfAlcohol = this.getPOAP(this.pageService.listIndex);
          let ingredientContentPercentage = this.getIngredientContentPercentage(ingredient);
          jumbotronConfig.config.title = `${ingredientName} ${ingredientStrength}`;
          if (percentageOfAlcohol && percentageOfAlcohol != '') {
            jumbotronConfig.config.title += ` ${percentageOfAlcohol}`;
          } else {
            jumbotronConfig.config.title += ` ${ingredientContentPercentage}`;
          }

          tab.push(jumbotronConfig);
        }
      }

      if (ingredient.hasOwnProperty('ingredientPurpose') && ingredient.ingredientPurpose) {
        let purpose = this.multiLangPipe.transform(ingredient.ingredientPurpose);
        if (purpose && purpose != '') {
          tab.push({
            "ctrlName": "list",
            "config": {
              "class": "smartlabel-list"
            },
            "controls": [
              {
                "ctrlName": "list-item",
                "config": {
                  "label": 'Purpose',
                  "content": ingredient.ingredientPurpose,
                }
              }]
          });
        }

      }

      //console.log(this.smartLabelConfig)

      if (ingredient.hasOwnProperty('CASNumber')) {
        tab.push({
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16 p-b-0",
            "content": `CAS Number: ${ingredient.CASNumber}`
          }
        });
      }


      if (ingredient.hasOwnProperty('chemicalOfConcern') && ingredient.chemicalOfConcern) {
        let cocConfig = this.getCocConfig(ingredient.COCAgency);
        if (cocConfig) {
          tab.push(cocConfig);
        }
      }

      if (ingredient.hasOwnProperty('subIngredient') && ingredient.subIngredient.length > 0) {

        let listConfig = this.ingredientListConfig(ingredient.subIngredient, this.pageService.listIndex, 1)


        tab.push(...[{
          "ctrlName": "heading",
          "config": {
            "class": "smartlabel-ingredient-heading p-16 p-t-0 p-b-0",
            "text": "SUB INGREDIENTS",
            "headingType": 2
          }

        }, listConfig])



      }

      if (this.smartLabelConfig.hasOwnProperty('ingredientProviderStatement')) {
        tab.push({
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16 p-b-0",
            "content": this.smartLabelConfig.ingredientProviderStatement
          }
        });
      }

      if (this.footer1Config) {
        pageLayout.controls.push(this.footer1Config);
      }

      if (this.footer2Config) {
        pageLayout.controls.push(this.footer2Config);
      }

      if (this.informationAccuracyConfig) {
        pageLayout.controls.push(this.informationAccuracyConfig);
      }

      if (this.footerLinksConfig) {
        pageLayout.controls.push(this.footerLinksConfig);
      }

      if (this.socialLinksConfig) {
        pageLayout.controls.push(this.socialLinksConfig);
      }


    }

    //console.log(pageLayout);
    return pageLayout;






  }

  /**
   * Allergens page layout
   * @author Venkatesh Pasala
   * @task QLIKTAG-2900
   */
  getAllergensPageLayout() {
    const allergenPath: string = 'data.modules.ProductAllergenInformationModuleType.allergenRelatedInformation.%index0%';
    let pathMap;
    pathMap = {
      "key": allergenPath
    }
    pathMap = this.appService.resolveIndex(pathMap);
    let path: Array<string> = pathMap.key.split('.');
    let allergen = this.appService.findObjectByPath(this.smartLabelData, path);
    let jumbotronConfig;
    let richTextConfig;
    if (allergen) {
      let allergenIndex = this.pageService.listIndex.split('_')[1];
      let allergenTypeCode = this.shortCodePipe.transform(`{{SL.${pathMap['key']}.allergen.${allergenIndex}.allergenTypeCode | mapTo: listItemName}}`);
      if (allergenTypeCode) {
        jumbotronConfig = {
          "ctrlName": "jumbotron",
          "config": {
            "title": allergenTypeCode,
            "content": allergen.hasOwnProperty('allergenStatement') ? allergen.allergenStatement : "",
            "class": "smartlabel-jumbotron"
          }
        };

        // tab.push(jumbotronConfig);
        if (allergen.hasOwnProperty("allergenSpecificationAgency") && allergen.hasOwnProperty("allergenSpecificationName")) {
          if (allergen.allergenSpecificationAgency && allergen.allergenSpecificationName) {
            richTextConfig = {
              "ctrlName": "rich-text",
              "config": {
                "class": "smartlabel-rich-text p-16",
                "content": this.multiLangPipe.transform(FOOD_LANG["read-more"]) + " " + allergenTypeCode + " " + this.multiLangPipe.transform(FOOD_LANG["read-allergies"]) + " " + `<a href="${allergen.allergenSpecificationAgency}" style="text-decoration:none;" target="_blank">${allergen.allergenSpecificationName}</a>`
              }
            }
          }
        }
      }

    }

    let tabs = {
      "ctrlName": "tabs",
      "config": {
        "class": 'smartlabel-tabs',
        "value": this.getTabName(2),//'Allergens',
        "layout": 'icon-top',
        "scrollable": false,
        "tabs": 5,
        "sticky": true,
        "gridFixed": false
      },
      "controls": [
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(0),
            "title": this.getTabTitle(0),
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
            "icon": this.getTabIcon(0),
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(1),
            "title": this.getTabTitle(1),
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
            "icon": this.getTabIcon(1),
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(2),
            "title": this.getTabTitle(2),
            "icon": this.getTabIcon(2),
            "show": true
          },
          "controls": [
            ...(jumbotronConfig ? [jumbotronConfig] : []),
            ...(richTextConfig ? [richTextConfig] : [])
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(3),
            "title": this.getTabTitle(3),
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
            "icon": this.getTabIcon(3),
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(4),
            "title": this.getTabTitle(4),
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
            "icon": this.getTabIcon(4),
            "show": true
          },
          "controls": []
        }
      ]
    }

    let allergenPageLayout = {
      "alias": "allergen-item",
      "title": "Allergen Details",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        ...[tabs]
      ]
    };
    if (this.footer1Config) {
      allergenPageLayout.controls.push(this.footer1Config);
    }
    if (this.footer2Config) {
      allergenPageLayout.controls.push(this.footer2Config);
    }
    if (this.informationAccuracyConfig) {
      allergenPageLayout.controls.push(this.informationAccuracyConfig);
    }
    if (this.footerLinksConfig) {
      allergenPageLayout.controls.push(this.footerLinksConfig);
    }
    if (this.socialLinksConfig) {
      allergenPageLayout.controls.push(this.socialLinksConfig);
    }
    return allergenPageLayout;
  }


  /**
 * [List config for tab5]
 * @return [List configuration for external links]
 */
  getExternalLinksConfig(config) {
    let listConfig = {
      "ctrlName": "list",
      "title": "Company/Brand External Links",
      "config": {
        "class": "smartlabel-list",
      },
      "controls": []
    }

    if (config.hasOwnProperty('hyperlinkUrl')) {
      if (config.hyperlinkUrl.length > 0) {

        config.hyperlinkUrl.map((value, key) => {

          let item = {
            "ctrlName": "list-item",
            "config": {
              "label": value.text,
              "url": { "url": value.url, "target": "_blank" },
              "icon": ['fas', 'external-link-square-alt']

            }
          }

          listConfig.controls.push(item);


        })

      }
    }

    //console.log(listConfig);
    return listConfig;
  }


  /**
   * [Ingredient Page Layout]
   * @author Neil C
   * @return [description]
   */
  getSubIngredientPageLayout() {

    const ingredientPath: string = 'data.modules.NonfoodIngredientInformationModuleType.nonfoodIngredient.%index0%.subIngredient.%index1%';

    let pathMap;

    pathMap = {
      "key": ingredientPath
    }

    pathMap = this.appService.resolveIndex(pathMap);

    let path: Array<string> = pathMap.key.split('.');
    let ingredient = this.appService.findObjectByPath(this.smartLabelData, path);


    let pageLayout = {
      "alias": "sub-ingredient-item",
      "title": "Sub Ingredient Details",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(0),//'ingredients-and-contents',
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": false
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),//'ingredients-and-contents, drug-facts',
                "title": this.getTabTitle(0),//'Ingredients & Contents, Drug Facts',
                "icon": this.getTabIcon(0),//['fas', 'list'],
                "show": true
              },
              "controls": [
                //

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),//'health-safety-and-environment',
                "title": this.getTabTitle(1),//'Health, Safety & Environment',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1),//['fas', 'exclamation-triangle'],
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2),//'usage-and-handling',
                "title": this.getTabTitle(2),//'Usage & Handling',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),//['far', 'hand-point-up'],
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),//'feature-and-benefits',
                "title": this.getTabTitle(3),//'Features & Benefits',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                "icon": this.getTabIcon(3),//['fas', 'info-circle'],
                "show": true
              },
              "controls": [

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),//'company-brand-and-sustainability',
                "title": this.getTabTitle(4),//'Company, Brand & Sustainability',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4),//['fas', 'external-link-square-alt'],
                "show": true
              },
              "controls": [
              ]
            }

          ]
        }
      ]
    }


    if (ingredient) {

      if (ingredient.hasOwnProperty("ingredientName")) {
        let ingredientName = this.multiLangPipe.transform(ingredient.ingredientName);
        //check if multilang pipe returns a value
        if (ingredientName) {

          let ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinition') ? ingredient.ingredientDefinition : "";

          if (!ingredientDefinition) {

            ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinitionTypeCodeNonFood') ? this.shortCodePipe.transform(`{{SL.${pathMap.key}.ingredientDefinitionTypeCodeNonFood  | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`) : "";

            ingredientDefinition ? ingredientDefinition : "";

          }

          let jumbotronConfig = {
            "ctrlName": "jumbotron",
            "config": {
              "title": ingredientName,
              "content": ingredientDefinition,
              "class": "smartlabel-jumbotron"
            }
          };
          let ingredientStrength = this.getIngredientStrength(ingredient, pathMap.key);
          jumbotronConfig.config.title = `${ingredientName} ${ingredientStrength}`

          pageLayout.controls[2].controls[0].controls.push(jumbotronConfig);
        }
      }


      if (ingredient.hasOwnProperty('ingredientPurpose') && ingredient.ingredientPurpose) {
        let purpose = this.multiLangPipe.transform(ingredient.ingredientPurpose);
        if (purpose && purpose != '') {
          pageLayout.controls[2].controls[0].controls.push({
            "ctrlName": "list",
            "config": {
              "class": "smartlabel-list"
            },
            "controls": [
              {
                "ctrlName": "list-item",
                "config": {
                  "label": 'Purpose',
                  "content": ingredient.ingredientPurpose,
                }
              }]
          });
        }

      }


      if (ingredient.hasOwnProperty('CASNumber')) {
        pageLayout.controls[2].controls[0].controls.push({
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16",
            "content": `CAS Number: ${ingredient.CASNumber}`
          }
        });
      }

      if (ingredient.hasOwnProperty('chemicalOfConcern') && ingredient.chemicalOfConcern) {
        let cocConfig = this.getCocConfig(ingredient.COCAgency);
        if (cocConfig) {
          pageLayout.controls[2].controls[0].controls.push(cocConfig);

        }
      }

      if (this.smartLabelConfig.hasOwnProperty('ingredientProviderStatement')) {
        pageLayout.controls[2].controls[0].controls.push({
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16 p-b-0",
            "content": this.smartLabelConfig.ingredientProviderStatement
          }
        });
      }

      if (this.footer1Config) {
        pageLayout.controls.push(this.footer1Config);
      }

      if (this.footer2Config) {
        pageLayout.controls.push(this.footer2Config);
      }

      if (this.informationAccuracyConfig) {
        pageLayout.controls.push(this.informationAccuracyConfig);
      }

      if (this.footerLinksConfig) {
        pageLayout.controls.push(this.footerLinksConfig);
      }

      if (this.socialLinksConfig) {
        pageLayout.controls.push(this.socialLinksConfig);
      }

    }
    return pageLayout;




  }

  /**
   * get Disclosure config for non-fragrance
   * @param level [level of non-fragrance disclosure]
   * @task QLIKTAG-2926
   * @author Radhika Agnihotri
   */
  getNonFragranceDisclosureConfig(level: number): object {
    //console.log('level', level)
    let level1 = `
    <p>
      <b>Level of Disclosure: </b>Level 1: Full Disclosure of All Intentionally Added and Nonfunctional Ingredients.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-non-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;
    let level2 = `
    <p>
      <b>Level of Disclosure: </b>Level 2: Full Disclosure of All Intentionally Added Ingredients.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-non-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;
    let level3 = `
    <p>
      <b>Level of Disclosure: </b>Level 3: Partial Disclosure of Intentionally Added Ingredients.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-non-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;

    let content = '';

    switch (level) {
      case 1: {
        content = level1;
        break;
      }
      case 2: {
        content = level2;
        break;
      }
      case 3: {
        content = level3;
        break;
      }
    }

    let richTextConfig = {
      "ctrlName": "rich-text",
      "config": {
        "class": "smartlabel-rich-text p-16",
        "content": content
      }
    }

    return richTextConfig;
  }

  /**
  * get Disclosure config for fragrance
  * @task QLIKTAG-2926
  * @author Radhika Agnihotri
  */
  getFragranceDisclosureConfig() {
    let result;
    //console.log('level', level);
    let nonfoodIngredientInformationPath: string = "data.modules.NonfoodIngredientInformationModuleType";

    let path: Array<string> = nonfoodIngredientInformationPath.split('.');
    //find object by path in given data
    let nonFoodIngredientInformation = this.appService.findObjectByPath(this.smartLabelData, path);

    let level1 = `
    <p>
      <b>Level of Disclosure: </b>Level 1: Full Disclosure of All Fragrances.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;
    let level2 = `
    <p>
      <b>Level of Disclosure: </b>Level 2: Partial Disclosure of Fragrances; Master List Provided.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;
    let level3 = `
    <p>
      <b>Level of Disclosure: </b>Level 3: Partial Disclosure of Fragrances; No Master List Provided.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;
    let level4 = `
    <p>
      <b>Level of Disclosure: </b>Level 4: No Disclosure of Fragrances; Master List Provided.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;
    let level5 = `
    <p>
      <b>Level of Disclosure: </b>Level 5: No Disclosure of Fragrances; No Master List Provided.<br>
      <a class="page-link" href="p/${this.pageService.pageAlias}?detail=disclosure-fragrance">
        More about Disclosure Levels
      </a>
    </p>`;

    let content = "";
    //set richtext control only if fragranceLevelOfDisclosure is present
    if (nonFoodIngredientInformation && nonFoodIngredientInformation.hasOwnProperty("fragranceLevelOfDisclosure")) {
      switch (nonFoodIngredientInformation["fragranceLevelOfDisclosure"]) {
        case 1: {
          content = level1;
          break;
        }
        case 2: {
          content = level2;
          break;
        }
        case 3: {
          content = level3;
          break;
        }
        case 4: {
          content = level4;
          break;
        }
        case 5: {
          content = level5;
          break;
        }
      }
      //set result if fragrance level of disclosure is present
      result = {
        "ctrlName": "rich-text",
        "config": {
          "class": "smartlabel-rich-text p-16",
          "content": content
        }
      }
    }
    return result;
  }

  /**
   * [List for productOriginInformationModuleType]
   * @author Lalitkumar Saindane, NeilC
   * @return [List]
   */
  getGeneralInfoPageList() {
    //Updated on 02/09/2020 by Radhika Agnihotri 
    let list = {
      "ctrlName": "list",
      "title": "General Information",
      "config": {
        "class": "smartlabel-list",
      },
      "controls": []
    }

    let countryOfOriginListItem = this.getCountryOfOriginListItem();
    if (countryOfOriginListItem) {
      list.controls.push(countryOfOriginListItem);
    }

    if (list.controls.length > 0) {
      return list;
    } else {
      return false;
    }


  }

  /**
   * [General Information Page Layout]
   * @author Lalitkumar Saindane
   * @return [description]
   */
  getGeneralInfoPageLayout() {

    let generalInfoList = this.getGeneralInfoPageList();
    return {
      "alias": "general-information",
      "title": "General Information",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),// ingredients-and-contents(NON-FOOD) || drug-facts(OTC-DRUG)
                "title": this.getTabTitle(0), // Ingredients & Contents(NON-FOOD) || Drug Facts(OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //  ['fas', 'list'] (NON-FOOD) || ['fas', 'search'] (OTC-DRUG),
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1), // health-safety-and-environment(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(1),// Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1),// ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(2),// Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),//['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(3),// Features & Benefits(NON-FOOD, OTC-DRUG)
                "icon": this.getTabIcon(3),// ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
                ...(generalInfoList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "General Information",
                    "headingType": 1
                  }
                },
                  generalInfoList] : [])

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(4), // Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4),  // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
              ]
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])

      ]
    };

  }
  /**
   * get disclosure config by level
   * @param DisclosureLevelPath [fragrance/non-fragrance disclosure level path]
   * @task QLIKTAG-2926
   * @author Radhika Agnihotri
   */
  getDisclosureLevelConfig(nonFoodIngredientInformationPath: string) {
    //set path as Array of Strings
    let path: Array<string> = nonFoodIngredientInformationPath.split('.');
    //find object by path in given data
    let nonFoodIngredientInformation = this.appService.findObjectByPath(this.smartLabelData, path);
    //console.log('nonfood', nonFoodIngredientInformation)
    if (nonFoodIngredientInformation) {
      if (nonFoodIngredientInformation.hasOwnProperty("nonfragranceLevelOfDisclosure")) {
        return this.getNonFragranceDisclosureConfig(nonFoodIngredientInformation.nonfragranceLevelOfDisclosure);
      }
    }
  }
  /**
   * getfragrance disclosure config by level
   * @param level [fragrance disclosure level]
   * @task QLIKTAG-2926
   * @author Radhika Agnihotri
   */
  getFragranceLevelOfDisclosureConfig() {
    let level;
    let DISCLOSURE_FRAGRANCE;
    const nonfoodIngredientInformationModulePath = "data.modules.NonfoodIngredientInformationModuleType";
    let path = nonfoodIngredientInformationModulePath.split(".");

    let nonfoodIngredientInformationModule = this.appService.findObjectByPath(this.smartLabelData, path);
    //Check if Fragrance or nonfragranceLevel present
    if (nonfoodIngredientInformationModule) {
      if (nonfoodIngredientInformationModule.hasOwnProperty("fragranceLevelOfDisclosure")) {
        level = nonfoodIngredientInformationModule.fragranceLevelOfDisclosure;
      }
    }
    //console.log("level", level)
    if (level) {
      DISCLOSURE_FRAGRANCE = {
        "alias": "disclosure-fragrance",
        "title": "Disclosure Fragrance",
        "mode": "smartlabel",
        "controls": [
          ...(this.headerConfig ? [this.headerConfig] : []),
          ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
          {
            "ctrlName": "tabs",
            "config": {
              "class": 'smartlabel-tabs',
              "value": this.getTabName(0), // ingredients-and-contents(NON-FOOD)
              "layout": 'icon-top',
              "scrollable": false,
              "tabs": 5,
              "sticky": true,
            },
            "controls": [
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(0), // ingredients-and-contents(NON-FOOD)
                  "title": this.getTabTitle(0), // Ingredients & Contents (NON_FOOD)
                  "icon": this.getTabIcon(0), //['fas', 'list'] (NON-FOOD)
                  "show": true
                },
                "controls": [
                  {
                    "ctrlName": "jumbotron",
                    "config": {
                      "title": "New York Department of Environmental Conservation",
                      "content": "Hierarchy of Fragrance Ingredients Disclosure Level",
                      "bgColor": "#24338a",
                      "titleTextColor": "#ffffff",
                      "contentTextColor": "#ffffff",
                      "class": "smartlabel-jumbotron"
                    }
                  },
                  {
                    "ctrlName": "rich-text",
                    "config": {
                      "content": `
                          <p>
                            <em>Hierarchy of Fragrance Ingredients Disclosure Levels: <br></em>
                          </p>
                          <ul class="smartlabel-disclosure-list">
                            <li>
                              <input type="checkbox" ${level && level === 1 ? 'checked' : ''}>
                              <label>
                                <strong>Level 1: Full Disclosure of All Fragrances.&nbsp;</strong>All fragrance ingredients are disclosed, including those present in trace quantities.
                              </label>
                            </li>
                            <li>
                              <input type="checkbox" ${level && level === 2 ? 'checked' : ''}>
                                <strong>Level 2: Partial Disclosure of Fragrances; Master List Provided</strong>. One or more fragrance ingredients are withheld as confidential business information, but a master list of either all fragrance ingredients used by the manufacturer, or of all fragrance ingredients used in a category of the manufacturer&rsquo;s designated consumer products is provided which includes all ingredients withheld.
                              </label>
                            </li>
                            <li>
                              <input type="checkbox" ${level && level === 3 ? 'checked' : ''}>
                              <label>
                                <strong>Level 3: Partial Disclosure of Fragrances; No Master List Provided. </strong>One or more fragrance ingredients are withheld as confidential business information, and no master list of fragrance ingredients used by the manufacturer is provided.
                              </label>
                            </li>
                            <li>
                              <input type="checkbox" ${level && level === 4 ? 'checked' : ''}>
                              <label>
                                <strong>Level 4: No Disclosure of Fragrances; Master List Provided.</strong>All fragrance ingredients are withheld as confidential business information, but a master list of either all fragrance ingredients used by the manufacturer, or of all fragrance ingredients used in a category of the manufacturer&rsquo;s designated consumer products is provided which includes all ingredients withheld.
                              </label>
                            </li>
                            <li>
                              <input type="checkbox" ${level && level === 5 ? 'checked' : ''}>
                              <label>
                                <strong>Level 5: No Disclosure of Fragrances; No Master List Provided. </strong>All fragrance ingredients are withheld as confidential business information, and no master list of fragrance ingredients used by the manufacturer is provided.
                              </label>
                            </li>
                          </ul>
                          <p>For more information (Fragrance Master List - IFRA approved ingredients)
                            <a class="smartlabel-link" target="_blank" href="https://ifrafragrance.org/self-regulation/library">
                             https://ifrafragrance.org/self-regulation/library
                            </a>
                          </p>
                          <p>
                            For more information:
                            <a class="smartlabel-link" target="_blank" href="https://www.dec.ny.gov/docs/materials_minerals_pdf/cleansingprodfin.pdf">
                              &nbsp;https://www.dec.ny.gov/docs/materials_minerals_pdf/cleansingprodfin.pdf
                            </a>
                          </p>`,
                      "class": "smartlabel-rich-text p-16"
                    }
                  }
                ]
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(1), // health-safety-and-environment(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(1), // Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                  "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(2),  // Usage & Handling(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                  "icon": this.getTabIcon(2),  //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(3),  // Features & Benefits(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                  "icon": this.getTabIcon(3),  // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(4), // Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                  "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              }
            ]
          },
          ...(this.footer1Config ? [this.footer1Config] : []),
          ...(this.footer2Config ? [this.footer2Config] : []),
          ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
          ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
          ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
        ]
      };
    }

    return DISCLOSURE_FRAGRANCE;
  }

  /**
   * get non-fragrance disclosure config by level
   * @param level [non-fragrance disclosure level]
   * @task QLIKTAG-2926
   * @author Radhika Agnihotri
   */
  getNonFragranceLevelOfDisclosureConfig() {
    let level;
    let DISCLOSURE_NON_FRAGRANCE;
    //Define Path to NonfoodIngredientInformationModule to get level of disclosure
    const nonfoodIngredientInformationModulePath = "data.modules.NonfoodIngredientInformationModuleType";
    let path = nonfoodIngredientInformationModulePath.split(".");

    let nonfoodIngredientInformationModule = this.appService.findObjectByPath(this.smartLabelData, path);
    //Check if Fragrance or nonfragranceLevel present
    if (nonfoodIngredientInformationModule) {
      if (nonfoodIngredientInformationModule.hasOwnProperty("nonfragranceLevelOfDisclosure")) {
        level = nonfoodIngredientInformationModule.nonfragranceLevelOfDisclosure;
      }
    }
    if (level) {
      DISCLOSURE_NON_FRAGRANCE = {
        "alias": "disclosure-non-fragrance",
        "title": "Disclosure Non-fragrance",
        "mode": "smartlabel",
        "controls": [
          ...(this.headerConfig ? [this.headerConfig] : []),
          ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
          {
            "ctrlName": "tabs",
            "config": {
              "class": 'smartlabel-tabs',
              "value": this.getTabName(0), // ingredients-and-contents(NON-FOOD)
              "layout": 'icon-top',
              "scrollable": false,
              "tabs": 5,
              "sticky": true,
            },
            "controls": [
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(0), // ingredients-and-contents(NON-FOOD)
                  "title": this.getTabTitle(0), // Ingredients & Contents (NON_FOOD)
                  "icon": this.getTabIcon(0), //['fas', 'list'] (NON-FOOD)
                  "show": true
                },
                "controls": [
                  {
                    "ctrlName": "jumbotron",
                    "config": {
                      "title": "New York Department of Environmental Conservation",
                      "content": "Hierarchy of Non-fragrance Ingredients Disclosure Level",
                      "bgColor": "#24338a",
                      "titleTextColor": "#ffffff",
                      "contentTextColor": "#ffffff",
                      "class": "smartlabel-jumbotron"
                    }
                  },
                  {
                    "ctrlName": "rich-text",
                    "config": {
                      "content": `
                          <p>
                            <em>Hierarchy of Non-fragrance Ingredients Disclosure Levels:</em>
                          </p>
                          <ul class="smartlabel-disclosure-list">
                            <li>
                              <input type="checkbox" ${level && level === 1 ? 'checked' : ''}>
                              <label>
                                <strong>Level 1: Full Disclosure of All Intentionally Added and Nonfunctional Ingredients.</strong>All known intentionally added ingredients are disclosed, including those present in trace quantities. All known nonfunctional ingredients are disclosed, including any present in trace quantities that appear on one or more of the lists of chemicals of concern named in Appendix B of this policy.
                              </label>
                            </li>
                            <li>
                            <input type="checkbox" ${level && level === 2 ? 'checked' : ''}>
                              <label>
                                <strong>Level 2: Full Disclosure of All Intentionally Added Ingredients.</strong> All intentionally added ingredients are disclosed, including those present in trace quantities. One or more nonfunctional ingredients are withheld as confidential business information.
                              </label>
                            </li>
                            <li>
                              <input type="checkbox" ${level && level === 3 ? 'checked' : ''}>
                              <label>
                                <strong>Level 3: Partial Disclosure of Intentionally Added Ingredients. </strong>One or more intentionally added ingredients are withheld as CBI. All nonfunctional ingredients are disclosed, or one or more are withheld as confidential business information.
                              </label>
                            </li>
                          </ul>
                          <p>
                            For more information:
                            <a class="smartlabel-link" target="_blank" href="https://www.dec.ny.gov/docs/materials_minerals_pdf/cleansingprodfin.pdf">
                              &nbsp;https://www.dec.ny.gov/docs/materials_minerals_pdf/cleansingprodfin.pdf
                            </a>
                          </p>`,
                      "class": "smartlabel-rich-text p-16"
                    }
                  }
                ]
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(1), // health-safety-and-environment(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(1), // Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                  "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(2),  // Usage & Handling(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                  "icon": this.getTabIcon(2),  //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(3),  // Features & Benefits(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                  "icon": this.getTabIcon(3),  // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              },
              {
                "ctrlName": "tab-item",
                "config": {
                  "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG)
                  "title": this.getTabTitle(4), // Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                  "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                  "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                  "show": true
                },
                "controls": []
              }
            ]
          },
          ...(this.footer1Config ? [this.footer1Config] : []),
          ...(this.footer2Config ? [this.footer2Config] : []),
          ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
          ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
          ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])


        ]
      };
    }
    return DISCLOSURE_NON_FRAGRANCE;
  }
  /**
   * [List for ProductClaimsAndEndorsementsModuleType]
   * @author Lalitkumar Saindane, NeilC
   * @return [List]
   */
  getCertificationsList() {

    //console.log('getCertificationsList')

    const productClaimsAndEndorsementsModuleTypePath = 'data.modules.ProductClaimsAndEndorsementsModuleType';
    let path: Array<string> = productClaimsAndEndorsementsModuleTypePath.split('.');
    let result = this.appService.findObjectByPath(this.smartLabelData, path);
    let list, item, accreditationText, statement, description;

    list = {
      "ctrlName": "list",
      "title": "Certification",
      "config": {
        "class": "smartlabel-list p-16",
      },
      "controls": []
    }


    if (result) {

      if (result.hasOwnProperty('accreditationInformation') && result['accreditationInformation'].length > 0) {

        result['accreditationInformation'].forEach((element, index) => {

          //Updated by NeilC for NLC 31/7/19

          let icon = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:cloudinaryurl | mapTo:fieldValue}}`);
          //this.appService.getListCodeIcon(element.accreditationCode);

          let codeText = //this.appService.getListCodeText(element.accreditationCode);

            this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.accreditationInformation.${index}.accreditationCode | mapTo: listItemName}}`);



          description =
            this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`);


          description = this.linkyPipe.transform(description, { className: 'smartlabel-link' });



          accreditationText = '';
          if (element.hasOwnProperty('accreditationText') && Array.isArray(element.accreditationText)) {
            accreditationText = this.multiLangPipe.transform(element.accreditationText).toString();
            accreditationText = this.linkyPipe.transform(accreditationText, { className: 'smartlabel-link' });

          }
          // statement = '<p>'+ element['accreditationCode'] +' '+ accreditationText +'</p>';
          //COMMENTED BY NEIL 18/07/19
          //Using separate ifs
          // statement = `
          //
          //   ${icon ? `<img src="${icon}" alt="${element['accreditationCode']}" class="smartlabel-certification-logo"/>` : ""}
          //   ${accreditationText ? `<h2 class="f-w-400">${accreditationText}</h2>` : ""}
          //   ${codeText ? `<p class="c-black">${codeText}</p>` : ""}
          // `;

          statement = '';

          if (icon) {
            statement += `<img src="${icon}" alt="${element['accreditationCode']}" class="smartlabel-certification-logo" loading="lazy"/>`
          }

          if (codeText) {
            statement += `<h2 class="f-w-400">${codeText}</h2>`
          }

          if (description && description != '') {
            statement += `<p class="c-black">${description}</p>`
          }
          if (accreditationText && accreditationText != '') {
            statement += `<p class="c-black">${accreditationText}</p>`
          }







          if (statement) {
            item = {
              "ctrlName": "list-item",
              "config": {
                "content": statement
              }
            }

            list.controls.push(item);
          }




        });



      }

      // if(result.hasOwnProperty('otherClaimStatement')) {
      //
      //    item = {
      //       "ctrlName": "list-item",
      //       "config": {
      //       "content": result['otherClaimStatement']
      //      }
      //    }
      //
      //   list.controls.push(item);
      //
      //  }

    }

    if (list.controls.length > 0) {
      return list;
    } else {

      return false;
    }
  }

  /**
   * [Certifications Page Layout]
   * @author Lalitkumar Saindane
   * @return [description]
   */
  getCertificationsPageLayout() {
    let certificationsList = this.getCertificationsList();
    return {
      "alias": "certifications",
      "title": "Certifications",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), //ingredients-and-contents(NON-FOOD) || drug-facts(OTC-DRUG)
                "title": this.getTabTitle(0),// Ingredients & Contents (NON_FOOD) || Drug Facts (OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //  ['fas', 'list'] (NON-FOOD) || ['fas', 'search'] (OTC-DRUG),
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1), // health-safety-and-environment(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(1),// Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(2),// Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2), //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(3), // Features & Benefits(NON-FOOD, OTC-DRUG)
                "icon": this.getTabIcon(3), // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
                ...(certificationsList ? [
                  {
                    "ctrlName": "heading",
                    "config": {
                      "class": "smartlabel-heading p-16 m-0",
                      "text": "Certifications",
                      "headingType": 1
                    }
                  },
                  {
                    "ctrlName": "jumbotron",
                    "config": {
                      "class": "smartlabel-jumbotron m-r-15 m-l-15",
                      "content": "SmartLabel® includes certifications from independent organizations that have meaningful and consistent standards for product composition, environmental protection and/or social justice."
                    }
                  },
                  certificationsList
                ] : [])

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
              ]
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])

      ]
    };

  }

  /**
   * [Effects On Human Health Page Layout]
   * @author Radhika Agnihotri
   * @return [description]
   */
  getEffectsOnHumanHealthPageLayout() {

    let effectsOnHumanHealthPageList = this.getEffectsOnHumanHealthPageList();
    //console.log("effectsOnHumanHealthPageList",effectsOnHumanHealthPageList);
    return {
      "alias": "human-health",
      "title": "Human Health",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(1), // health-safety-and-environment
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),    // ingredients-and-contents, drug-facts
                "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search']
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),     // health-safety-and-environment
                "title": this.getTabTitle(1),   // Health, Safety & Environment
                "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle']
                "show": true
              },
              "controls": [
                ...(effectsOnHumanHealthPageList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "Human Health",
                    "headingType": 1
                  }
                },
                  effectsOnHumanHealthPageList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling
                "title": this.getTabTitle(2), // Usage & Handling
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2), // ['far', 'hand-point-up']
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // Features & Benefits
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),   // company-brand-and-sustainability,
                "title": this.getTabTitle(4),  // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
                "show": true
              },
              "controls": []
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])

      ]
    };
  }

  /**
   * [List for productUsageAndSafety]
   * @author Lalitkumar Saindane
   * @return [List]
   */
  getStorageAndDisposalList() {

    const productUsageAndSafetyModuleTypePath = 'data.modules.ProductUsageAndSafetyModuleType';
    let path: Array<string> = productUsageAndSafetyModuleTypePath.split('.');
    let result = this.appService.findObjectByPath(this.smartLabelData, path);
    let list, item, output = false;


    if (result && typeof result === 'object') {

      if (result.hasOwnProperty('productUsageAndSafety') && result.productUsageAndSafety) {

        list = {
          "ctrlName": "list",
          "title": "Storage And Disposal",
          "config": {
            "class": "smartlabel-list",
          },
          "controls": []
        }

        if (result.productUsageAndSafety.hasOwnProperty('itemMinimumDurability') && result.productUsageAndSafety.itemMinimumDurability.hasOwnProperty("value")) {
          if (result.productUsageAndSafety.itemMinimumDurability.value) {
            item = {
              "ctrlName": "list-item",
              "config": {
                "label": "Use By",
                "content":
                  `${result.productUsageAndSafety.itemMinimumDurability.value}
             {{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.itemMinimumDurability.timeMeasurementUnitCode | mapTo: listItemName}}`

              }
            }
          }


          list.controls.push(item);

        }

        if (result.productUsageAndSafety.hasOwnProperty('itemPeriodSafeToUseAfterOpening') && result.productUsageAndSafety.itemPeriodSafeToUseAfterOpening.hasOwnProperty("value")) {
          if (result.productUsageAndSafety.itemPeriodSafeToUseAfterOpening.value) {
            item = {
              "ctrlName": "list-item",
              "config": {
                "label": "Best If Used By",
                "content":

                  `${result.productUsageAndSafety.itemPeriodSafeToUseAfterOpening.value}
             {{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.itemPeriodSafeToUseAfterOpening.timeMeasurementUnitCode | mapTo: listItemName}}`

              }
            }
          }


          list.controls.push(item);

        }

        if (result.productUsageAndSafety.hasOwnProperty('dateMarkStatement') && result.productUsageAndSafety.dateMarkStatement) {

          item = {
            "ctrlName": "list-item",
            "config": {
              "label": '',
              "content": result.productUsageAndSafety.dateMarkStatement
            }
          }

          list.controls.push(item);

        }
        //return list only if listItems are present
        if (list && list.controls.length > 0) {
          output = list;
        }

      }

    }
    return output;

  }

  /**
   * [Storage And Disposal Page Layout]
   * @author Lalitkumar Saindane
   * @return [description]
   */
  getStorageAndDisposalPageLayout() {
    let storageAndDisposalList = this.getStorageAndDisposalList();
    //if(storageAndDisposalList) {
    return {
      "alias": "storage-and-disposal",
      "title": "Storage And Disposal",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(1),// health-safety-and-environment
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),   // ingredients-and-contents,drug-facts
                "title": this.getTabTitle(0), // Ingredients & Contents,Drug Facts
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents,drug-facts
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search']
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),     // health-safety-and-environment
                "title": this.getTabTitle(1),   // Health, Safety & Environment
                "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle']
                "show": true
              },
              "controls": [
                ...(storageAndDisposalList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "Storage And Disposal",
                    "headingType": 1
                  }
                },
                  storageAndDisposalList] : []),

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2),   // usage-and-handling
                "title": this.getTabTitle(2), // Usage & Handling
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2),   // ['far', 'hand-point-up']
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // Features & Benefits
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3), //['fas', 'info-circle'],
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),  // company-brand-and-sustainability
                "title": this.getTabTitle(4), // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'],
                "show": true
              },
              "controls": [
              ]
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])

      ]
    };
    //  } else {
    //    return false;
    //  }

  }

  /**
 * [List for ProductSustainabilityModuleType]
 * @author Lalitkumar Saindane
 * @return [List]
 */
  getProductSustainabilityModuleTypeList() {

    let productSustainabilityModuleTypePath = 'data.modules.ProductSustainabilityModuleType';
    let path: Array<string> = productSustainabilityModuleTypePath.split('.');
    let result = this.appService.findObjectByPath(this.smartLabelData, path);
    let list, item, statement, statements = '', output = [];

    list = {
      "ctrlName": "list",
      "title": "Environment",
      "config": {
        "class": "smartlabel-list",
      },
      "controls": []
    }

    //GHS
    let ghsListItems = this.ghsEffectsOnEnvList();
    if (ghsListItems) {
      list.controls.push(ghsListItems);
    }

    if (result) {
      if (result.hasOwnProperty('packagingMaterialAbsenceClaims') && result['packagingMaterialAbsenceClaims'].length > 0) {
        let packagingMaterialAbsenceClaimsStatements = [];
        let content = "";
        packagingMaterialAbsenceClaimsStatements = [...result['packagingMaterialAbsenceClaims'].filter((items) => {
          if (items.hasOwnProperty('languageCode')) {
            if (items.languageCode.toLowerCase() === this.pageService.appLang$.getValue()) {
              return items;
            }
          }
        })];
        if (packagingMaterialAbsenceClaimsStatements.length > 0) {
          packagingMaterialAbsenceClaimsStatements.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              content += `<p>${text}</p>`;
            }
          })
        }

        if (content) {
          item = {
            "ctrlName": "list-item",
            "config": {
              "content": content
            }
          }
          list.controls.push(item);
        }
      }

      if (result.hasOwnProperty('packagingReusabilityRenewabilityDescription') && result['packagingReusabilityRenewabilityDescription'].length > 0) {
        let packagingReusabilityRenewabilityDescriptionStatements = [];
        let descriptionStatements = "";
        packagingReusabilityRenewabilityDescriptionStatements = [...result['packagingReusabilityRenewabilityDescription'].filter((items) => {
          if (items.hasOwnProperty('languageCode')) {
            if (items.languageCode.toLowerCase() === this.pageService.appLang$.getValue()) {
              return items;
            }
          }
        })];

        if (packagingReusabilityRenewabilityDescriptionStatements.length > 0) {
          packagingReusabilityRenewabilityDescriptionStatements.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              descriptionStatements += `<p>${text}</p>`;
            }
          })
        }

        if (descriptionStatements) {
          item = {
            "ctrlName": "list-item",
            "config": {
              "content": descriptionStatements
            }
          }
          list.controls.push(item);
        }
      }
    }

    let basicProductInformationModuleTypePath = 'data.modules.BasicProductInformationModuleType';
    let basicProductInformationpath: Array<string> = basicProductInformationModuleTypePath.split('.');
    let basicProductInformationModuleType = this.appService.findObjectByPath(this.smartLabelData, basicProductInformationpath);

    if (basicProductInformationModuleType) {

      if (basicProductInformationModuleType.hasOwnProperty('productInformationLink') && basicProductInformationModuleType['productInformationLink'].length > 0) {
        let sdsLinks = '', sdsLink, links;

        links = basicProductInformationModuleType['productInformationLink'].filter((item) => {
          if (item.productInformationTypeCode === 'SDS' && item.hasOwnProperty('languageCode')) {
            const [langCode] = item['languageCode'].filter((lang) => lang.toLowerCase() === this.pageService.appLang$.getValue());
            if (langCode) {
              return item;
            }
          }
        })

        links.forEach((items) => {
          const url = decodeURIComponent(items.url)
          sdsLink = '<p>' + `<a class="smartlabel-link" target="_blank" href="${url}">${url}</a>` + '</p>';
          sdsLinks += sdsLink;
        })

        if (sdsLinks) {
          item = {
            "ctrlName": "list-item",
            "config": {
              "label": 'Safety Data Sheet',
              "content": sdsLinks
            }
          }
          list.controls.push(item);
        }

        output = basicProductInformationModuleType['productInformationLink'].filter((item) => {
          if (item.productInformationTypeCode === 'RESEARCH_LINK_EFFECT_ON_ENVIRONMENT' && item.hasOwnProperty('languageCode')) {
            const [langCode] = item['languageCode'].filter((lang) => lang.toLowerCase() === this.pageService.appLang$.getValue());
            if (langCode) {
              return item;
            }
          }
        })

        output.forEach((items) => {

          const url = decodeURIComponent(items.url);

          statement = '<p>' + `<a class="smartlabel-link" target="_blank" href="${url}">${url}</a>` + '</p>';
          statements += statement;

        })

        if (statements) {
          item = {
            "ctrlName": "list-item",
            "config": {
              "label": 'Link to Research Studies',
              "content": statements
            }
          }
          list.controls.push(item);
        }
      }
    }
    //added by @Radhika Agnihotri 10/6/2019
    //list item for ACI safety initiative
    // let aciSafetyInitiative = this.getAciSafetyInitiative();
    // if(aciSafetyInitiative) {
    //   list.controls.push(aciSafetyInitiative);
    // }

    if (list.controls.length > 0) {
      return list;
    } else {
      return false;
    }

  }



  /**
     * [Effects On Environment Page Layout]
     * @author Lalitkumar Saindane
     * @return [description]
     */
  getEffectsOnEnvironmentPageLayout() {
    let productSustainabilityModuleTypeList = this.getProductSustainabilityModuleTypeList();

    //if(productSustainabilityModuleTypeList) {
    return {
      "alias": "environment",
      "title": "Environment",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(1), // health-safety-and-environment
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),  // ingredients-and-contents,drug-facts
                "title": this.getTabTitle(0), // Ingredients & Contents,Drug Facts
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents,drug-fact
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search']
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),   // health-safety-and-environment
                "title": this.getTabTitle(1), // Health, Safety & Environment
                "icon": this.getTabIcon(1),   // ['fas', 'exclamation-triangle'],
                "show": true
              },
              "controls": [
                ...(productSustainabilityModuleTypeList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "Environment",
                    "headingType": 1
                  }
                }] : []),

                ...(productSustainabilityModuleTypeList ? [productSustainabilityModuleTypeList] : []),

              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2),   // usage-and-handling
                "title": this.getTabTitle(2), // Usage & Handling
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2), // ['far', 'hand-point-up']
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // 'Features & Benefits',
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3),   // ['fas', 'info-circle'],
                "show": true
              },
              "controls": [
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),  // company-brand-and-sustainability
                "title": this.getTabTitle(4), // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4),  // ['fas', 'external-link-square-alt']
                "show": true
              },
              "controls": [
              ]
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
    //  } else {
    //  return false
    //  }

  }

  /**
   * [formatPageLinkURL description]
   * @param  pageLink  [description]
   * @param  listIndex [description]
   * @author
   * @return           [description]
   */
  formatPageLinkURL(pageLink, listIndex) {
    if (pageLink) {
      let link = JSON.parse(JSON.stringify(pageLink));
      if (listIndex) {

        if (link.hasOwnProperty('url')) {

          link.url += `&listIndex=${listIndex}`;
        }

      }

      return link;
    }


  }

  /**
   * [List of nonfoodIngredient]
   * @param ingredientsList [Array of ingredientsList]
   * @return [ingredientList]
   * @author Lalitkumar Saindane
   * @task QLIKTAG-3037
   */
  ingredientListConfig(ingredientsList: Array<any>, listIndex?, level = 0, status?: string) {
    const nestedKey = 'subIngredient';
    const pageLinks = [
      { "url": `p/${this.pageService.pageAlias}?detail=ingredient-item`, "type": "detail" },
      { "url": `p/${this.pageService.pageAlias}?detail=sub-ingredient-item`, "type": "detail" }]

    let result = {
      "ctrlName": "list",
      "config": {
        "class": "smartlabel-list"
      },
      "controls": []
    }
    let isFirstActiveFound: boolean = false;
    let levels = [
      'data.modules.NonfoodIngredientInformationModuleType.nonfoodIngredient.%index0%',
      'data.modules.NonfoodIngredientInformationModuleType.nonfoodIngredient.%index0%.subIngredient.%index1%'
    ]
    let lid;
    if (ingredientsList && ingredientsList.length > 0) {

      let listItem;
      let itemListConfig;
      const coc = `<div class="coc-text"><i><small>Chemical of Concern</small></i></div>`;
      const dl = `<ion-badge mode="ios" style="float: right;" class="smartlabel-badge">DL</ion-badge>`

      ingredientsList.forEach((item, index) => {

        listItem = {
          "ctrlName": "list-item",
          "config": {
            "label": item.hasOwnProperty('ingredientName') ?
              `${this.multiLangPipe.transform(item.ingredientName)}` : '',
            "content": '',
            "url": this.formatPageLinkURL(pageLinks[level],


              listIndex ? `${listIndex}_${index}` : index)
          },
          "controls": []
        }

        if (item.hasOwnProperty('chemicalOfConcern') && item.chemicalOfConcern) {
          if (this.isCategoryHousehold() && this.isSubjectToCARegulations()) {
            listItem.config.label += this.multiLangPipe.transform(dl);
          } else {
            listItem.config.label += this.multiLangPipe.transform(coc);
          }
        }

        //QLIKTAG-3159 hide sub-ingredients on the outer page
        //Commented by NEil C

        // if(item.hasOwnProperty(nestedKey)) {
        //   level = level+1;
        //
        //   itemListConfig = this.ingredientListConfig(item[nestedKey], index, level);
        //   if(level != 0) {
        //     level = level-1;
        //   }
        //
        //   if(itemListConfig) {
        //       listItem.controls.push(itemListConfig);
        //   }
        // }   

        if (item.hasOwnProperty("ingredientStrength")) {
          let pathMap;
          lid = listIndex ? `${listIndex}_${index}` : index.toString();

          pathMap = {
            "key": levels[level]
          }
          //resolve index
          pathMap = this.appService.resolveIndex(pathMap, lid);

          let ingredientStrength = this.getIngredientStrength(item, pathMap.key)
          // add ingredientStrength with space
          listItem.config.label += ` ${ingredientStrength}`;
        }
        if (status) {
          let flag = false;
          if (status === 'active') {
            flag = true;
          } else {
            flag = false;
          }
          // When isActive property is present
          if (item.hasOwnProperty('isActive') && item.isActive === flag) {
            if (item.isActive === true) {
              //update ingredientName       
              let text = `${listItem.config.label}`
              //add % to first active ingredient
              let percentageOfAlcohol = this.getPOAL();
              if (!isFirstActiveFound && percentageOfAlcohol) {
                isFirstActiveFound = true;
                //call getPercentageOfAlcoholForList
                text += ` ${percentageOfAlcohol}`
              } else {
                let ingredientContentPercentage = this.getIngredientContentPercentage(item);
                if (ingredientContentPercentage) {
                  text += ` ${ingredientContentPercentage}`
                }
              }

              //replace label text with updated values
              listItem.config.label = text;
            }
            result.controls.push(listItem);

          }
          //  When isActive property is NOT present add to inactive ingredients
          if (!flag && !item.hasOwnProperty('isActive')) {
            result.controls.push(listItem);
          }
        } else {
          result.controls.push(listItem);
        }
      })



    }

    return result;
  }

  /**
   * [getSmartlabelConfig Finds smartlabel control in given page and returns it's config]
   * @param  page [ Page object with smartlabel control ]
   * @return      [ smartlabel config object ]
   * @author - SwapnilP
   * @task - Qliktag-2925
   */
  getSmartlabelConfig(page: Page): any {
    let result = {};
    if (page.hasOwnProperty('controls') && page.controls.length) {
      const found = page.controls.filter((control) => {
        return control.ctrlName === 'smartlabel';
      });
      if (found.length) {
        result = found[0].config;
      }
    }
    return result;
  }

  /**
   * get about-product page layout
   * @author Radhika Agnihotri
   *
   */
  getAboutProductPageLayout() {
    let list = this.getAboutProductPageList();
    return {
      "alias": "about-product",
      "title": "About Product",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // ingredients-and-contents(NON-FOOD) || drug-facts(OTC-DRUG)
                "title": this.getTabTitle(0), // Ingredients & contents (NON-FOOD) || Drug Facts(OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //  ['fas', 'list'] (NON-FOOD) || ['fas', 'search'] (OTC-DRUG),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),// health-safety-and-environment(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(1),  // Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(2),  // Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),//['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(3), // Features & Benefits(NON-FOOD, OTC-DRUG)
                "icon": this.getTabIcon(3), // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
                ...(list ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "Features &amp; Benefits",
                    "headingType": 1
                  }
                },
                {
                  "ctrlName": "jumbotron",
                  "config": {
                    "class": "smartlabel-jumbotron m-r-15 m-l-15",
                    "content": "Claims listed within SmartLabel® are either governed by entities such as the USDA, FDA, EPA, etc., or defined by participating brands."
                  }
                },
                  list] : []),
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    }
  }

  /**
   * get TAB-3:Usage and Handling config
   * @author Radhika Agnihotri
   */
  getUsageAndHandlingConfig() {
    let result;
    let listItems: Array<any> = [];
    let productInstructionsModuleTypePath = "data.modules.ProductInstructionsModuleType";
    let path: Array<any>;
    let storageStatement = '';
    let usageStatement = '';
    const currentLang = this.pageService.appLang$.getValue();

    path = productInstructionsModuleTypePath.split(".");
    let productInstructionsModuleType = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productInstructionsModuleType) {
      if (productInstructionsModuleType.hasOwnProperty("consumerUsageInstructions")) {
        let statements = [];

        if (productInstructionsModuleType.consumerUsageInstructions.length > 0) {
          statements = productInstructionsModuleType.consumerUsageInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
        }

        if (statements.length > 0) {
          statements.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              usageStatement += `<p>${text}</p>`
            }
          });
        }

        //Add listItem for consumer usage instructions
        if (usageStatement) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": "Usage Instructions",
              "content": usageStatement
            }
          })
        }
      }

      if (productInstructionsModuleType.hasOwnProperty("consumerStorageInstructions")) {
        let statements = [];

        if (productInstructionsModuleType.consumerStorageInstructions.length > 0) {
          statements = productInstructionsModuleType.consumerStorageInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
        }

        if (statements.length > 0) {
          statements.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              storageStatement += `<p>${text}</p>`
            }
          });
        }
        //Add listItem for consumer storage instructions
        if (storageStatement) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": "Storage Instructions",
              "content": storageStatement
            }
          })
        }
      }

    }
    //return list if listItems are present (else function returns undefined)
    if (listItems.length > 0) {
      result = {
        "ctrlName": "list",
        "title": "Usage and Handling",
        "config": {
          "class": "smartlabel-list",
        },
        "controls": listItems
      };
    }
    //console.log("result", result);
    // no list control if listItems are not present
    return result;
  }

  /**
   * get allergen information listItem
   * branch: tab-2-a-effectsOnHumanHealth
   * @author Radhika Agnihotri
   */
  getAllergenRelatedInformation() {

    let result;
    let allergenRelatedInformationPath = "data.modules.ProductAllergenInformationModuleType.allergenRelatedInformation";
    //listItem 1: Allergen Information
    let path = allergenRelatedInformationPath.split(".");
    let allergenRelatedInformation = this.appService.findObjectByPath(this.smartLabelData, path)
    let content = "";
    let statements = [];
    const currentLang = this.pageService.appLang$.getValue();

    if (allergenRelatedInformation && allergenRelatedInformation.length > 0) {
      allergenRelatedInformation.map((element) => {
        if (element.hasOwnProperty("allergenStatement") && Array.isArray(element.allergenStatement)) {
          statements = [...statements, ...element.allergenStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        }
      })

      if (statements.length > 0) {
        statements.forEach(element => {
          if (element.hasOwnProperty("value") && element.value) {


            let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
            content += `<p>${text}</p>`;
          }
        });
      }
      //only if content is present, create listItem
      if (content) {
        result = {
          "ctrlName": "list-item",
          "config": {
            "label": "Allergen Information",
            "content": content
          }
        }
      }

    }
    return result;
  }


  /**
   * get warning and advisory statements
   * @author Radhika Agnihotri
   */
  getWarningAndAdvisoryStatement() {
    let result;
    let content = "";
    let productUsageAndSafetyPath = "data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety";
    //listItem 2 : Warning Statement
    let path = productUsageAndSafetyPath.split(".");
    let productUsageAndSafety = this.appService.findObjectByPath(this.smartLabelData, path);
    let warningPrecautionStatements = [];
    let advisoryStatements = [];
    const currentLang = this.pageService.appLang$.getValue();


    if (productUsageAndSafety) {
      //check if module has Product Usage and Safety module
      if (productUsageAndSafety.hasOwnProperty("warningPrecautionStatement")) {
        warningPrecautionStatements = [...warningPrecautionStatements, ...productUsageAndSafety.warningPrecautionStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]

        if (warningPrecautionStatements.length > 0) {
          warningPrecautionStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              const text: string = this.linkyPipe.transform(item.value, { className: 'smartlabel-link' });
              content += `<p>${text}</p>`;
            }
          });
        }

      }
      if (productUsageAndSafety.hasOwnProperty("advisoryStatement")) {
        advisoryStatements = [...advisoryStatements, ...productUsageAndSafety.advisoryStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]

        if (advisoryStatements.length > 0) {
          advisoryStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              content += `<p>${item.value}</p>`;
            }
          });
        }

      }

      if (content) {
        result = {
          "ctrlName": "list-item",
          "config": {
            "label": "Precautions / Warnings",
            "content": content
          }
        };
      }
    }

    return result;
  }

  /**
   * get list Item for links [ ResearchLink]
   * @author Radhika Agnihotri
   * @task tab-2-a-effectsOnHumanHealth
   */
  getProductInfoLink(productInformationTypeCode: string, label: any) {
    let result;
    let basicProductInformationModuleTypePath = 'data.modules.BasicProductInformationModuleType';
    let basicProductInformationpath: Array<string> = basicProductInformationModuleTypePath.split('.');
    let basicProductInformationModuleType = this.appService.findObjectByPath(this.smartLabelData, basicProductInformationpath);
    let statement;
    let statements = '';
    let output = [];
    let lowerlang = [];

    if (basicProductInformationModuleType) {
      if (basicProductInformationModuleType.hasOwnProperty('productInformationLink') && basicProductInformationModuleType['productInformationLink'].length > 0) {
        output = basicProductInformationModuleType['productInformationLink'].filter(
          (item) => {
            if (item.productInformationTypeCode === productInformationTypeCode) {
              if (item.hasOwnProperty('languageCode')) {
                lowerlang = item.languageCode.map((lang) => lang.toLowerCase());
              }
              if (lowerlang.includes(this.pageService.appLang$.getValue())) {
                return item;
              }
            }
          }
        )
        output.forEach((items) => {

          const url = decodeURIComponent(items.url);


          if (items.url && items.url != '') {
            statement = `<p><a target="_blank" class="smartlabel-link" href="${url}">${url}</a></p>`;
            statements += statement;


          }


        })
        if (statements) {
          result = {
            "ctrlName": "list-item",
            "config": {
              "label": label,
              "content": statements
            }
          }
        }
      }
    }

    //console.log(result);
    return result;
  }

  /**
   * get list for Effects on Human Health page
   * @author Radhika Agnihotri
   */
  getEffectsOnHumanHealthPageList() {

    let listItems: Array<any> = [];
    let list;

    let ghsInformation = this.ghsEffectsOnHumanHealth()
    if (ghsInformation) {
      listItems.push(ghsInformation)
    }

    let allergenRelatedInformation = this.getAllergenRelatedInformation();
    if (allergenRelatedInformation) {
      listItems.push(allergenRelatedInformation)
    }

    let warningAndAdvisoryStatements = this.getWarningAndAdvisoryStatement();
    if (warningAndAdvisoryStatements) {
      listItems.push(warningAndAdvisoryStatements)
    }
    //pass productInformationTypeCode and label to function
    let sdsLink = this.getProductInfoLink("SDS", "Safety Data Sheet");
    if (sdsLink) {
      listItems.push(sdsLink);
    }
    //pass productInformationTypeCode and label to function
    let researchStudyLink = this.getProductInfoLink("RESEARCH_LINK_EFFECT_ON_HUMAN_HEALTH", "Link to Research Studies");
    if (researchStudyLink) {
      listItems.push(researchStudyLink);
    }

    // //ACI Safety Initiative
    // let aciSafetyInitiative = this.getAciSafetyInitiative();
    // if(aciSafetyInitiative) {
    //   listItems.push(aciSafetyInitiative);
    // }

    if (listItems.length > 0) {
      list = {
        "ctrlName": "list",
        "title": "Human Health",
        "config": {
          "class": "smartlabel-list",
        },
        "controls": listItems
      }

    }
    return list;
  }

  /**
   * get tab4-about-product page list
   * @author Radhika Agnihotri
   */
  getAboutProductPageList() {
    let listItems: Array<any> = [];
    let list;
    let path: Array<string>;
    //PATHS
    const basicProductInformationModuleTypePath = "data.modules.BasicProductInformationModuleType";

    let basicProductInformationModule;

    path = basicProductInformationModuleTypePath.split(".");
    basicProductInformationModule = this.appService.findObjectByPath(this.smartLabelData, path);

    let output;
    let otherClaimStatements: Array<any> = [];
    let nutritionalClaimStatements: Array<any> = [];
    let healthClaimDescriptions: Array<any> = [];
    let regulatoryComplianceCodes: Array<any> = [];
    let nutritionalClaimCodes: Array<any> = [];
    let dietaryClaimCodes: Array<any> = [];

    if (basicProductInformationModule && basicProductInformationModule.hasOwnProperty("consumerMarketingDescription") && basicProductInformationModule.consumerMarketingDescription.length > 0) {

      output = basicProductInformationModule.consumerMarketingDescription.filter(
        (item) => item.hasOwnProperty('languageCode') && item['languageCode'].toLowerCase() === this.pageService.appLang$.getValue()
      );

      if (output.length > 0) {
        output.forEach(element => {
          if (element.hasOwnProperty("value") && element.value) {
            const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
            let statement = `<p>${text}</p>`;
            listItems.push(
              {
                "ctrlName": "list-item",
                "config": {
                  "label": "",
                  "content": statement
                }
              });
          }
        });
      }
    }




    otherClaimStatements = this.getOtherClaimStatements();
    if (otherClaimStatements.length > 0) {
      listItems = [...listItems, ...otherClaimStatements]
    }

    nutritionalClaimCodes = this.getNutritionalClaimCodes();
    if (nutritionalClaimCodes.length > 0) {
      listItems = [...listItems, ...nutritionalClaimCodes];
    }

    nutritionalClaimStatements = this.getNutritionalClaimStatements();
    if (nutritionalClaimStatements.length > 0) {
      listItems = [...listItems, ...nutritionalClaimStatements];
    }

    healthClaimDescriptions = this.getHealthClaimDescriptions();
    if (healthClaimDescriptions.length > 0) {
      listItems = [...listItems, ...healthClaimDescriptions];
    }

    dietaryClaimCodes = this.getDietaryClaimCodes();
    if (dietaryClaimCodes.length > 0) {
      listItems = [...listItems, ...dietaryClaimCodes];
    }

    regulatoryComplianceCodes = this.getRegulatoryComplianceCodes();
    if (regulatoryComplianceCodes.length > 0) {
      listItems = [...listItems, ...regulatoryComplianceCodes]
    }

    if (listItems.length > 0) {
      list = {
        "ctrlName": "list",
        "config": {
          "class": "smartlabel-list p-16"
        },
        "controls": listItems
      };
    }
    return list;
  }


  /** set meta tags for smartlabel
   * @param smartLabelData
   * @author Radhika Agnihotri
   * @task QLIKTAG-3032
   */
  setMetaTags() {
    let imagePath = 'data.modules.BasicProductInformationModuleType.imageLink';
    let image;
    let title;
    let description;
    let url: string;

    let tags = [
      { property: "og:type", content: "website" },
      { name: "twitter:card", content: "summary" },
    ];

    image = this.getProductImages(imagePath, 1).shift();
    //add url of first element in imageLink array
    if (image && image.hasOwnProperty("url")) {
      tags.push(
        { property: "og:image", content: `${image.url}` },
        // {name: "twitter:image", content: `${image.url}`}
      )
    }

    title = this.getProductName();
    if (title) {
      let titleText = this.multiLangPipe.transform(title);
      tags.push(
        { property: "og:title", content: `${titleText} - SmartLabel™` },
        { name: "twitter:title", content: `Checkout ${titleText} product information via SmartLabel™ at` },
      )
    }

    description = this.getConsumerMarketingDescription();
    if (description) {
      let descriptionText = this.multiLangPipe.transform(description);
      tags.push(
        { property: "og:description", content: `${descriptionText}` },
        // {name: "twitter:description", content: `${descriptionText}`},
      )
    }

    url = window.location.href;
    tags.push({ property: "og:url", content: url })

    this.seoService.addTags(tags);

  }

  /**
   * unset meta tags
   * @author Radhika Agnihotri
   * @task QLIKTAG-3032
   */
  unsetMetaTags() {

    let attributeSelectors = ['name="twitter:card"', 'name="twitter:title"', 'name="twitter:description"', 'name="twitter:image"', 'property="og:url"', 'property="og:type"', 'property="og:title"', 'property="og:description"', 'property="og:image"'];

    attributeSelectors.forEach((item) => {
      this.seoService.removeTag(item);
    })

  }


  /**
   * get product name from smartlabel data
   * @task QLIKTAG-3032
   * @author Radhika Agnihotri
   */
  getProductName() {
    let result;
    let productNamePath = "data.modules.BasicProductInformationModuleType.productName";
    let path: Array<string> = productNamePath.split('.');
    let productName = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productName) {
      result = productName;
    }
    return result;
  }

  /**
   * get consumerMarketingDescription from smartlabel data
   * @task QLIKTAG-3032
   * @author Radhika Agnihotri
   */
  getConsumerMarketingDescription() {
    let result;
    let consumerMarketingDescriptionPath = "data.modules.BasicProductInformationModuleType.consumerMarketingDescription";
    let path: Array<string> = consumerMarketingDescriptionPath.split('.');
    let consumerMarketingDescription = this.appService.findObjectByPath(this.smartLabelData, path);

    if (consumerMarketingDescription) {
      result = consumerMarketingDescription;
    }
    return result;
  }

  /**
   * get listItems for otherClaimsStatements
   * @author Radhika Agnihotri
   * @task QLIKTAG-3053
   */
  getOtherClaimStatements(): Array<any> {
    let result = [];
    const productClaimsAndEndorsementsModuleTypePath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let productClaimsAndEndorsementsModule;
    let path: Array<string>;
    const currentLang = this.pageService.appLang$.getValue();
    let statements = [];

    path = productClaimsAndEndorsementsModuleTypePath.split(".");
    productClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productClaimsAndEndorsementsModule && productClaimsAndEndorsementsModule.hasOwnProperty("otherClaimStatement")) {
      if (productClaimsAndEndorsementsModule.otherClaimStatement.length > 0) {
        statements = productClaimsAndEndorsementsModule.otherClaimStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
      }
    }
    if (statements.length > 0) {
      statements.forEach(element => {
        if (element.hasOwnProperty("value") && element.value) {
          let statement = `<p>${element.value}</p>`;
          result.push(
            {
              "ctrlName": "list-item",
              "config": {
                "label": "",
                "content": statement
              }
            });
        }
      });
    }

    return result;
  }

  /**
   * get nutritionalClaimStatements listItems for about-product page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3053
   */
  getNutritionalClaimStatements(): Array<any> {
    let result = [];
    const productClaimsAndEndorsementsModuleTypePath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let productClaimsAndEndorsementsModule;
    let path: Array<string>;
    const currentLang = this.pageService.appLang$.getValue();
    let statements = [];

    path = productClaimsAndEndorsementsModuleTypePath.split(".");
    productClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productClaimsAndEndorsementsModule && productClaimsAndEndorsementsModule.hasOwnProperty("nutritionalClaimStatement")) {
      statements = productClaimsAndEndorsementsModule.nutritionalClaimStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
    }

    if (statements.length > 0) {
      statements.forEach(element => {
        if (element.hasOwnProperty("value") && element.value) {
          const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
          let statement = `<p>${text}</p>`;
          result.push(
            {
              "ctrlName": "list-item",
              "config": {
                "label": "",
                "content": statement
              }
            });
        }
      });
    }
    return result;
  }

  /**
   * get healthClaimDesription listItems for about-product page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3053
   */
  getHealthClaimDescriptions(): Array<any> {
    let result = [];
    const productClaimsAndEndorsementsModuleTypePath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let productClaimsAndEndorsementsModule;
    let path: Array<string>;
    const currentLang = this.pageService.appLang$.getValue();
    let statements = [];

    path = productClaimsAndEndorsementsModuleTypePath.split(".");
    productClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productClaimsAndEndorsementsModule && productClaimsAndEndorsementsModule.hasOwnProperty("healthClaimDescription")) {
      statements = productClaimsAndEndorsementsModule.healthClaimDescription.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
    }

    if (statements.length > 0) {
      statements.forEach(element => {
        if (element.hasOwnProperty("value") && element.value) {
          let statement = `<p>${element.value}</p>`;

          result.push(
            {
              "ctrlName": "list-item",
              "config": {
                "label": "",
                "content": statement
              }
            });
        }
      });
    }
    return result;
  }

  /**
   * get regulatoryComplianceCodes listItems for about-product page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3053
   */
  getRegulatoryComplianceCodes(): Array<any> {
    let result = [];
    const productClaimsAndEndorsementsModuleTypePath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let productClaimsAndEndorsementsModule;
    let path: Array<string>;
    let text;
    path = productClaimsAndEndorsementsModuleTypePath.split(".");
    productClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productClaimsAndEndorsementsModule && productClaimsAndEndorsementsModule.hasOwnProperty("regulatoryCompliance")) {
      if (productClaimsAndEndorsementsModule.regulatoryCompliance.length > 0) {
        productClaimsAndEndorsementsModule.regulatoryCompliance.forEach((element, index) => {
          if (element.hasOwnProperty("regulatoryComplianceCode") && element.regulatoryComplianceCode) {

            text = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.regulatoryCompliance.${index}.regulatoryComplianceCode | mapTo: listItemName}}`);


            if (text) {
              let statement = `<p>${text}</p>`;
              result.push(
                {
                  "ctrlName": "list-item",
                  "config": {
                    "label": "",
                    "content": statement
                  }
                });
            }
          }
        });
      }
    }

    return result;
  }

  /**
   * get nutritionalClaimCodes listItems for about-product page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3053
   */
  getNutritionalClaimCodes(): Array<any> {
    let result = [];

    const productClaimsAndEndorsementsModuleTypePath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let productClaimsAndEndorsementsModule;
    let path: Array<string>;
    let text;
    path = productClaimsAndEndorsementsModuleTypePath.split(".");
    productClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productClaimsAndEndorsementsModule && productClaimsAndEndorsementsModule.hasOwnProperty("nutritionalClaimCode")) {
      if (productClaimsAndEndorsementsModule.nutritionalClaimCode.length > 0) {
        productClaimsAndEndorsementsModule.nutritionalClaimCode.forEach((element, index) => {

          let icon =
            this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.nutritionalClaimCode.${index} | mapTo: additionalInformation | find:fieldCode:logo | mapTo: fieldValue}}`);


          text = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.nutritionalClaimCode.${index} | mapTo: listItemName}}`);

          if (text) {
            let statement = '';
            if (icon) {
              statement += `<img src="${icon}" alt="${element}" class="smartlabel-certification-logo" loading="lazy"/>`
            }
            if (text) {
              statement += `<h2 class="f-w-400">${text}</h2>`
            }

            //console.log('statement', statement)

            if (statement && statement != '') {
              result.push(
                {
                  "ctrlName": "list-item",
                  "config": {
                    "label": "",
                    "content": statement
                  }
                }
              );
            }

          }
        });
      }
    }

    return result;
  }

  /**
   * get dietaryClaimCodes listItems for about-product page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3053
   */
  getDietaryClaimCodes(): Array<any> {
    let result = [];

    const productClaimsAndEndorsementsModuleTypePath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let productClaimsAndEndorsementsModule;
    let path: Array<string>;
    let text;
    path = productClaimsAndEndorsementsModuleTypePath.split(".");
    productClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, path);

    if (productClaimsAndEndorsementsModule && productClaimsAndEndorsementsModule.hasOwnProperty("dietaryClaimCode")) {
      if (productClaimsAndEndorsementsModule.dietaryClaimCode.length > 0) {
        productClaimsAndEndorsementsModule.dietaryClaimCode.forEach((element, index) => {

          let icon = //this.appService.getListCodeIcon(element, "logo");
            this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.dietaryClaimCode.${index} | mapTo:additionalInformation | find:fieldCode:logo | mapTo:fieldValue }}`);


          text = //this.multiLangPipe.transform(element.listItemName);
            this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypePath}.dietaryClaimCode.${index} | mapTo:listItemName}}`)

          if (text) {
            let statement = '';

            if (icon) {
              statement += `<img src="${icon}" alt="${element}" class="smartlabel-certification-logo" loading="lazy"/>`
            }
            if (text) {
              statement += `<h2 class="f-w-400">${text}</h2>`;
            }
            if (statement && statement != '') {
              result.push(
                {
                  "ctrlName": "list-item",
                  "config": {
                    "label": "",
                    "content": statement
                  }
                }
              );
            }

          }
        });
      }
    }

    return result;
  }

  // /**
  //  * get listItem for ACI safety Initiative
  //  * @author Radhika Agnihotri
  //  * @task QLIKTAG-2927
  //  */
  // getAciSafetyInitiative() {
  //   //console.log("getAciSafetyInitiative")
  //   const aciSafetyInitiativeLink = "https://www.cleaninginstitute.org/industry-priorities/science/cleaning-product-ingredient-safety-initiative";
  //   let result;

  //   if(this.smartLabelData && this.smartLabelData.hasOwnProperty("product"))
  //   {
  //     if(this.smartLabelData.product.hasOwnProperty("productCategory")) {
  //       if(this.smartLabelData.product.productCategory != '') {
  //         if(this.smartLabelData.product.productCategory.toLowerCase() == 'household') {
  //           result = {
  //             "ctrlName": "list-item",
  //             "config": {
  //             "label": "ACI Safety Initiative",
  //             "content":`<p><a class="smartlabel-link" href=${aciSafetyInitiativeLink}>${aciSafetyInitiativeLink}</a></p>`
  //            }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return result;
  // }

  /**
   * check if IngredientName has Fragrance || Parfum || Fragrance (Parfum)
   * @param ingredientPurpose [ingredientPurpose array]
   * @author Radhika Agnihotri
   * @task QLIKTAG-2926
   */
  isFragrance(ingredientPurpose: Array<any>): boolean {
    if (ingredientPurpose.length > 0) {
      if (ingredientPurpose.filter(item => item.value.match(/^(Fragrance|Parfum|Fragrance (Parfum))$/)).length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
  * [Smartlabel footers]
  * @param  type [number]
  * @return        [footer control configuration]
  * @author Lalitkumar Saindane
  * @task QLIKTAG-3204
  */

  getFooterConfig(type: number) {

    if (type === 1) {
      return {
        "ctrlName": "rich-text",
        "config": {
          "class": "smartlabel-rich-text smartlabel-footer m-b-10",
          "content": this.smartLabelConfig.footer1,
        }
      }
      //{
      //     "ctrlName": "footer",
      //     "config": {
      //       "sticky": false,
      //       "isContentFooter": true,
      //       "content": this.smartLabelConfig.footer1,
      //       "class": "smartlabel-footer"
      //     }
      //   }


    }

    if (type === 2) {
      return {
        "ctrlName": "rich-text",
        "config": {
          "class": "smartlabel-rich-text smartlabel-footer m-b-10",
          "content": this.smartLabelConfig.footer2,
        }
      }
    }

  }

  /**
    * [Smartlabel footerLinks]
    * @return        [footer-link control configuration]
    * @author Lalitkumar Saindane
    * @task QLIKTAG-3204
    */
  getFooterLinksConfig() {
    const privacyPolicyUrl = this.getPrivacyPolicyUrl(this.smartLabelConfig);
    return {
      "ctrlName": "footer-links",
      "config": {
        "class": "smartlabel-footer-links",
        "links": [
          ...(privacyPolicyUrl ? [privacyPolicyUrl] : [])
        ]
      }
    }
  }

  /**
   * get layout for About Allergens page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3199
   */
  getOtcAllergenPageLayout() {
    //console.log("getotcallergenpagelayout")
    let list = this.getOtcAllergenPageList();
    //console.log("list", list)
    return {
      "alias": "about-allergens",
      "title": "About Allergens",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(1), // health-safety-and-environment(OTC-DRUG)
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // drug-facts(OTC-DRUG)
                "title": this.getTabTitle(0),// Drug Facts (OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0),// ['fas', 'search'] (OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1), // health-safety-and-environment(OTC-DRUG)
                "title": this.getTabTitle(1),  // Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
                ...(list ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "About Allergens",
                    "headingType": 1
                  }
                },
                  list] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(OTC-DRUG)
                "title": this.getTabTitle(2), // Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2), //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(OTC-DRUG)
                "title": this.getTabTitle(3),// Features & Benefits(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                "icon": this.getTabIcon(3),// ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(OTC-DRUG)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    }
  }

  /**
   * get list items for otc-drug About Allergens page
   * @author Radhika Agnihotri
   * @task QLITKAG-3199
   */
  getOtcAllergenPageList() {

    let listItems: Array<any> = [];
    let list;
    let allergenRelatedInformation = this.getAllergenRelatedInformation();
    //sconsole.log("allergenrelated", allergenRelatedInformation)
    if (allergenRelatedInformation) {
      listItems.push(allergenRelatedInformation)
    }

    if (listItems.length > 0) {
      list = {
        "ctrlName": "list",
        "title": "About Allergens",
        "config": {
          "class": "smartlabel-list",
        },
        "controls": listItems
      }

    }
    return list;
  }

  /**
   * [Smartlabel ContactTab]
   * @return   [contact buttons]
   * @author Lalitkumar Saindane
   * @task QLIKTAG-3203
   */
  getContactTabsConfig() {
    let button;
    let segment;
    if (this.smartLabelConfig) {

      button = {
        "ctrlName": "button-segments",
        "config": {
          "class": "smartlabel-button-segments",
          "segments": []
        }
      };

      if (this.smartLabelConfig.hasOwnProperty('email') && this.smartLabelConfig.email) {
        let title = [
          {
            "languageCode": "en",
            "value": "Contact Us"
          },
          {
            "languageCode": "fr",
            "value": "Nous contacter"
          },
          {
            "languageCode": "en_US",
            "value": "Contact Us"
          },
          {
            "languageCode": "en_CA",
            "value": "Contact Us"
          },
          {
            "languageCode": "fr_CA",
            "value": "Nous contacter"
          }
        ]
        segment = {
          "title": this.multiLangPipe.transform(title),
          "icon": ['far', 'envelope'],
          "link": `mailto:${this.smartLabelConfig.email}`,
          "tooltip": `<p style="text-align: center; color: #fff; font-family: 'Trebuchet MS' !important; padding: 10px 0; margin: 0; font-size: 12px;font-weight: bold;">${this.smartLabelConfig.email}</p>`,
          "tooltipClass": "smartlabel-tooltip"
        };
        button.config.segments.push(segment);
      }

      if (this.smartLabelConfig.hasOwnProperty('phone') && this.smartLabelConfig.phone) {
        let title = [
          {
            "languageCode": "en",
            "value": "Call Us"
          },
          {
            "languageCode": "fr",
            "value": "Appelez-nous"
          },
          {
            "languageCode": "en_US",
            "value": "Call Us"
          },
          {
            "languageCode": "en_CA",
            "value": "Call Us"
          },
          {
            "languageCode": "fr_CA",
            "value": "Appelez-nous"
          }
        ];
        segment = {
          "title": this.multiLangPipe.transform(title),
          "icon": "phone",
          "link": `tel:${this.smartLabelConfig.phone}`,
          "tooltip": `<p style="text-align: center; color: #fff; font-family: 'Trebuchet MS' !important; padding: 10px 0; margin: 0; font-size: 12px; font-weight: bold;">${this.smartLabelConfig.phone}</p>`,
          "tooltipClass": "smartlabel-tooltip"
        };
        button.config.segments.push(segment);
      }
    }
    return button;
  }

  /** get "About Expiration Dates" detail page layout
   * @author Radhika Agnihotri
   * @task QLIKTAG-3199
   */
  getOtcExpirationPageLayout() {
    //console.log("getOtcExpirationPageLayout");
    let list = this.getStorageAndDisposalList();
    //console.log("list", list)
    return {
      "alias": "about-expiration-dates",
      "title": "About Expiration Dates",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(1), // health-safety-and-environment(OTC-DRUG)
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // drug-facts(OTC-DRUG)
                "title": this.getTabTitle(0),// Drug Facts (OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //['fas', 'search'] (OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1), // health-safety-and-environment(OTC-DRUG)
                "title": this.getTabTitle(1),// Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "icon": this.getTabIcon(1),// ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
                ...(list ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading p-16 m-0",
                    "text": "About Expiration Dates",
                    "headingType": 1
                  }
                },
                  list] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(OTC-DRUG)
                "title": this.getTabTitle(2), // Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2), //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(OTC-DRUG)
                "title": this.getTabTitle(3),// Features & Benefits(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                "icon": this.getTabIcon(3),// ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(OTC-DRUG)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    }
  }
  /**
* get consumerUsageInstructions listItem for tab3
* @author Lalitkumar Saindane
* @task QLIKTAG-3200
* @returns [listItem]
*/
  getUsageInstructionsList() {
    const consumerUsageInstructionsPath = 'data.modules.ProductInstructionsModuleType.consumerUsageInstructions';
    let path: Array<any> = [];
    let usageStatement = '';
    const currentLang = this.pageService.appLang$.getValue();

    path = consumerUsageInstructionsPath.split('.');
    const consumerUsageInstructions = this.appService.findObjectByPath(this.smartLabelData, path);

    if (consumerUsageInstructions) {
      if (consumerUsageInstructions.length > 0) {
        let statements = [];

        statements = consumerUsageInstructions.filter((item) => {
          if (item.hasOwnProperty('languageCode')) {
            if (item.languageCode.toLowerCase() === currentLang) {
              return item;
            }
          }
        });

        if (statements.length > 0) {
          statements.forEach(element => {
            if (element.hasOwnProperty('value') && element.value) {
              const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              usageStatement += `<p>${text}</p>`;
            }
          });
        }

        // create listItem of consumer usage instructions
        if (usageStatement) {
          return {
            "ctrlName": "list-item",
            "config": {
              "label": "Instructions for Use",
              "content": usageStatement
            }
          };
        }
      }
    }


  }
  /**
   * get consumerStorageInstructions listItem for tab3
   * @author Lalitkumar Saindane
   * @task QLIKTAG-3200
   * @returns [listItem]
   */
  getStorageInstructionsList() {
    const consumerStorageInstructionsPath = 'data.modules.ProductInstructionsModuleType.consumerStorageInstructions';
    let path: Array<any>;
    let storageStatement = '';
    const currentLang = this.pageService.appLang$.getValue();

    path = consumerStorageInstructionsPath.split('.');
    const consumerStorageInstructions = this.appService.findObjectByPath(this.smartLabelData, path);

    if (consumerStorageInstructions) {
      if (consumerStorageInstructions.length > 0) {
        let statements = [];
        statements = consumerStorageInstructions.filter((item) => {
          if (item.hasOwnProperty('languageCode')) {
            if (item.languageCode.toLowerCase() === currentLang) {
              return item;
            }
          }
        });

        if (statements.length > 0) {
          statements.forEach(element => {
            if (element.hasOwnProperty('value') && element.value) {
              const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              storageStatement += `<p>${text}</p>`;
            }
          });
        }

        // create listItem for consumer storage instructions
        if (storageStatement) {
          return {
            "ctrlName": "list-item",
            "config": {
              "label": "Storage Instructions",
              "content": storageStatement
            }
          };
        }
      }
    }


  }

  /** get "Health, Safety & Other Advisories" detail page layout
   * @author Radhika Agnihotri
   * @task QLIKTAG-3199
   */
  getOtcHealthAndSafetyPageLayout() {
    let list = this.getOtcHealthAndSafetyPageList();
    return {
      "alias": "health-safety-and-other-advisories",
      "title": "Health, Safety & Other Advisories",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(1), // health-safety-and-environment(OTC-DRUG)
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // drug-facts(OTC-DRUG)
                "title": this.getTabTitle(0),// Drug Facts (OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //['fas', 'search'] (OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1), // health-safety-and-environment(OTC-DRUG)
                "title": this.getTabTitle(1), // Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "icon": this.getTabIcon(1),// ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": [
                ...(list ? [
                  {
                    "ctrlName": "heading",
                    "config": {
                      "class": "smartlabel-heading p-16 m-0",
                      "text": "Health, Safety & Other Advisories",
                      "headingType": 1
                    }
                  },
                  list
                ] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(OTC-DRUG)
                "title": this.getTabTitle(2),// Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2), //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(OTC-DRUG)
                "title": this.getTabTitle(3), // Features & Benefits(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                "icon": this.getTabIcon(3),  // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(OTC-DRUG)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            }

          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    }
  }

  /** get "Health, Safety & Other Advisories" detail page list
   * @author Radhika Agnihotri
   * @task QLIKTAG-3199
   */
  getOtcHealthAndSafetyPageList() {
    let list;
    let listItems: Array<any> = [];


    let warningAndAdvisoryStatements = this.getWarningAndAdvisoryStatement();
    if (warningAndAdvisoryStatements) {
      listItems.push(warningAndAdvisoryStatements)
    }
    //pass productInformationTypeCode and label to function
    let sdsLink = this.getProductInfoLink("SDS", "Safety Data Sheet");
    if (sdsLink) {
      listItems.push(sdsLink);
    }
    //pass productInformationTypeCode and label to function
    let researchStudyLink = this.getProductInfoLink("RESEARCH_LINK_EFFECT_ON_HUMAN_HEALTH", "Link to Research Studies");
    if (researchStudyLink) {
      listItems.push(researchStudyLink);
    }

    if (listItems.length > 0) {
      list = {
        "ctrlName": "list",
        "title": "Health, Safety & Other Advisories",
        "config": {
          "class": "smartlabel-list",
        },
        "controls": listItems
      }

    }
    return list;

  }

  /**
  * get drugfactspanel from imageLink for tab1
  * @author Lalitkumar Saindane, NeilC
  * @task QLIKTAG-3228
  * @returns [detail page]
  */
  getDrugFactsPanelPageLayout() {
    const imageLinkPath = 'data.modules.BasicProductInformationModuleType.imageLink';
    let path: Array<any>;
    let drugFactsPanelImages = [];
    const currentLang = this.pageService.appLang$.getValue();

    path = imageLinkPath.split('.');

    const imageLink = this.appService.findObjectByPath(this.smartLabelData, path);

    //console.log('imageLink', imageLink);

    if (imageLink && imageLink.length > 0) {
      drugFactsPanelImages = imageLink.filter((item) => {
        console.log(item)
        if (item.hasOwnProperty('imageTypeCode')) {
          if (item.imageTypeCode === 'DRUG_FACTS_PANEL' && item.hasOwnProperty('languageCode')) {
            const [langCode] = item['languageCode'].filter((items) => items.toLowerCase() === currentLang);
            if (langCode && item.hasOwnProperty('url')) {
              return { 'url': item.url };
            }
          }
        }
      });
    }

    //console.log('drugFactsPanelImages', drugFactsPanelImages);
    if (drugFactsPanelImages.length > 0) {
      return {
        "alias": "drug-facts",
        "title": "Drug Facts Panel",
        "mode": "smartlabel",
        "controls": [
          {
            "ctrlName": "header",
            "title": "Smart label header",
            "config": {
              "sticky": true,
              "isBackButtonVisible": true,
              "bgImageColor": "#ffffff",
              "isContentHeader": true,
              "class": "smartlabel-header"
            }
          },
          {
            "ctrlName": "carousel",
            "config": {
              "images": drugFactsPanelImages
            }
          }
        ]
      };
    } else {
      return false;
    }
  }

  /**
  * get tabTitle for product
  * @author Lalitkumar Saindane
  * @returns [tabTitle]
  */
  getTabTitle(index): string {
    let result: any = '';
    let source;
    if (this.smartLabelData) {

      if (this.isProductEntity) {

        if (this.entityInformation.hasOwnProperty('productCategory')) {
          source = this.entityInformation.productCategory;
        }

      } else {

        if (this.smartLabelData.hasOwnProperty('product') && this.smartLabelData.product.hasOwnProperty('productCategory')) {

          const { productCategory } = this.smartLabelData.product

          source = productCategory
        } else if (this.smartLabelData.hasOwnProperty('product') && this.smartLabelData.product.hasOwnProperty('data')) {
          const { productCategory } = this.smartLabelData.product?.data;

          source = productCategory
        }

      }


      if (source) {

        switch (source) {
          case 'OTC_DRUG':
            result = DRUG_FACTS[index].name;
            break;
          case 'PERSONAL_CARE':
            result = NON_FOOD[index].name;
            break;
          case 'FOOD':
            result = this.multiLangPipe.transform(FOOD[index].name);
            break;
          case 'DIETARY_SUPPLEMENT':
            result = this.multiLangPipe.transform(SUPPLEMENT[index].name);
            break;
          default:
            result = NON_FOOD[index].name;
            break;
        }
      }
    }
    return result;
  }
  /**
  * get tabName for product
  * @author Lalitkumar Saindane
  * @returns [tabName]
  */
  getTabName(index): string {
    let result = '';
    let source;
    if (this.smartLabelData) {

      if (this.isProductEntity) {

        if (this.entityInformation.hasOwnProperty('productCategory')) {
          source = this.entityInformation.productCategory;
        }

      } else {



        if (this.smartLabelData.hasOwnProperty('product') && this.smartLabelData.product.hasOwnProperty('productCategory')) {

          const { productCategory } = this.smartLabelData.product

          source = productCategory
        } else if (this.smartLabelData.hasOwnProperty('product') && this.smartLabelData.product.hasOwnProperty('data')) {
          const { productCategory } = this.smartLabelData.product?.data;

          source = productCategory
        }

      }


      if (source) {

        switch (source) {
          case 'OTC_DRUG':
            result = DRUG_FACTS[index].alias;
            break;
          case 'PERSONAL_CARE':
            result = NON_FOOD[index].alias;
            break;
          case 'FOOD':
            result = FOOD[index].alias;
            break;
          case 'DIETARY_SUPPLEMENT':
            result = SUPPLEMENT[index].alias;
            break;
          default:
            result = NON_FOOD[index].alias;
            break;
        }
      }
    }
    return result;
  }
  /**
 * get tabAlias for product
 * @author Lalitkumar Saindane
 * @returns [tabAlias]
 */
  getTabIcon(index): any {
    let result: any = '';
    let source;
    if (this.smartLabelData) {

      if (this.isProductEntity) {

        if (this.entityInformation.hasOwnProperty('productCategory')) {
          source = this.entityInformation.productCategory;
        }

      } else {

        if (this.smartLabelData.hasOwnProperty('product') && this.smartLabelData.product.hasOwnProperty('productCategory')) {

          const { productCategory } = this.smartLabelData.product

          source = productCategory
        } else if (this.smartLabelData.hasOwnProperty('product') && this.smartLabelData.product.hasOwnProperty('data')) {
          const { productCategory } = this.smartLabelData.product?.data;

          source = productCategory
        }

      }



      if (source) {

        switch (source) {
          case 'OTC_DRUG':
            result = DRUG_FACTS[index].icon;
            break;
          case 'PERSONAL_CARE':
            result = NON_FOOD[index].icon;
            break;
          case 'FOOD':
            result = FOOD[index].icon;
            break;
          case 'DIETARY_SUPPLEMENT':
            result = SUPPLEMENT[index].icon;
            break;
          default:
            result = NON_FOOD[index].icon;
            break;
        }
      }
    }
    return result;
  }

  /**
   * [List configuration for socail share]
   * @author Abhishek
   * @task QLIKTAG-2896
   * @return [social config]
   */
  getSocialLinksConfig() {

    let imagePath = 'data.modules.BasicProductInformationModuleType.imageLink';
    let image;
    let title;
    let description;

    image = this.getProductImages(imagePath, 1).shift();

    if (image && image.hasOwnProperty("url")) {
      image = image.url
    } else {
      image = ""
    }

    title = this.getProductName();
    if (title) {
      title = this.multiLangPipe.transform(title);
    } else {
      title = ""
    }

    description = this.getConsumerMarketingDescription();
    if (description) {
      description = this.multiLangPipe.transform(description);
    } else {
      description = ""
    }

    return {
      "ctrlName": "social",
      "config": {
        class: "smartlabel-footer-sticky",
        title: title,
        description: description,
        imageUrl: image,
        pageLink: window.location.href
      }
    }
  }

  /**
   * get image carousel detail page layout
   * @author Radhika Agnihotri
   * @task QLIKTAG-3197
   * @return [product-image detail page layout]
   */
  getProductImagesLayout() {
    // console.log("getProductImagesLayout")
    let productImages = [];
    let imagePath = 'data.modules.BasicProductInformationModuleType.imageLink';

    productImages = this.getProductImages(imagePath);
    // console.log('productImages', productImages)

    return {
      "alias": "product-images",
      "title": "Product Images",
      "mode": "smartlabel",
      "controls": [
        {
          "ctrlName": "header",
          "title": "Smart label header",
          "config": {
            "sticky": true,
            "isBackButtonVisible": true,
            "bgImageColor": "#ffffff",
            "isContentHeader": true,
            "class": "smartlabel-header"
          }
        },
        ...(productImages && productImages.length > 0 ? [{
          "ctrlName": "carousel",
          "config": {
            "images": productImages

          }
        }] : [])
      ]
    };
  }

  /**
   * get information accuracy config
   * @author Radhika Agnihotri
   * @task QLIKTAG-3279
   */
  getInformationAccuracyConfig() {
    let informationAccuracyConfigPath = "data";
    let path: Array<string>;
    path = informationAccuracyConfigPath.split(".");
    let informationAccuracyConfig = this.appService.findObjectByPath(this.smartLabelData, path);
    let result;
    let informationAccuracyText = [
      {
        "languageCode": "en",
        "value": "Information confirmed for accuracy on"
      },
      {
        "languageCode": "fr",
        "value": "Confirmation de l'exactitude des informations sur"
      },
      {
        "languageCode": "en_US",
        "value": "Information confirmed for accuracy on"
      },
      {
        "languageCode": "en_CA",
        "value": "Information confirmed for accuracy on"
      },
      {
        "languageCode": "fr_CA",
        "value": "Confirmation de l'exactitude des informations sur"
      }
    ]
    if (informationAccuracyConfig) {
      if (informationAccuracyConfig.hasOwnProperty('informationAccuracyAsOfDateTime') && informationAccuracyConfig.informationAccuracyAsOfDateTime != '') {

        const date = moment(informationAccuracyConfig.informationAccuracyAsOfDateTime).format('DD-MMM-YYYY');
        const text = this.multiLangPipe.transform(informationAccuracyText);
        result = {
          "ctrlName": "footer",
          "config": {
            "sticky": false,
            "isContentFooter": true,
            "content": `${text} ${date}`,
            "class": "smartlabel-footer"
          }
        }
      }
    }
    return result;
  }

  /**
   * get GHS Listitem for effects on environment page
   * @author Radhika Agnihotri
   * @task QLIKTAG-2927
   */
  ghsEffectsOnEnvList() {
    let result;
    //console.log("getGhsAquaticToxins", this.smartLabelData)
    let declarationPath = 'data.modules.BasicProductInformationModuleType.declaration';
    let path: Array<string>;
    path = declarationPath.split(".");
    let declaration = this.appService.findObjectByPath(this.smartLabelData, path);
    const currentLang = this.pageService.appLang$.getValue();
    let declarationInfo;
    let statement = '';
    if (declaration && declaration.length > 0) {

      //filter to get declarationTypeCode = 'GHS_Effect_On_Environment'
      [declarationInfo] = [...declaration.filter((item) => {
        if (item && item.hasOwnProperty("declarationTypeCode")) {
          if (item.declarationTypeCode.toLowerCase() == 'ghs_effect_on_environment') {
            return item;
          }
        }
      })];

      //filter for languageCodes and update value

      if (declarationInfo && declarationInfo.hasOwnProperty("declarationStatement") && declarationInfo.declarationStatement.length > 0) {
        declarationInfo.declarationStatement.filter((item) => {
          if (item.hasOwnProperty("languageCode") && item.languageCode.toLowerCase() == currentLang) {
            //console.log("ghs-debug:declarationStatement multilang", item)
            return item;
          }
        }).reduce((acc, element) => {
          let key = element.value.toLowerCase().replace(/\s/g, "_");
          if (GHS.hasOwnProperty(key)) {
            statement += `<p><a class="smartlabel-link" target="_blank" href="https://www.osha.gov/dsg/hazcom/ghsguideoct05.pdf">${GHS[key]['title']}</a> ${GHS[key]['description']}</p>`;
          }
        }, '');
      }


      if (statement && statement != '') {

        result = {
          "ctrlName": "list-item",
          "config": {
            "label": "GHS of Hazard Characteristics",
            "content": statement
          }
        };
      }
    }

    //console.log("result", result)
    return result;

  }

  /**
  * get getGhsRelatedInformation listItem for tab2(Effect_On_Human_Health)
  * @author Lalitkumar Saindane
  * @task QLIKTAG-2927
  * @returns [listItem]
  */
  ghsEffectsOnHumanHealth() {

    let ghsInfoPath = "data.modules.BasicProductInformationModuleType.declaration";
    let path = ghsInfoPath.split(".");
    let ghsInfo = this.appService.findObjectByPath(this.smartLabelData, path);
    let statements = '';
    const currentLang = this.pageService.appLang$.getValue();

    if (ghsInfo) {

      if (ghsInfo.length > 0) {

        const [data] = [...ghsInfo.filter((item) => {
          if (item.hasOwnProperty('declarationTypeCode') && item.declarationTypeCode.toLowerCase() === 'ghs_effect_on_human_health') {
            return item;
          }
        })]

        if (data) {
          if (data.hasOwnProperty('declarationStatement')) {
            let statement = [];
            statement = data.declarationStatement.filter((item) => item.languageCode.toLowerCase() === currentLang)
            statements = statement.reduce((acc, codes, index) => {
              if (codes.hasOwnProperty('value')) {
                let code = codes.value.replace(/ /g, '_').toLowerCase();
                if (GHS.hasOwnProperty(code)) {

                  acc += `<p><a target="_blank" href="https://www.osha.gov/dsg/hazcom/ghsguideoct05.pdf" class="smartlabel-link">
                ${GHS[code].title}</a> ${GHS[code].description}</p>`;

                  if (index != statement.length - 1) {
                    acc += `<br>`;
                  }
                }
                return acc;
              }
            }, '');
          }
        }
      }

    }


    if (statements.length > 0) {
      return {
        "ctrlName": "list-item",
        "config": {
          "label": "GHS of Hazard Characteristics",
          "content": statements
        }
      };
    } else {
      return false;
    }
  }

  /**
   * get coc agency richtext config
   * @param coc [cocAgency array]
   * @author Radhika Agnihotri
   * @task QLIKTAG-3276
   */
  getCocConfig(coc: Array<any>) {
    //console.log("getcocconfig", coc)
    let statement = '';
    let result;

    if (coc && Array.isArray(coc) && coc.length > 0) {
      if (this.isCategoryHousehold() && this.isSubjectToCARegulations()) {
        statement = '<p class="smartlabel-heading" >Designated Lists</p>';
      }

      coc.forEach(element => {
        let key = element.replace(/ /g, '_').toLowerCase()
        if (COC_AGENCY.hasOwnProperty(key)) {
          statement += `<p>  
          <a target="_blank" style="text-decoration:none; color: inherit;" href="${COC_AGENCY[key].url}" class="smartlabel-link">
         ${COC_AGENCY[key].description}<ion-icon target="_blank" href="${COC_AGENCY[key].url}" name="open"></ion-icon>
         </a> </p>`
        }
      });
      if (statement && statement != '') {
        result = {
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16",
            "content": statement
          }
        }
      }
    }
    return result;
  }

  /**
   * get ingredient strength value for ingredientName
   * @param ingredient [ingredient information]
   * @param ingredientPath [resolved path for current ingredient]
   * @returns [ingredientStrength: string]
   * @author Radhika Agnihotri
   * @task QLIKTAG-3197
   */
  getIngredientStrength(ingredient, ingredientPath): string {
    //console.log("listindex",ingredient,  ingredientPath)
    let result = '';

    let measurementUnitCode;
    //console.log("ingredient", ingredient)

    if (ingredient.hasOwnProperty("ingredientStrength")) {
      if (ingredient.ingredientStrength.value && ingredient.ingredientStrength.value != '') {
        measurementUnitCode = this.shortCodePipe.transform(`{{SL.${ingredientPath}.ingredientStrength.measurementUnitCode | mapTo: listItemName}}`);
        result = `${ingredient.ingredientStrength.value}${measurementUnitCode ? measurementUnitCode : ''}`
      }
    }
    return result;
  }


  /**
   * get percentage of alcohol for first active ingredient only(inner page)
   * @param index [listIndex]
   * @author Radhika Agnihotri
   * @task QLIKTAG-3197
   */
  getPOAP(index): string {
    let result = '';

    const ingredientsListPath = 'data.modules.NonfoodIngredientInformationModuleType.nonfoodIngredient';
    //get ingredients list
    let ingredientsList = this.getIngredientsByList(ingredientsListPath);
    //find first active ingredient index
    let firstActiveIngredientIndex = ingredientsList.findIndex(item => item.isActive)


    //get percentageOfAlcoholByVolume
    const percentageOfAlcoholByVolumePath = 'data.modules.ProductQuantityInformationModuleType.percentageOfAlcoholByVolume';
    let path = percentageOfAlcoholByVolumePath.split('.');
    const percentageOfAlcoholByVolume = this.appService.findObjectByPath(this.smartLabelData, path);

    //display percentageOfAlcoholByVolume only if first active index
    if (firstActiveIngredientIndex == index) {
      if (percentageOfAlcoholByVolume && percentageOfAlcoholByVolume != '') {
        result = `${percentageOfAlcoholByVolume}%`;
      }
    }
    //console.log("result", result)
    return result;
  }

  /**
   * get percentageOfAlcoholByVolume for outer ingredient list
   * @author Radhika Agnihotri
   * @task QLIKTAG-3197
   */
  getPOAL() {
    let result = '';
    //console.log("getpoal")
    const percentageOfAlcoholByVolumePath = 'data.modules.ProductQuantityInformationModuleType.percentageOfAlcoholByVolume';
    let path = percentageOfAlcoholByVolumePath.split('.');
    const percentageOfAlcoholByVolume = this.appService.findObjectByPath(this.smartLabelData, path);

    if (percentageOfAlcoholByVolume && percentageOfAlcoholByVolume != '') {
      result = `${percentageOfAlcoholByVolume}%`;
    }
    //console.log("result", result)
    return result;
  }

  /**
   * get ingredientContentPercentage
   * @param ingredient 
   * @param ingredientPath 
   * @author Radhika Agnihotri
   * @task QLIKTAG-3197
   */
  getIngredientContentPercentage(ingredient): string {
    let result = '';

    if (ingredient.hasOwnProperty("ingredientContentPercentage") && ingredient.ingredientContentPercentage != '') {
      result = ` (${ingredient.ingredientContentPercentage}%)`;
    }
    return result;
  }

  /**
   * check if product is household
   * @author Radhika Agnihotri
   * @task QLIKTAG-3441
   */
  isCategoryHousehold(): boolean {
    let result: boolean = false;

    if (this.isProductEntity) {

      if (this.entityInformation.hasOwnProperty("productCategory")) {
        if (this.entityInformation.productCategory.toLowerCase() === 'household') {
          result = true;
        }
      }

    } else {

      if (this.smartLabelData.hasOwnProperty("product") && this.smartLabelData.product.hasOwnProperty("data")) {
        if (this.smartLabelData.product.data.hasOwnProperty("productCategory") && this.smartLabelData.product.data.productCategory.toLowerCase() === 'household') {
          result = true;
        }
      }

    }

    return result;
  }

  /**
   * check if product is subject to CA regulations
   * @author Radhika Agnihotri
   * @task @QLIKTAG-3441
   */
  isSubjectToCARegulations(): boolean {
    let result: boolean = false;

    if (this.isProductEntity) {

      if (this.entityInformation.hasOwnProperty('data')) {

        if (this.entityInformation.data.hasOwnProperty("subjectToCARegulations")) {
          result = this.entityInformation.data.subjectToCARegulations;
        }
      }

    } else {

      if (this.smartLabelData.hasOwnProperty("product") && this.smartLabelData.product.hasOwnProperty("data")) {
        if (this.smartLabelData.product.data.hasOwnProperty("subjectToCARegulations") && this.smartLabelData.product.data.subjectToCARegulations) {
          result = true;
        }
      }

    }


    return result;
  }


  /**
   * return detail page layout for 'designated-list'
   * @author Radhika Agnihotri
   * @task QLIKTAG-3439
   */
  getDesginatedListPageLayout() {

    return {
      "alias": "designated-list",
      "title": "Designated Lists",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(0), // ingredients-and-contents(NON-FOOD)
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // ingredients-and-contents(NON-FOOD)
                "title": this.getTabTitle(0), // Ingredients & Contents (NON_FOOD)
                "icon": this.getTabIcon(0), //['fas', 'list'] (NON-FOOD)
                "show": true
              },
              "controls": [
                {
                  "ctrlName": "rich-text",
                  "config": {
                    "content": `<p>
                        <h4 class="smartlabel-heading">Designated Lists</h4>
                        Designated Lists are lists established by a state, federal or international regulatory body. Ingredients listed are identified as allergens, carcinogens, mutagens, pollutants, and/or toxins. Ingredients may appear on more than one list.
                        </p>`,
                    "class": "smartlabel-rich-text p-16"
                  }
                }
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1), // health-safety-and-environment(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(1), // Health, Safety & Environment(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(2),  // Usage & Handling(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),  //['far', 'hand-point-up'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(3),  // Features & Benefits(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`,
                "icon": this.getTabIcon(3),  // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG)
                "title": this.getTabTitle(4), // Company, Brand & Sustainability(NON-FOOD, OTC-DRUG)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG)
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
  }

  /**
   * get about product food page layout
   * @author Venkatesh Pasala 
   * @task QLIKTAG-2901
   */
  getAboutProductFoodPageLayout() {
    let aboutProductFoodPageList = this.getAboutProductFoodPageList();
    let attributeKey = 'product.targetMarket';
    if (this.isProductEntity) {
      attributeKey = 'targetMarket';
    }
    return {
      "alias": "about-this-product",
      "title": "About This Product",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // ingredients-and-contents(NON-FOOD) || drug-facts(OTC-DRUG) || nutrition(FOOD)
                "title": this.getTabTitle(0), // Ingredients & contents (NON-FOOD) || Drug Facts(OTC-DRUG) || Nutrition(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //  ['fas', 'list'] (NON-FOOD) || ['fas', 'search'] (OTC-DRUG) || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),// health-safety-and-environment(NON-FOOD, OTC-DRUG) || ingredients(FOOD)
                "title": this.getTabTitle(1),  // Health, Safety & Environment(NON-FOOD, OTC-DRUG) || Ingredients(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG) || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG) || allergens(FOOD)
                "title": this.getTabTitle(2),  // Usage & Handling(NON-FOOD, OTC-DRUG) || Allergens(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),//['far', 'hand-point-up'](NON-FOOD, OTC-DRUG) || ['fas', 'exclamation-triangle'](FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG, FOOD)  
                "title": this.getTabTitle(3), // Features & Benefits(NON-FOOD, OTC-DRUG, FOOD) 
                "icon": this.getTabIcon(3), // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG, FOOD) 
                "show": true
              },
              "controls": [
                ...(aboutProductFoodPageList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": FOOD_LANG["feature-and-benefits"],
                    "headingType": 1
                  }
                },
                {
                  "ctrlName": "conditional",
                  "config": {
                    "source": "entityInformation",
                    "action": "show",
                    "operator": "matches",
                    "mapping": {
                      "attributeKey": attributeKey
                    },
                    "attributeValue": "840"
                  },
                  "controls": [
                    {
                      "ctrlName": "jumbotron",
                      "config": {
                        "class": "smartlabel-jumbotron m-l-15 m-r-15",
                        "content": FOOD_LANG["feature-and-benefits-about-info"]
                      },
                    }
                  ]
                },
                {
                  "ctrlName": "conditional",
                  "config": {
                    "source": "entityInformation",
                    "action": "show",
                    "operator": "matches",
                    "mapping": {
                      "attributeKey": attributeKey
                    },
                    "attributeValue": "124"
                  },
                  "controls": [
                    {
                      "ctrlName": "jumbotron",
                      "config": {
                        "class": "smartlabel-jumbotron m-l-15 m-r-15",
                        "content": FOOD_LANG["ca-feature-and-benefits-about-info"]
                      }
                    }
                  ]
                },
                  aboutProductFoodPageList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG, FOOD)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG, FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG, FOOD)
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    }
  }

  /**
   * get health and safety food page layout
   * @author Venkatesh Pasala 
   * @task QLIKTAG-2901
   */
  getHealthAndSafetyFoodPageLayout() {
    let healthAndSafetyFoodList = this.getProductUsageAndSafetyFoodPageList();
    return {
      "alias": "health-and-safety",
      "title": "Health & Safety",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
                "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
                "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
                "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
                "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // Features & Benefits
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
                "show": true
              },
              "controls": [
                ...(healthAndSafetyFoodList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": FOOD_LANG["health-safety"],
                    "headingType": 1
                  }
                },
                  healthAndSafetyFoodList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),   // company-brand-and-sustainability,
                "title": this.getTabTitle(4),  // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
  }

  /**
   * get product instructions food page layput 
   * @author Venkatesh Pasala 
   * @task QLIKTAG-2901
   */
  getProductInstructionsFoodPageLayout() {
    let productInstructionsFoodPageList = this.getProductInstructionsFoodPageList();
    return {
      "alias": "product-instructions",
      "title": "Product Instructions",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
                "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
                "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
                "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
                "show": true,
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
                "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // Features & Benefits
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
                "show": true
              },
              "controls": [
                ...(productInstructionsFoodPageList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": FOOD_LANG["product-instructions"],
                    "headingType": 1
                  }
                },
                  productInstructionsFoodPageList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),   // company-brand-and-sustainability,
                "title": this.getTabTitle(4),  // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
  }

  /**
   * get product instructions food page list 
   * @author Venkatesh Pasala 
   * @task QLIKTAG-2901
   */
  getProductInstructionsFoodPageList() {
    {
      let result;
      let listItems: Array<any> = [];
      let productInstructionsModuleTypePath = "data.modules.ProductInstructionsModuleType";
      let path: Array<any>;
      let storageStatement = '';
      let usageStatement = '';
      let feedingStatement = '';
      const currentLang = this.pageService.appLang$.getValue();
      path = productInstructionsModuleTypePath.split(".");
      let productInstructionsModuleType = this.appService.findObjectByPath(this.smartLabelData, path);
      let preparationInformationModuleTypePath = "data.modules.FoodAndBeveragePreparationInformationModuleType";
      let preparationPath: Array<any>;
      preparationPath = preparationInformationModuleTypePath.split(".");
      let preparationInformationModuleTyp = this.appService.findObjectByPath(this.smartLabelData, preparationPath);
      let preparationInstructions = '';
      if (preparationInformationModuleTyp) {
        if (preparationInformationModuleTyp.hasOwnProperty("preparationMethod")) {
          preparationInformationModuleTyp.preparationMethod.map((prepInstructions, prepInstructionsIndex) => {
            if (prepInstructions.hasOwnProperty("preparationInstructions") && Array.isArray(prepInstructions.preparationInstructions)) {
              let preparationStatement = '';
              let preparationCode = this.shortCodePipe.transform(`{{SL.${preparationInformationModuleTypePath}.preparationMethod.${prepInstructionsIndex}.preparationTypeCode | mapTo: listItemName}}`);
              let preparationStatements = prepInstructions.preparationInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang);
              if (preparationStatements.length > 0) {
                preparationStatements.forEach(element => {
                  if (element.hasOwnProperty("value") && element.value) {
                    let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                    preparationStatement += `<p>${text}</p>`
                  }
                });
              }
              if (preparationCode && preparationCode != '') {
                preparationInstructions += `<h2 class="f-w-400">${preparationCode}</p></h2>`;
              }
              if (preparationStatement && preparationStatement != '') {
                preparationInstructions += `<p class="c-black">${preparationStatement}</p>`
              }
            }
          });
          //Add listItem for Preparation Instructions 
          if (preparationInstructions && preparationInstructions != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": FOOD_LANG["preparation-instructions"],
                "content": preparationInstructions,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
      }
      if (productInstructionsModuleType) {
        if (productInstructionsModuleType.hasOwnProperty("feedingGuildlines")) {
          let feedingStatements = [];
          if (productInstructionsModuleType.feedingGuildlines.length > 0) {
            feedingStatements = productInstructionsModuleType.feedingGuildlines.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
          }
          if (feedingStatements.length > 0) {
            feedingStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                feedingStatement += `<p>${text}</p>`
              }
            });
          }
          //Add listItem for feeding guildlines
          if (feedingStatement && feedingStatement != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": FOOD_LANG["feeding-guidelines"],
                "content": feedingStatement,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
        if (productInstructionsModuleType.hasOwnProperty("consumerUsageInstructions")) {
          let usageStatements = [];
          if (productInstructionsModuleType.consumerUsageInstructions.length > 0) {
            usageStatements = productInstructionsModuleType.consumerUsageInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
          }
          if (usageStatements.length > 0) {
            usageStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                usageStatement += `<p>${text}</p>`
              }
            });
          }
          //Add listItem for consumer usage instructions
          if (usageStatement && usageStatement != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": FOOD_LANG["usage-instructions"],
                "content": usageStatement,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
        if (productInstructionsModuleType.hasOwnProperty("consumerStorageInstructions")) {
          let storageStatements = [];
          if (productInstructionsModuleType.consumerStorageInstructions.length > 0) {
            storageStatements = productInstructionsModuleType.consumerStorageInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
          }
          if (storageStatements.length > 0) {
            storageStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                storageStatement += `<p>${text}</p>`
              }
            });
          }
          //Add listItem for consumer storage instructions
          if (storageStatement && storageStatement != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": FOOD_LANG["storage-instructions"],
                "content": storageStatement,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
      }
      //return list if listItems are present (else function returns undefined)
      if (listItems && listItems.length > 0) {
        result = {
          "ctrlName": "list",
          "title": FOOD_LANG["product-instructions"],
          "config": {
            "class": "smartlabel-list m-l-15 m-r-15",
          },
          "controls": listItems
        };
      }
      //console.log("result", result);
      // no list control if listItems are not present
      return result;
    }
  }

  /**
   * get health and safety food list 
   * @author Venkatesh Pasala
   * @task QLIKTAG-2901
   */
  getProductUsageAndSafetyFoodPageList() {
    let list;
    let listItems: Array<any> = [];
    let productUsageAndSafetyPath = "data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety";
    let path = productUsageAndSafetyPath.split(".");
    let productUsageAndSafety = this.appService.findObjectByPath(this.smartLabelData, path);
    let warningPrecautionStatements = [];
    let advisoryStatements = [];
    let safeHandlingStatements = [];
    let disclaimerStatements = [];
    let dateMarkStatements = [];
    let warningStatement = '';
    let precautionStatement = '';
    let safeHandlingStatement = '';
    let disclaimerStatement = '';
    let dateMarkStatement = '';
    const currentLang = this.pageService.appLang$.getValue();
    if (productUsageAndSafety) {
      if (productUsageAndSafety.hasOwnProperty("dateMarkStatement") && productUsageAndSafety.dateMarkStatement) {
        dateMarkStatements = [...dateMarkStatements, ...productUsageAndSafety.dateMarkStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (dateMarkStatements.length > 0) {
          dateMarkStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              dateMarkStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (dateMarkStatement && dateMarkStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["use-by"],
              "content": dateMarkStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("warningPrecautionStatement") && productUsageAndSafety.warningPrecautionStatement) {
        warningPrecautionStatements = [...warningPrecautionStatements, ...productUsageAndSafety.warningPrecautionStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (warningPrecautionStatements.length > 0) {
          warningPrecautionStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              const text: string = this.linkyPipe.transform(item.value, { className: 'smartlabel-link' });
              warningStatement += `<p>${text}</p>`;
            }
          });
        }
        if (warningStatement && warningStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["precautions-warnings"],
              "content": warningStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("advisoryStatement") && productUsageAndSafety.advisoryStatement) {
        advisoryStatements = [...advisoryStatements, ...productUsageAndSafety.advisoryStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (advisoryStatements.length > 0) {
          advisoryStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              precautionStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (precautionStatement && precautionStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["advisory-statement"],
              "content": precautionStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("safeHandlingStatement") && productUsageAndSafety.safeHandlingStatement) {
        safeHandlingStatements = [...safeHandlingStatements, ...productUsageAndSafety.safeHandlingStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (safeHandlingStatements.length > 0) {
          safeHandlingStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              safeHandlingStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (safeHandlingStatement && safeHandlingStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["safe-handling"],
              "content": safeHandlingStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("disclaimerStatement") && productUsageAndSafety.disclaimerStatement) {
        disclaimerStatements = [...disclaimerStatements, ...productUsageAndSafety.disclaimerStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (disclaimerStatements.length > 0) {
          disclaimerStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              disclaimerStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (disclaimerStatement && disclaimerStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["disclaimer-statement"],
              "content": disclaimerStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("itemMinimumDurability") && productUsageAndSafety.itemMinimumDurability) {
        if (productUsageAndSafety.itemMinimumDurability.hasOwnProperty("value") && productUsageAndSafety.itemMinimumDurability.value) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["best-if-used-by"],
              "content": `${productUsageAndSafety.itemMinimumDurability.value}
          {{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.itemMinimumDurability.timeMeasurementUnitCode | mapTo: listItemName}}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }

      }
      if (productUsageAndSafety.hasOwnProperty("itemPeriodSafeToUseAfterOpening") && productUsageAndSafety.itemPeriodSafeToUseAfterOpening) {
        if (productUsageAndSafety.itemPeriodSafeToUseAfterOpening.hasOwnProperty("value") && productUsageAndSafety.itemPeriodSafeToUseAfterOpening.value) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["USE-By"],
              "content": `${productUsageAndSafety.itemPeriodSafeToUseAfterOpening.value}
              {{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.itemPeriodSafeToUseAfterOpening.timeMeasurementUnitCode | mapTo: listItemName}}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }

      }
      if (productUsageAndSafety.hasOwnProperty("optimumPreparationTemperatureRange") && productUsageAndSafety.optimumPreparationTemperatureRange) {
        let minPreTemp, maxPreTemp, minTemp, maxTemp;
        if (productUsageAndSafety.optimumPreparationTemperatureRange.hasOwnProperty("minimumTemperature") && productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature) {
          if (productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.value) {
            minTemp = `${productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            minPreTemp = `<p class="f-w-400">Minimum Temperature: ${minTemp}</p>`;
          }
        }
        if (productUsageAndSafety.optimumPreparationTemperatureRange.hasOwnProperty("maximumTemperature") && productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature) {
          if (productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.value) {
            maxTemp = `${productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            maxPreTemp = `<p class="f-w-400">Maximum Temperature: ${maxTemp}</p>`;
          }
        }
        if (minPreTemp || maxPreTemp) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["preparation-temperature"],
              "content": `${minPreTemp ? minPreTemp : ''}
                ${maxPreTemp ? maxPreTemp : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (productUsageAndSafety.hasOwnProperty("optimumStorageTemperatureRange") && productUsageAndSafety.optimumStorageTemperatureRange) {
        let minStorTemp, maxStorTemp, minTemp, maxTemp;
        if (productUsageAndSafety.optimumStorageTemperatureRange.hasOwnProperty("minimumTemperature") && productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature) {
          if (productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.value) {
            minTemp = `${productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            minStorTemp = `<p class="f-w-400">Minimum Temperature: ${minTemp}</p>`;
          }
        }
        if (productUsageAndSafety.optimumStorageTemperatureRange.hasOwnProperty("maximumTemperature") && productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature) {
          if (productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.value) {
            maxTemp = `${productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            maxStorTemp = `<p class="f-w-400">Maximum Temperature: ${maxTemp}</p>`;
          }
        }
        if (minStorTemp || maxStorTemp) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["storage-temperature"],
              "content": `${minStorTemp ? minStorTemp : ''}
                  ${maxStorTemp ? maxStorTemp : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (productUsageAndSafety.hasOwnProperty("optimumConsumptionTemperatureRange") && productUsageAndSafety.optimumConsumptionTemperatureRange) {
        let minConsTemp, maxConsTemp, minTemp, maxTemp;
        if (productUsageAndSafety.optimumConsumptionTemperatureRange.hasOwnProperty("minimumTemperature") && productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature) {
          if (productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.value) {
            minTemp = `${productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            minConsTemp = `<p class="f-w-400">Minimum Temperature: ${minTemp}</p>`;
          }
        }
        if (productUsageAndSafety.optimumConsumptionTemperatureRange.hasOwnProperty("maximumTemperature") && productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature) {
          if (productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.value) {
            maxTemp = `${productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            maxConsTemp = `<p class="f-w-400">Maximum Temperature: ${maxTemp}</p>`;
          }
        }
        if (minConsTemp || maxConsTemp) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["consumption-temperature"],
              "content": `${minConsTemp ? minConsTemp : ''}
                  ${maxConsTemp ? maxConsTemp : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (listItems && listItems.length > 0) {
        list = {
          "ctrlName": "list",
          "title": FOOD_LANG["health-safety"],
          "config": {
            "class": "smartlabel-list m-l-15 m-r-15"
          },
          "controls": listItems
        };
      }
    }
    return list;
  }

  /**
   * get about product food page list
   * @author Venkatesh Pasala 
   * @task QLIKTAG-2901
   */
  getAboutProductFoodPageList() {
    let listItems: Array<any> = [];
    let list;
    let path: Array<string>;
    let accreditationListCodes = ["BE_MARKED", "BE_DERIVED"];
    const basicProductInformationModuleTypePath = "data.modules.BasicProductInformationModuleType";
    let basicProductInformationModule;
    path = basicProductInformationModuleTypePath.split(".");
    basicProductInformationModule = this.appService.findObjectByPath(this.smartLabelData, path);
    let output;
    const currentLang = this.pageService.appLang$.getValue();
    let productClaimspath: Array<string>;
    const foodProductClaimsAndEndorsementsModuleTypeFoodPath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let foodProductClaimsAndEndorsementsModule;
    productClaimspath = foodProductClaimsAndEndorsementsModuleTypeFoodPath.split(".");
    foodProductClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, productClaimspath);
    if (foodProductClaimsAndEndorsementsModule) {
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty('accreditationInformation') && foodProductClaimsAndEndorsementsModule['accreditationInformation'].length > 0) {
        foodProductClaimsAndEndorsementsModule['accreditationInformation'].forEach((element, index) => {
          if (element.accreditationCode && accreditationListCodes.includes(element.accreditationCode)) {
            let icon = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:cloudinaryurl | mapTo:fieldValue}}`);
            let codeText = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: listItemName}}`);
            let accreditationFoodText = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`);
            accreditationFoodText = this.linkyPipe.transform(accreditationFoodText, { className: 'smartlabel-link' });
            let accreditationFoodStatement = '';
            if (icon && icon != '') {
              accreditationFoodStatement += `<img src="${icon}" alt="${element['accreditationCode']}" class="smartlabel-certification-logo"/>`
            }
            if (codeText && codeText != '') {
              accreditationFoodStatement += `<h2 class="f-w-400">${codeText}</p></h2>`
            }
            if (accreditationFoodText && accreditationFoodText != '') {
              accreditationFoodStatement += `<p class="c-black">${accreditationFoodText}</p></h2>`
            }
            if (accreditationFoodStatement && accreditationFoodStatement != '') {
              //console.log("accreditationfoodstmt", accreditationFoodStatement)
              listItems.push({
                "ctrlName": "list-item",
                "config": {
                  "content": accreditationFoodStatement,
                  "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              });
            }
          }
        });
      }
    }
    if (basicProductInformationModule && basicProductInformationModule.hasOwnProperty("consumerMarketingDescription") && basicProductInformationModule.consumerMarketingDescription && basicProductInformationModule.consumerMarketingDescription.length > 0) {
      output = basicProductInformationModule.consumerMarketingDescription.filter(
        (item) => item.hasOwnProperty('languageCode') && item['languageCode'].toLowerCase() === this.pageService.appLang$.getValue()
      );
      if (output && output.length > 0) {
        output.forEach(element => {
          if (element.hasOwnProperty("value") && element.value) {
            const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
            let statement = `<p>${text}</p>`;
            //console.log("statement", statement)
            listItems.push(
              {
                "ctrlName": "list-item",
                "config": {
                  "label": "",
                  "content": statement,
                  "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              });
          }
        });
      }
    }
    if (foodProductClaimsAndEndorsementsModule) {
      let nutritionalArray = '', foodNutritionalClaimStatement = [], nutrClaimText, nutrClaimStatement;
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("nutritionalClaimCode") && foodProductClaimsAndEndorsementsModule.nutritionalClaimCode) {
        if (foodProductClaimsAndEndorsementsModule.nutritionalClaimCode.length > 0) {
          foodProductClaimsAndEndorsementsModule.nutritionalClaimCode.forEach((element, index) => {
            let icon = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.nutritionalClaimCode.${index} | mapTo: additionalInformation | find:fieldCode:logo | mapTo: fieldValue}}`);
            let text = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.nutritionalClaimCode.${index} | mapTo: listItemName}}`);
            if (text && text != '') {
              let foodNutritionalClaimCodes = '';
              if (icon && icon != '') {
                foodNutritionalClaimCodes += `<img src="${icon}" alt="${element}" class="smartlabel-certification-logo"/>`
              }
              if (text && text != '') {
                foodNutritionalClaimCodes += `<h2 class="f-w-400">${text}</h2>`
              }
              if (foodNutritionalClaimCodes && foodNutritionalClaimCodes != '' && foodNutritionalClaimCodes != undefined) {
                nutritionalArray += foodNutritionalClaimCodes;
              }
            }
          });
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["nutritional-claims"],
              "content": `${nutritionalArray ? nutritionalArray : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("nutritionalClaimStatement") && foodProductClaimsAndEndorsementsModule.nutritionalClaimStatement) {
        foodNutritionalClaimStatement = foodProductClaimsAndEndorsementsModule.nutritionalClaimStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
        if (foodNutritionalClaimStatement && foodNutritionalClaimStatement.length > 0) {
          foodNutritionalClaimStatement.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              nutrClaimText = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              nutrClaimStatement = `<p>${nutrClaimText}</p>`;
            }
          });
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["nutritional-claim-statement"],
              "content": `${nutrClaimStatement ? nutrClaimStatement : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("dietaryClaimCode") && foodProductClaimsAndEndorsementsModule.dietaryClaimCode) {
        if (foodProductClaimsAndEndorsementsModule.dietaryClaimCode.length > 0) {
          foodProductClaimsAndEndorsementsModule.dietaryClaimCode.forEach((element, index) => {
            let icon = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.dietaryClaimCode.${index} | mapTo:additionalInformation | find:fieldCode:logo | mapTo:fieldValue }}`);
            const text = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.dietaryClaimCode.${index} | mapTo:listItemName}}`);
            if (text && text != '') {
              let dietaryStatement = '';
              if (icon && icon != '') {
                dietaryStatement += `<img src="${icon}" alt="${element}" class="smartlabel-certification-logo" loading="lazy"/>`
              }
              if (text && text != '') {
                dietaryStatement += `<h2 class="f-w-400">${text}</h2>`;
              }
              if (dietaryStatement && dietaryStatement != '') {
                //console.log("dietary claims", dietaryStatement)
                listItems.push({
                  "ctrlName": "list-item",
                  "config": {
                    "label": FOOD_LANG["dietary-claims"],
                    "content": dietaryStatement,
                    "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                  }
                });
              }
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("healthClaimDescription") && foodProductClaimsAndEndorsementsModule.healthClaimDescription) {
        let healthClaimStatements = [];
        healthClaimStatements = foodProductClaimsAndEndorsementsModule.healthClaimDescription.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
        if (healthClaimStatements.length > 0) {
          healthClaimStatements.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              let healthClaimStatement = `<p>${element.value}</p>`;
              // console.log("healthclaim", healthClaimStatement)
              listItems.push(
                {
                  "ctrlName": "list-item",
                  "config": {
                    "label": FOOD_LANG["health-claims"],
                    "content": healthClaimStatement,
                    "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                  }
                });
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("regulatoryCompliance") && foodProductClaimsAndEndorsementsModule.regulatoryCompliance) {
        let regComplStatement, regComplText, isPackagingMarked;
        if (foodProductClaimsAndEndorsementsModule.regulatoryCompliance.length > 0) {
          foodProductClaimsAndEndorsementsModule.regulatoryCompliance.forEach((element, index) => {
            if (element.hasOwnProperty("regulatoryComplianceCode") && element.regulatoryComplianceCode) {
              regComplText = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.regulatoryCompliance.${index}.regulatoryComplianceCode | mapTo: listItemName}}`);
              if (regComplText) {
                regComplStatement = `<p class="f-w-400">Regulatory Compliance Code: ${regComplText}</p>`;
              }
            }
            if (element.hasOwnProperty("isPackagingMarkedWithRegulatoryCompliance") && element.isPackagingMarkedWithRegulatoryCompliance) {
              isPackagingMarked = `<p class="f-w-400">Packaging Marked With Regulatory Compliance: ${element.isPackagingMarkedWithRegulatoryCompliance}</p>`;
            }
          });
        }
        if (regComplStatement || isPackagingMarked) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": FOOD_LANG["regulatory-claims"],
              "content": `${regComplStatement ? regComplStatement : ''}
                  ${isPackagingMarked ? isPackagingMarked : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("otherClaimStatement") && foodProductClaimsAndEndorsementsModule.otherClaimStatement) {
        if (foodProductClaimsAndEndorsementsModule.otherClaimStatement.length > 0) {
          let otherClaimStatements = [];
          otherClaimStatements = foodProductClaimsAndEndorsementsModule.otherClaimStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
          if (otherClaimStatements.length > 0) {
            otherClaimStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let otherClaimStatement = `<p>${element.value}</p>`;
                listItems.push(
                  {
                    "ctrlName": "list-item",
                    "config": {
                      "label": FOOD_LANG["other-claims"],
                      "content": otherClaimStatement,
                      "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                    }
                  });
              }
            });
          }
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("warningStatement") && foodProductClaimsAndEndorsementsModule.warningStatement) {
        if (foodProductClaimsAndEndorsementsModule.warningStatement.length > 0) {
          let warningStatements = [];
          warningStatements = foodProductClaimsAndEndorsementsModule.warningStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
          if (warningStatements.length > 0) {
            warningStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let warningStatement = `<p>${element.value}</p>`;
                listItems.push(
                  {
                    "ctrlName": "list-item",
                    "config": {
                      "label": FOOD_LANG["warning"],
                      "content": warningStatement,
                      "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                    }
                  });
              }
            });
          }
        }
      }

    }
    let countryOfOriginListItem = this.getCountryOfOriginListItem();
    if (countryOfOriginListItem) {
      listItems.push(countryOfOriginListItem);
    }
    if (listItems && listItems.length > 0) {
      list = {
        "ctrlName": "list",
        "title": FOOD_LANG["about-this-product"],
        "config": {
          "class": "smartlabel-list m-l-15 m-r-15"
        },
        "controls": listItems
      };
    }
    return list;
  }

  /**
   * Get certification food page layout
   * @author Venkatesh Pasala
   * @task QLIKTAG-2901
   */
  getCertificationsFoodPageLayout() {
    let certificationsList = this.getCertificationsFoodPageList();

    return {
      "alias": "certifications-item",
      "title": "Certifications",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3),
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),
                "title": this.getTabTitle(0),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),
                "title": this.getTabTitle(1),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2),
                "title": this.getTabTitle(2),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),
                "title": this.getTabTitle(3),
                "icon": this.getTabIcon(3),
                "show": true
              },
              "controls": [
                ...(certificationsList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": FOOD_LANG["certifications"],
                    "headingType": 1
                  }
                },
                {
                  "ctrlName": "jumbotron",
                  "config": {
                    "class": "smartlabel-jumbotron m-l-15 m-r-15",
                    "content": FOOD_LANG["feature-and-benefits-certification-info"]
                  }
                },
                  certificationsList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),
                "title": this.getTabTitle(4),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4),
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
  }

  /***
   * Get certification food list 
   * @author Venkatesh Pasala
   * @task QLIKTAG-2901
   */
  getCertificationsFoodPageList() {
    const productClaimsAndEndorsementsModuleTypeFoodPath = 'data.modules.ProductClaimsAndEndorsementsModuleType';
    let certificationsFoodListPath: Array<string> = productClaimsAndEndorsementsModuleTypeFoodPath.split('.');
    let certificationsFoodList = this.appService.findObjectByPath(this.smartLabelData, certificationsFoodListPath);
    let accreditationList, item, accreditationText, accreditationFoodStatement, description;
    let accreditationListCodes = ["BE_MARKED", "BE_DERIVED"];
    accreditationList = {
      "ctrlName": "list",
      "title": "Certification",
      "config": {
        "class": "smartlabel-list m-l-15 m-r-15",
      },
      "controls": []
    }
    if (certificationsFoodList) {
      if (certificationsFoodList.hasOwnProperty('accreditationInformation') && certificationsFoodList.accreditationInformation && certificationsFoodList['accreditationInformation'].length > 0) {
        certificationsFoodList['accreditationInformation'].forEach((element, index) => {
          if (element.accreditationCode && !accreditationListCodes.includes(element.accreditationCode)) {
            let icon = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:cloudinaryurl | mapTo:fieldValue}}`);
            let codeText = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: listItemName}}`);
            description = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`);
            description = this.linkyPipe.transform(description, { className: 'smartlabel-link' });


            accreditationText = ''; //reset variable
            if (element.hasOwnProperty('accreditationText') && Array.isArray(element.accreditationText)) {
              accreditationText = this.multiLangPipe.transform(element.accreditationText).toString();
              accreditationText = this.linkyPipe.transform(accreditationText, { className: 'smartlabel-link' });
            }

            accreditationFoodStatement = '';
            if (icon && icon != '') {
              accreditationFoodStatement += `<img src="${icon}" alt="${element['accreditationCode']}" class="smartlabel-certification-logo" loading="lazy"/>`
            }
            if (codeText && codeText != '') {
              accreditationFoodStatement += `<h2 class="f-w-400">${codeText}</h2>`
            }
            if (description && description != '') {
              accreditationFoodStatement += `<p class="c-black">${description}</p>`
            }
            if (accreditationText && accreditationText != '') {
              accreditationFoodStatement += `<p class="c-black">${accreditationText}</p>`
            }
            if (accreditationFoodStatement && accreditationFoodStatement != '') {
              item = {
                "ctrlName": "list-item",
                "config": {
                  "content": accreditationFoodStatement,
                  "class": " p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              }
              accreditationList.controls.push(item);
            }
          }
        });
      }
    }
    if (accreditationList.controls.length > 0) {
      return accreditationList;
    } else {
      return false;
    }
  }

  /** get detail page config for food ingredient item
   * @author Radhika Agnihotri
   * @task QLIKTAG-2899
   */
  getFoodIngredientDetailPageLayout() {
    const ingredientPath: string = 'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%';

    let pathMap;

    pathMap = {
      "key": ingredientPath
    }
    pathMap = this.appService.resolveIndex(pathMap);

    let path: Array<string> = pathMap.key.split('.');
    let ingredient = this.appService.findObjectByPath(this.smartLabelData, path);
    let jumbotronConfig;
    let ingredientPurposeConfig;
    let ingredientProviderStatement;
    if (ingredient && ingredient.hasOwnProperty("ingredientName")) {
      let ingredientName = this.multiLangPipe.transform(ingredient.ingredientName);
      //check if multilang pipe returns a value
      if (ingredientName) {

        let ingredientDefinition = "";

        ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinition') ? ingredient.ingredientDefinition : "";

        if (!ingredientDefinition) {

          ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinitionTypeCodeFood') ? this.shortCodePipe.transform(`{{SL.${pathMap.key}.ingredientDefinitionTypeCodeFood | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`) : "";

          ingredientDefinition ? ingredientDefinition : "";

        }

        jumbotronConfig = {
          "ctrlName": "jumbotron",
          "config": {
            "title": ingredientName,
            "content": ingredientDefinition,
            "class": "smartlabel-jumbotron m-l-15 m-r-15"
          }
        };
        // tab.push(jumbotronConfig);
      }
    }
    if (ingredient && ingredient.hasOwnProperty('ingredientPurpose')) {
      let purpose = this.multiLangPipe.transform(ingredient.ingredientPurpose);

      if (purpose) {
        ingredientPurposeConfig = {
          "ctrlName": "list",
          "config": {
            "class": "smartlabel-list m-l-15 m-r-15"
          },
          "controls": [
            {
              "ctrlName": "list-item",
              "config": {
                "label": 'Purpose',
                "content": purpose,
                "class": " p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
              }
            }]
        }
      }

    }

    if (this.smartLabelConfig.hasOwnProperty('ingredientProviderStatement')) {
      ingredientProviderStatement = {
        "ctrlName": "rich-text",
        "config": {
          "class": "smartlabel-rich-text p-16 p-b-0",
          "content": this.smartLabelConfig.ingredientProviderStatement
        }
      };
    }
    let tabs = {
      "ctrlName": "tabs",
      "config": {
        "class": 'smartlabel-tabs',
        "value": this.getTabName(1),
        "layout": 'icon-top',
        "scrollable": false,
        "tabs": 5,
        "sticky": true,
      },
      "controls": [
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
            "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
            "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
            "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
            "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": [
            ...(jumbotronConfig ? [jumbotronConfig] : []),
            ...(ingredientPurposeConfig ? [ingredientPurposeConfig] : []),
            ...(ingredientProviderStatement ? [ingredientProviderStatement] : [])
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
            "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
            "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(3),  // feature-and-benefits
            "title": this.getTabTitle(3), // Features & Benefits
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
            "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
            "show": true
          },
          "controls": [
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(4),   // company-brand-and-sustainability,
            "title": this.getTabTitle(4),  // Company, Brand & Sustainability
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
            "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
            "show": true
          },
          "controls": []
        }
      ]
    }
    let pageLayout = {
      "alias": "ingredient-item-food",
      "title": "Ingredients",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        ...[tabs],
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
    //console.log(pageLayout);
    return pageLayout;

  }



  getFoodSubIngredientDetailPageLayout() {
    let listIndex = this.pageService.listIndex;
    let jumbotronConfig;
    let ingredientPurposeConfig;
    let ingredientProviderStatement;

    if (listIndex) {
      const level: number = listIndex.split("_").length - 1;
      let levels = [
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%.subIngredient.%index2%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%.subIngredient.%index2%.subIngredient.%index3%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%.subIngredient.%index2%.subIngredient.%index3%.subIngredient.%index4%'
      ]
      let pathMap;
      pathMap = {
        "key": levels[level]
      }
      //resolve index
      pathMap = this.appService.resolveIndex(pathMap, listIndex);

      let path: Array<string> = pathMap.key.split('.');
      let ingredient = this.appService.findObjectByPath(this.smartLabelData, path);

      console.log("ingredient", ingredient)
      if (ingredient && ingredient.hasOwnProperty("ingredientName") && ingredient.ingredientName) {
        let ingredientName = this.multiLangPipe.transform(ingredient.ingredientName);
        //check if multilang pipe returns a value
        if (ingredientName) {

          let ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinition') ? ingredient.ingredientDefinition : "";

          if (!ingredientDefinition) {

            ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinitionTypeCodeFood') ? this.shortCodePipe.transform(`{{SL.${pathMap.key}.ingredientDefinitionTypeCodeFood  | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`) : "";

            ingredientDefinition ? ingredientDefinition : "";

          }

          jumbotronConfig = {
            "ctrlName": "jumbotron",
            "config": {
              "title": ingredientName,
              "content": ingredientDefinition,
              "class": "smartlabel-jumbotron m-l-15 m-r-15"
            }
          };
          // tab.push(jumbotronConfig);
        }
      }
      if (ingredient && ingredient.hasOwnProperty('ingredientPurpose') && ingredient.ingredientPurpose) {
        let purpose = this.multiLangPipe.transform(ingredient.ingredientPurpose);

        if (purpose) {
          ingredientPurposeConfig = {
            "ctrlName": "list",
            "config": {
              "class": "smartlabel-list m-l-15 m-r-15"
            },
            "controls": [
              {
                "ctrlName": "list-item",
                "config": {
                  "label": 'Purpose',
                  "content": purpose,
                  "class": " p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              }]
          }
        }

      }

      if (this.smartLabelConfig.hasOwnProperty('ingredientProviderStatement') && this.smartLabelConfig.ingredientProviderStatement) {
        ingredientProviderStatement = {
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16 p-b-0  m-l-15 m-r-15",
            "content": this.smartLabelConfig.ingredientProviderStatement
          }
        };
      }
    }
    let tabs = {
      "ctrlName": "tabs",
      "config": {
        "class": 'smartlabel-tabs',
        "value": this.getTabName(1),
        "layout": 'icon-top',
        "scrollable": false,
        "tabs": 5,
        "sticky": true,
      },
      "controls": [
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
            "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
            "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
            "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
            "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": [
            ...(jumbotronConfig ? [jumbotronConfig] : []),
            ...(ingredientPurposeConfig ? [ingredientPurposeConfig] : []),
            ...(ingredientProviderStatement ? [ingredientProviderStatement] : [])

          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
            "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
            "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(3),  // feature-and-benefits
            "title": this.getTabTitle(3), // Features & Benefits
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
            "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
            "show": true
          },
          "controls": [
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(4),   // company-brand-and-sustainability,
            "title": this.getTabTitle(4),  // Company, Brand & Sustainability
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
            "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
            "show": true
          },
          "controls": []
        }
      ]
    }
    let pageLayout = {
      "alias": "sub-ingredient-item-food",
      "title": "test",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        ...[tabs],
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
    return pageLayout;
  }

  /*
   * get badge items displayed above tabs page
   * @author Radhika Agnihotri
   * @task QLIKTAG-2889
   */
  getBadgeConfig() {
    //console.log("codes", codes)
    let codes = ['BE_MARKED', 'BE_DERIVED']
    let result;
    let items = [];
    const link = `p/${this.pageService.pageAlias}?detail=about-this-product`;
    let icon = '';
    let altText = '';
    const accreditationInformationPath = 'data.modules.ProductClaimsAndEndorsementsModuleType.accreditationInformation';
    let path: Array<string> = accreditationInformationPath.split('.');
    let accreditationInformation = this.appService.findObjectByPath(this.smartLabelData, path);
    if (accreditationInformation) {

      for (let code of codes) {
        let index = accreditationInformation.findIndex(element => element.accreditationCode === code);
        if (index != -1) {

          icon = this.shortCodePipe.transform(`{{SL.${accreditationInformationPath}.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:cloudinaryurl | mapTo:fieldValue}}`);
          altText = accreditationInformation[index].accreditationCode;

          if (icon) {
            items.push({
              "icon": icon,
              "title": altText,
              "link": link
            });
          }
        }
      }

    }
    if (items.length > 0) {
      result = {
        "ctrlName": "badge-segment",
        "config": {
          "scroll": true,
          "class": "smartlabel-acc-badges",
          "items": items,
        }
      }
    }
    return result;
  }

  /**
   * get about product supplement page layout
   * @author Venkatesh Pasala 
   * @task QLIKTAG-3654
   */
  getAboutProductSupPageLayout() {
    let aboutProductSupPageList = this.getAboutProductSupPageList();
    let attributeKey = 'product.targetMarket';
    if (this.isProductEntity) {
      attributeKey = 'targetMarket';
    }

    return {
      "alias": "sup-about-this-product",
      "title": "About This Product",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0), // ingredients-and-contents(NON-FOOD) || drug-facts(OTC-DRUG) || nutrition(FOOD)
                "title": this.getTabTitle(0), // Ingredients & contents (NON-FOOD) || Drug Facts(OTC-DRUG) || Nutrition(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0), //  ['fas', 'list'] (NON-FOOD) || ['fas', 'search'] (OTC-DRUG) || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),// health-safety-and-environment(NON-FOOD, OTC-DRUG) || ingredients(FOOD)
                "title": this.getTabTitle(1),  // Health, Safety & Environment(NON-FOOD, OTC-DRUG) || Ingredients(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1), // ['fas', 'exclamation-triangle'] (NON-FOOD, OTC-DRUG) || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling(NON-FOOD, OTC-DRUG) || allergens(FOOD)
                "title": this.getTabTitle(2),  // Usage & Handling(NON-FOOD, OTC-DRUG) || Allergens(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),//['far', 'hand-point-up'](NON-FOOD, OTC-DRUG) || ['fas', 'exclamation-triangle'](FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3), // feature-and-benefits(NON-FOOD, OTC-DRUG, FOOD)  
                "title": this.getTabTitle(3), // Features & Benefits(NON-FOOD, OTC-DRUG, FOOD) 
                "icon": this.getTabIcon(3), // ['fas', 'info-circle'](NON-FOOD, OTC-DRUG, FOOD) 
                "show": true
              },
              "controls": [
                ...(aboutProductSupPageList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": SUP_LANG["feature-and-benefits"],
                    "headingType": 1
                  }
                },
                {
                  "ctrlName": "conditional",
                  "config": {
                    "source": "entityInformation",
                    "action": "show",
                    "operator": "matches",
                    "mapping": {
                      "attributeKey": attributeKey
                    },
                    "attributeValue": "840"
                  },
                  "controls": [
                    {
                      "ctrlName": "jumbotron",
                      "config": {
                        "class": "smartlabel-jumbotron m-l-15 m-r-15",
                        "content": SUP_LANG["feature-and-benefits-about-info"]
                      },
                    }
                  ]
                },
                {
                  "ctrlName": "conditional",
                  "config": {
                    "source": "entityInformation",
                    "action": "show",
                    "operator": "matches",
                    "mapping": {
                      "attributeKey": attributeKey
                    },
                    "attributeValue": "124"
                  },
                  "controls": [
                    {
                      "ctrlName": "jumbotron",
                      "config": {
                        "class": "smartlabel-jumbotron m-l-15 m-r-15",
                        "content": SUP_LANG["ca-feature-and-benefits-about-info"]
                      }
                    }
                  ]
                },
                  aboutProductSupPageList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4), // company-brand-and-sustainability(NON-FOOD, OTC-DRUG, FOOD)
                "title": this.getTabTitle(4),// Company, Brand & Sustainability(NON-FOOD, OTC-DRUG, FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4), // ['fas', 'external-link-square-alt'] (NON-FOOD, OTC-DRUG, FOOD)
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    }
  }

  /**
  * get about product supplement page list
  * @author Venkatesh Pasala 
  * @task QLIKTAG-3654
  */
  getAboutProductSupPageList() {
    let listItems: Array<any> = [];
    let list;
    let path: Array<string>;
    let accreditationListCodes = ["BE_MARKED", "BE_DERIVED"];
    const basicProductInformationModuleTypePath = "data.modules.BasicProductInformationModuleType";
    let basicProductInformationModule;
    path = basicProductInformationModuleTypePath.split(".");
    basicProductInformationModule = this.appService.findObjectByPath(this.smartLabelData, path);
    let output;
    const currentLang = this.pageService.appLang$.getValue();
    let productClaimspath: Array<string>;
    const foodProductClaimsAndEndorsementsModuleTypeFoodPath = "data.modules.ProductClaimsAndEndorsementsModuleType";
    let foodProductClaimsAndEndorsementsModule;
    productClaimspath = foodProductClaimsAndEndorsementsModuleTypeFoodPath.split(".");
    foodProductClaimsAndEndorsementsModule = this.appService.findObjectByPath(this.smartLabelData, productClaimspath);
    if (foodProductClaimsAndEndorsementsModule) {
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty('accreditationInformation') && foodProductClaimsAndEndorsementsModule['accreditationInformation'].length > 0) {
        foodProductClaimsAndEndorsementsModule['accreditationInformation'].forEach((element, index) => {
          if (element.accreditationCode && accreditationListCodes.includes(element.accreditationCode)) {
            let icon = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:cloudinaryurl | mapTo:fieldValue}}`);
            let codeText = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: listItemName}}`);
            let accreditationFoodText = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`);
            accreditationFoodText = this.linkyPipe.transform(accreditationFoodText, { className: 'smartlabel-link' });
            let accreditationFoodStatement = '';
            if (icon) {
              accreditationFoodStatement += `<img src="${icon}" alt="${element['accreditationCode']}" class="smartlabel-certification-logo" loading="lazy"/>`
            }
            if (codeText) {
              accreditationFoodStatement += `<h2 class="f-w-400">${codeText}</p></h2>`
            }
            if (accreditationFoodText) {
              accreditationFoodStatement += `<p class="c-black">${accreditationFoodText}</p></h2>`
            }
            if (accreditationFoodStatement) {
              //console.log("accreditationfoodstmt", accreditationFoodStatement)
              listItems.push({
                "ctrlName": "list-item",
                "config": {
                  "content": accreditationFoodStatement,
                  "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              });
            }
          }
        });
      }
    }
    if (basicProductInformationModule && basicProductInformationModule.hasOwnProperty("consumerMarketingDescription") && basicProductInformationModule.consumerMarketingDescription.length > 0) {
      output = basicProductInformationModule.consumerMarketingDescription.filter(
        (item) => item.hasOwnProperty('languageCode') && item['languageCode'].toLowerCase() === this.pageService.appLang$.getValue()
      );
      if (output.length > 0) {
        output.forEach(element => {
          if (element.hasOwnProperty("value") && element.value) {
            const text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
            let statement = `<p>${text}</p>`;
            //console.log("statement", statement)
            listItems.push(
              {
                "ctrlName": "list-item",
                "config": {
                  "label": "",
                  "content": statement,
                  "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              });
          }
        });
      }
    }
    if (foodProductClaimsAndEndorsementsModule) {
      let nutritionalArray = '', foodNutritionalClaimStatement = [], nutrClaimText, nutrClaimStatement;
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("nutritionalClaimCode")) {
        if (foodProductClaimsAndEndorsementsModule.nutritionalClaimCode.length > 0) {
          foodProductClaimsAndEndorsementsModule.nutritionalClaimCode.forEach((element, index) => {
            let icon = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.nutritionalClaimCode.${index} | mapTo: additionalInformation | find:fieldCode:logo | mapTo: fieldValue}}`);
            let text = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.nutritionalClaimCode.${index} | mapTo: listItemName}}`);
            if (text) {
              let foodNutritionalClaimCodes = '';
              if (icon) {
                foodNutritionalClaimCodes += `<img src="${icon}" alt="${element}" class="smartlabel-certification-logo" loading="lazy"/>`
              }
              if (text) {
                foodNutritionalClaimCodes += `<h2 class="f-w-400">${text}</h2>`
              }
              if (foodNutritionalClaimCodes && foodNutritionalClaimCodes != '' && foodNutritionalClaimCodes != undefined) {
                nutritionalArray += foodNutritionalClaimCodes;
              }
            }
          });
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["nutritional-claims"],
              "content": `${nutritionalArray ? nutritionalArray : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("nutritionalClaimStatement")) {
        foodNutritionalClaimStatement = foodProductClaimsAndEndorsementsModule.nutritionalClaimStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
        if (foodNutritionalClaimStatement.length > 0) {
          foodNutritionalClaimStatement.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              nutrClaimText = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
              nutrClaimStatement = `<p>${nutrClaimText}</p>`;
            }
          });
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["nutritional-claim-statement"],
              "content": `${nutrClaimStatement ? nutrClaimStatement : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("dietaryClaimCode")) {
        if (foodProductClaimsAndEndorsementsModule.dietaryClaimCode.length > 0) {
          foodProductClaimsAndEndorsementsModule.dietaryClaimCode.forEach((element, index) => {
            let icon = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.dietaryClaimCode.${index} | mapTo:additionalInformation | find:fieldCode:logo | mapTo:fieldValue }}`);
            const text = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.dietaryClaimCode.${index} | mapTo:listItemName}}`);
            if (text) {
              let dietaryStatement = '';
              if (icon) {
                dietaryStatement += `<img src="${icon}" alt="${element}" class="smartlabel-certification-logo" loading="lazy"/>`
              }
              if (text) {
                dietaryStatement += `<h2 class="f-w-400">${text}</h2>`;
              }
              if (dietaryStatement && dietaryStatement != '') {
                //console.log("dietary claims", dietaryStatement)
                listItems.push({
                  "ctrlName": "list-item",
                  "config": {
                    "label": SUP_LANG["dietary-claims"],
                    "content": dietaryStatement,
                    "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                  }
                });
              }
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("healthClaimDescription")) {
        let healthClaimStatements = [];
        healthClaimStatements = foodProductClaimsAndEndorsementsModule.healthClaimDescription.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
        if (healthClaimStatements.length > 0) {
          healthClaimStatements.forEach(element => {
            if (element.hasOwnProperty("value") && element.value) {
              let healthClaimStatement = `<p>${element.value}</p>`;
              // console.log("healthclaim", healthClaimStatement)
              listItems.push(
                {
                  "ctrlName": "list-item",
                  "config": {
                    "label": SUP_LANG["health-claims"],
                    "content": healthClaimStatement,
                    "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                  }
                });
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("regulatoryCompliance")) {
        let regComplStatement, regComplText, isPackagingMarked;
        if (foodProductClaimsAndEndorsementsModule.regulatoryCompliance.length > 0) {
          foodProductClaimsAndEndorsementsModule.regulatoryCompliance.forEach((element, index) => {
            if (element.hasOwnProperty("regulatoryComplianceCode") && element.regulatoryComplianceCode) {
              regComplText = this.shortCodePipe.transform(`{{SL.${foodProductClaimsAndEndorsementsModuleTypeFoodPath}.regulatoryCompliance.${index}.regulatoryComplianceCode | mapTo: listItemName}}`);
              if (regComplText) {
                regComplStatement = `<p class="f-w-400">Regulatory Compliance Code: ${regComplText}</p>`;
              }
            }
            if (element.hasOwnProperty("isPackagingMarkedWithRegulatoryCompliance") && element.isPackagingMarkedWithRegulatoryCompliance) {
              isPackagingMarked = `<p class="f-w-400">Packaging Marked With Regulatory Compliance: ${element.isPackagingMarkedWithRegulatoryCompliance}</p>`;
            }
          });
        }
        if (regComplStatement || isPackagingMarked) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["regulatory-claims"],
              "content": `${regComplStatement ? regComplStatement : ''}
                  ${isPackagingMarked ? isPackagingMarked : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("otherClaimStatement")) {
        if (foodProductClaimsAndEndorsementsModule.otherClaimStatement.length > 0) {
          let otherClaimStatements = [];
          otherClaimStatements = foodProductClaimsAndEndorsementsModule.otherClaimStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
          if (otherClaimStatements.length > 0) {
            otherClaimStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let otherClaimStatement = `<p>${element.value}</p>`;
                listItems.push(
                  {
                    "ctrlName": "list-item",
                    "config": {
                      "label": SUP_LANG["other-claims"],
                      "content": otherClaimStatement,
                      "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                    }
                  });
              }
            });
          }
        }
      }
      if (foodProductClaimsAndEndorsementsModule.hasOwnProperty("warningStatement")) {
        if (foodProductClaimsAndEndorsementsModule.warningStatement.length > 0) {
          let warningStatements = [];
          warningStatements = foodProductClaimsAndEndorsementsModule.warningStatement.filter((element) => element.hasOwnProperty('languageCode') && element.languageCode.toLowerCase() === currentLang);
          if (warningStatements.length > 0) {
            warningStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let warningStatement = `<p>${element.value}</p>`;
                listItems.push(
                  {
                    "ctrlName": "list-item",
                    "config": {
                      "label": SUP_LANG["warning"],
                      "content": warningStatement,
                      "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                    }
                  });
              }
            });
          }
        }
      }
    }

    let countryOfOriginListItem = this.getCountryOfOriginListItem();
    if (countryOfOriginListItem) {
      listItems.push(countryOfOriginListItem);
    }
    if (listItems.length > 0) {
      list = {
        "ctrlName": "list",
        "title": SUP_LANG["about-this-product"],
        "config": {
          "class": "smartlabel-list m-l-15 m-r-15"
        },
        "controls": listItems
      };
    }
    return list;
  }

  /**
   * Get certification supplement page layout
   * @author Venkatesh Pasala
   * @task QLIKTAG-3654
   */
  getCertificationsSupPageLayout() {
    let certificationsList = this.getCertificationsSupPageList();
    return {
      "alias": "sup-certifications-item",
      "title": "Certifications",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3),
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),
                "title": this.getTabTitle(0),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`,
                "icon": this.getTabIcon(0),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),
                "title": this.getTabTitle(1),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(1)}`,
                "icon": this.getTabIcon(1),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2),
                "title": this.getTabTitle(2),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`,
                "icon": this.getTabIcon(2),
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),
                "title": this.getTabTitle(3),
                "icon": this.getTabIcon(3),
                "show": true
              },
              "controls": [
                ...(certificationsList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": SUP_LANG["certifications"],
                    "headingType": 1
                  }
                },
                {
                  "ctrlName": "jumbotron",
                  "config": {
                    "class": "smartlabel-jumbotron m-l-15 m-r-15",
                    "content": SUP_LANG["feature-and-benefits-certification-info"]
                  }
                },
                  certificationsList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),
                "title": this.getTabTitle(4),
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`,
                "icon": this.getTabIcon(4),
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
  }

  /***
   * Get certification supplement list 
   * @author Venkatesh Pasala
   * @task QLIKTAG-3654
   */
  getCertificationsSupPageList() {
    const productClaimsAndEndorsementsModuleTypeFoodPath = 'data.modules.ProductClaimsAndEndorsementsModuleType';
    let certificationsFoodListPath: Array<string> = productClaimsAndEndorsementsModuleTypeFoodPath.split('.');
    let certificationsFoodList = this.appService.findObjectByPath(this.smartLabelData, certificationsFoodListPath);
    let accreditationList, item, accreditationText, accreditationFoodStatement, description;
    let accreditationListCodes = ["BE_MARKED", "BE_DERIVED"];
    accreditationList = {
      "ctrlName": "list",
      "title": "Certification",
      "config": {
        "class": "smartlabel-list m-l-15 m-r-15",
      },
      "controls": []
    }
    if (certificationsFoodList) {
      if (certificationsFoodList.hasOwnProperty('accreditationInformation') && certificationsFoodList['accreditationInformation'].length > 0) {
        certificationsFoodList['accreditationInformation'].forEach((element, index) => {
          if (element.accreditationCode && !accreditationListCodes.includes(element.accreditationCode)) {
            let icon = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:cloudinaryurl | mapTo:fieldValue}}`);
            let codeText = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: listItemName}}`);
            description = this.shortCodePipe.transform(`{{SL.${productClaimsAndEndorsementsModuleTypeFoodPath}.accreditationInformation.${index}.accreditationCode | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`);
            description = this.linkyPipe.transform(description, { className: 'smartlabel-link' });
            //append accreditationText to description

            accreditationText = '';
            if (element.hasOwnProperty('accreditationText') && Array.isArray(element.accreditationText)) {
              accreditationText = this.multiLangPipe.transform(element.accreditationText).toString();
              accreditationText = this.linkyPipe.transform(accreditationText, { className: 'smartlabel-link' });

            }
            accreditationFoodStatement = '';
            if (icon) {
              accreditationFoodStatement += `<img src="${icon}" alt="${element['accreditationCode']}" class="smartlabel-certification-logo" loading="lazy"/>`
            }
            if (codeText) {
              accreditationFoodStatement += `<h2 class="f-w-400">${codeText}</h2>`
            }
            if (description && description != '') {
              accreditationFoodStatement += `<p class="c-black">${description}</p>`
            }
            if (accreditationText && accreditationText != '') {
              accreditationFoodStatement += `<p class="c-black">${accreditationText}</p>`
            }
            if (accreditationFoodStatement) {
              item = {
                "ctrlName": "list-item",
                "config": {
                  "content": accreditationFoodStatement,
                  "class": " p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              }
              accreditationList.controls.push(item);
            }
          }
        });
      }
    }
    if (accreditationList.controls.length > 0) {
      return accreditationList;
    } else {
      return false;
    }
  }

  /**
   * get health and safety supplement page layout
   * @author Venkatesh Pasala 
   * @task QLIKTAG-3654
   */
  getHealthAndSafetySupPageLayout() {
    let healthAndSafetyFoodList = this.getProductUsageAndSafetySupPageList();
    return {
      "alias": "sup-health-and-safety",
      "title": "Health & Safety",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
                "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
                "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
                "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
                "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // Features & Benefits
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
                "show": true
              },
              "controls": [
                ...(healthAndSafetyFoodList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": SUP_LANG["health-safety"],
                    "headingType": 1
                  }
                },
                  healthAndSafetyFoodList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),   // company-brand-and-sustainability,
                "title": this.getTabTitle(4),  // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };

  }

  /**
   * get product instructions supplement page layput 
   * @author Venkatesh Pasala 
   * @task QLIKTAG-3654
   */
  getProductInstructionsSupPageLayout() {
    let productInstructionsSupPageList = this.getProductInstructionsSupPageList();
    return {
      "alias": "sup-product-instructions",
      "title": "Product Instructions",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        {
          "ctrlName": "tabs",
          "config": {
            "class": 'smartlabel-tabs',
            "value": this.getTabName(3), // feature-and-benefits
            "layout": 'icon-top',
            "scrollable": false,
            "tabs": 5,
            "sticky": true,
            "gridFixed": true
          },
          "controls": [
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
                "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
                "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
                "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
                "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
                "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
                "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
                "show": true
              },
              "controls": []
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(3),  // feature-and-benefits
                "title": this.getTabTitle(3), // Features & Benefits
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
                "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
                "show": true
              },
              "controls": [
                ...(productInstructionsSupPageList ? [{
                  "ctrlName": "heading",
                  "config": {
                    "class": "smartlabel-heading m-l-15 m-r-15",
                    "text": SUP_LANG["product-instructions"],
                    "headingType": 1
                  }
                },
                  productInstructionsSupPageList] : [])
              ]
            },
            {
              "ctrlName": "tab-item",
              "config": {
                "name": this.getTabName(4),   // company-brand-and-sustainability,
                "title": this.getTabTitle(4),  // Company, Brand & Sustainability
                "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
                "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
                "show": true
              },
              "controls": []
            }
          ]
        },
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
  }

  /**
   * get product instructions supplement page list 
   * @author Venkatesh Pasala 
   * @task QLIKTAG-3654
   */
  getProductInstructionsSupPageList() {
    {
      let result;
      let listItems: Array<any> = [];
      let productInstructionsModuleTypePath = "data.modules.ProductInstructionsModuleType";
      let path: Array<any>;
      let storageStatement = '';
      let usageStatement = '';
      let feedingStatement = '';
      const currentLang = this.pageService.appLang$.getValue();
      path = productInstructionsModuleTypePath.split(".");
      let productInstructionsModuleType = this.appService.findObjectByPath(this.smartLabelData, path);
      let preparationInformationModuleTypePath = "data.modules.FoodAndBeveragePreparationInformationModuleType";
      let preparationPath: Array<any>;
      preparationPath = preparationInformationModuleTypePath.split(".");
      let preparationInformationModuleTyp = this.appService.findObjectByPath(this.smartLabelData, preparationPath);
      let preparationInstructions = '';
      if (preparationInformationModuleTyp) {
        if (preparationInformationModuleTyp.hasOwnProperty("preparationMethod")) {
          preparationInformationModuleTyp.preparationMethod.map((prepInstructions, prepInstructionsIndex) => {
            if (prepInstructions.hasOwnProperty("preparationInstructions") && Array.isArray(prepInstructions.preparationInstructions)) {
              let preparationStatement = '';
              let preparationCode = this.shortCodePipe.transform(`{{SL.${preparationInformationModuleTypePath}.preparationMethod.${prepInstructionsIndex}.preparationTypeCode | mapTo: listItemName}}`);
              let preparationStatements = prepInstructions.preparationInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang);
              if (preparationStatements.length > 0) {
                preparationStatements.forEach(element => {
                  if (element.hasOwnProperty("value") && element.value) {
                    let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                    preparationStatement += `<p>${text}</p>`
                  }
                });
              }
              if (preparationCode && preparationCode != '') {
                preparationInstructions += `<h2 class="f-w-400">${preparationCode}</p></h2>`;
              }
              if (preparationStatement && preparationStatement != '') {
                preparationInstructions += `<p class="c-black">${preparationStatement}</p>`
              }
            }
          });
          //Add listItem for Preparation Instructions 
          if (preparationInstructions && preparationInstructions != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": SUP_LANG["preparation-instructions"],
                "content": preparationInstructions,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
      }
      if (productInstructionsModuleType) {
        if (productInstructionsModuleType.hasOwnProperty("feedingGuildlines")) {
          let feedingStatements = [];
          if (productInstructionsModuleType.feedingGuildlines.length > 0) {
            feedingStatements = productInstructionsModuleType.feedingGuildlines.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
          }
          if (feedingStatements.length > 0) {
            feedingStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                feedingStatement += `<p>${text}</p>`
              }
            });
          }
          //Add listItem for feeding guildlines
          if (feedingStatement && feedingStatement != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": SUP_LANG["feeding-guidelines"],
                "content": feedingStatement,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
        if (productInstructionsModuleType.hasOwnProperty("consumerUsageInstructions")) {
          let usageStatements = [];
          if (productInstructionsModuleType.consumerUsageInstructions.length > 0) {
            usageStatements = productInstructionsModuleType.consumerUsageInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
          }
          if (usageStatements.length > 0) {
            usageStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                usageStatement += `<p>${text}</p>`
              }
            });
          }
          //Add listItem for consumer usage instructions
          if (usageStatement && usageStatement != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": SUP_LANG["usage-instructions"],
                "content": usageStatement,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
        if (productInstructionsModuleType.hasOwnProperty("consumerStorageInstructions")) {
          let storageStatements = [];
          if (productInstructionsModuleType.consumerStorageInstructions.length > 0) {
            storageStatements = productInstructionsModuleType.consumerStorageInstructions.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)
          }
          if (storageStatements.length > 0) {
            storageStatements.forEach(element => {
              if (element.hasOwnProperty("value") && element.value) {
                let text: string = this.linkyPipe.transform(element.value, { className: 'smartlabel-link' });
                storageStatement += `<p>${text}</p>`
              }
            });
          }
          //Add listItem for consumer storage instructions
          if (storageStatement && storageStatement != '') {
            listItems.push({
              "ctrlName": "list-item",
              "config": {
                "label": SUP_LANG["storage-instructions"],
                "content": storageStatement,
                "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-15"
              }
            })
          }
        }
      }
      //return list if listItems are present (else function returns undefined)
      if (listItems && listItems.length > 0) {
        result = {
          "ctrlName": "list",
          "title": SUP_LANG["product-instructions"],
          "config": {
            "class": "smartlabel-list m-l-15 m-r-15",
          },
          "controls": listItems
        };
      }
      //console.log("result", result);
      // no list control if listItems are not present
      return result;
    }
  }

  /**
   * get health and safety supplement list 
   * @author Venkatesh Pasala
   * @task QLIKTAG-3654
   */
  getProductUsageAndSafetySupPageList() {
    let list;
    let listItems: Array<any> = [];
    let productUsageAndSafetyPath = "data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety";
    let path = productUsageAndSafetyPath.split(".");
    let productUsageAndSafety = this.appService.findObjectByPath(this.smartLabelData, path);
    let warningPrecautionStatements = [];
    let advisoryStatements = [];
    let safeHandlingStatements = [];
    let disclaimerStatements = [];
    let dateMarkStatements = [];
    let warningStatement = '';
    let precautionStatement = '';
    let safeHandlingStatement = '';
    let disclaimerStatement = '';
    let dateMarkStatement = '';
    const currentLang = this.pageService.appLang$.getValue();
    if (productUsageAndSafety) {
      if (productUsageAndSafety.hasOwnProperty("dateMarkStatement") && productUsageAndSafety.dateMarkStatement) {
        dateMarkStatements = [...dateMarkStatements, ...productUsageAndSafety.dateMarkStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (dateMarkStatements.length > 0) {
          dateMarkStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              dateMarkStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (dateMarkStatement && dateMarkStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["use-by"],
              "content": dateMarkStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("warningPrecautionStatement") && productUsageAndSafety.warningPrecautionStatement) {
        warningPrecautionStatements = [...warningPrecautionStatements, ...productUsageAndSafety.warningPrecautionStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (warningPrecautionStatements.length > 0) {
          warningPrecautionStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              const text: string = this.linkyPipe.transform(item.value, { className: 'smartlabel-link' });
              warningStatement += `<p>${text}</p>`;
            }
          });
        }
        if (warningStatement && warningStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["precautions-warnings"],
              "content": warningStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("advisoryStatement") && productUsageAndSafety.advisoryStatement) {
        advisoryStatements = [...advisoryStatements, ...productUsageAndSafety.advisoryStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (advisoryStatements.length > 0) {
          advisoryStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              precautionStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (precautionStatement && precautionStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["advisory-statement"],
              "content": precautionStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("safeHandlingStatement") && productUsageAndSafety.safeHandlingStatement) {
        safeHandlingStatements = [...safeHandlingStatements, ...productUsageAndSafety.safeHandlingStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (safeHandlingStatements.length > 0) {
          safeHandlingStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              safeHandlingStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (safeHandlingStatement && safeHandlingStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["safe-handling"],
              "content": safeHandlingStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("disclaimerStatement") && productUsageAndSafety.disclaimerStatement) {
        disclaimerStatements = [...disclaimerStatements, ...productUsageAndSafety.disclaimerStatement.filter((item) => item.hasOwnProperty('languageCode') && item.languageCode.toLowerCase() === currentLang)]
        if (disclaimerStatements.length > 0) {
          disclaimerStatements.forEach((item) => {
            if (item.hasOwnProperty("value") && item.value) {
              disclaimerStatement += `<p>${item.value}</p>`;
            }
          });
        }
        if (disclaimerStatement && disclaimerStatement != '') {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["disclaimer-statement"],
              "content": disclaimerStatement,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          })
        }
      }
      if (productUsageAndSafety.hasOwnProperty("itemMinimumDurability") && productUsageAndSafety.itemMinimumDurability) {
        if (productUsageAndSafety.itemMinimumDurability.hasOwnProperty("value") && productUsageAndSafety.itemMinimumDurability.value) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["best-if-used-by"],
              "content": `${productUsageAndSafety.itemMinimumDurability.value}
          {{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.itemMinimumDurability.timeMeasurementUnitCode | mapTo: listItemName}}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }

      }
      if (productUsageAndSafety.hasOwnProperty("itemPeriodSafeToUseAfterOpening") && productUsageAndSafety.itemPeriodSafeToUseAfterOpening) {
        if (productUsageAndSafety.itemPeriodSafeToUseAfterOpening.hasOwnProperty("value") && productUsageAndSafety.itemPeriodSafeToUseAfterOpening.value) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["USE-By"],
              "content": `${productUsageAndSafety.itemPeriodSafeToUseAfterOpening.value}
              {{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.itemPeriodSafeToUseAfterOpening.timeMeasurementUnitCode | mapTo: listItemName}}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }

      }
      if (productUsageAndSafety.hasOwnProperty("optimumPreparationTemperatureRange") && productUsageAndSafety.optimumPreparationTemperatureRange) {
        let minPreTemp, maxPreTemp, minTemp, maxTemp;
        if (productUsageAndSafety.optimumPreparationTemperatureRange.hasOwnProperty("minimumTemperature") && productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature) {
          if (productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.value) {
            minTemp = `${productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumPreparationTemperatureRange.minimumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            minPreTemp = `<p class="f-w-400">Minimum Temperature: ${minTemp}</p>`;
          }
        }
        if (productUsageAndSafety.optimumPreparationTemperatureRange.hasOwnProperty("maximumTemperature") && productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature) {
          if (productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.value) {
            maxTemp = `${productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumPreparationTemperatureRange.maximumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            maxPreTemp = `<p class="f-w-400">Maximum Temperature: ${maxTemp}</p>`;
          }
        }
        if (minPreTemp || maxPreTemp) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["preparation-temperature"],
              "content": `${minPreTemp ? minPreTemp : ''}
                ${maxPreTemp ? maxPreTemp : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (productUsageAndSafety.hasOwnProperty("optimumStorageTemperatureRange") && productUsageAndSafety.optimumStorageTemperatureRange) {
        let minStorTemp, maxStorTemp, minTemp, maxTemp;
        if (productUsageAndSafety.optimumStorageTemperatureRange.hasOwnProperty("minimumTemperature") && productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature) {
          if (productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.value) {
            minTemp = `${productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumStorageTemperatureRange.minimumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            minStorTemp = `<p class="f-w-400">Minimum Temperature: ${minTemp}</p>`;
          }
        }
        if (productUsageAndSafety.optimumStorageTemperatureRange.hasOwnProperty("maximumTemperature") && productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature) {
          if (productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.value) {
            maxTemp = `${productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumStorageTemperatureRange.maximumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            maxStorTemp = `<p class="f-w-400">Maximum Temperature: ${maxTemp}</p>`;
          }
        }
        if (minStorTemp || maxStorTemp) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["storage-temperature"],
              "content": `${minStorTemp ? minStorTemp : ''}
                  ${maxStorTemp ? maxStorTemp : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (productUsageAndSafety.hasOwnProperty("optimumConsumptionTemperatureRange") && productUsageAndSafety.optimumConsumptionTemperatureRange) {
        let minConsTemp, maxConsTemp, minTemp, maxTemp;
        if (productUsageAndSafety.optimumConsumptionTemperatureRange.hasOwnProperty("minimumTemperature") && productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature) {
          if (productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.value) {
            minTemp = `${productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumConsumptionTemperatureRange.minimumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            minConsTemp = `<p class="f-w-400">Minimum Temperature: ${minTemp}</p>`;
          }
        }
        if (productUsageAndSafety.optimumConsumptionTemperatureRange.hasOwnProperty("maximumTemperature") && productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature) {
          if (productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.hasOwnProperty("value") && productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.value) {
            maxTemp = `${productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.value}` + " " + `{{SL.data.modules.ProductUsageAndSafetyModuleType.productUsageAndSafety.optimumConsumptionTemperatureRange.maximumTemperature.temperatureMeasurementUnitCode | mapTo: listItemName}}`;
            maxConsTemp = `<p class="f-w-400">Maximum Temperature: ${maxTemp}</p>`;
          }
        }
        if (minConsTemp || maxConsTemp) {
          listItems.push({
            "ctrlName": "list-item",
            "config": {
              "label": SUP_LANG["consumption-temperature"],
              "content": `${minConsTemp ? minConsTemp : ''}
                  ${maxConsTemp ? maxConsTemp : ''}`,
              "class": "p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
            }
          });
        }
      }
      if (listItems && listItems.length > 0) {
        list = {
          "ctrlName": "list",
          "title": SUP_LANG["health-safety"],
          "config": {
            "class": "smartlabel-list m-l-15 m-r-15"
          },
          "controls": listItems
        };
      }
    }
    return list;
  }

  /**
   * ingredient-supplement detail page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3722
   */
  getSupIngredientDetailPageLayout() {
    let listIndex = this.pageService.listIndex;

    let jumbotronConfig;
    let ingredientPurposeConfig;
    let ingredientProviderStatement;


    if (listIndex) {
      const level: number = listIndex.split("_").length - 1;
      let levels = [
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%.subIngredient.%index2%'
      ]
      let pathMap;
      pathMap = {
        "key": levels[level]
      }
      //resolve index
      pathMap = this.appService.resolveIndex(pathMap, listIndex);

      let path: Array<string> = pathMap.key.split('.');
      let ingredient = this.appService.findObjectByPath(this.smartLabelData, path);

      if (ingredient && ingredient.hasOwnProperty("ingredientName") && ingredient.ingredientName) {
        let ingredientName = this.multiLangPipe.transform(ingredient.ingredientName);
        //check if multilang pipe returns a value
        if (ingredientName) {

          let ingredientDefinition = ""

          ingredientDefinition = ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinition') ? ingredient.ingredientDefinition : "";

          if (!ingredientDefinition) {

            ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinitionTypeCodeFood') ? this.shortCodePipe.transform(`{{SL.${pathMap.key}.ingredientDefinitionTypeCodeFood | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`) : "";

            ingredientDefinition ? ingredientDefinition : "";

          }

          jumbotronConfig = {
            "ctrlName": "jumbotron",
            "config": {
              "title": ingredientName,
              "content": ingredientDefinition,
              "class": "smartlabel-jumbotron m-l-15 m-r-15"
            }
          };
          // tab.push(jumbotronConfig);
        }
      }
      if (ingredient && ingredient.hasOwnProperty('ingredientPurpose') && ingredient.ingredientPurpose) {
        let purpose = this.multiLangPipe.transform(ingredient.ingredientPurpose);

        if (purpose) {
          ingredientPurposeConfig = {
            "ctrlName": "list",
            "config": {
              "class": "smartlabel-list m-l-15 m-r-15"
            },
            "controls": [
              {
                "ctrlName": "list-item",
                "config": {
                  "label": 'Purpose',
                  "content": purpose,
                  "class": " p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              }]
          }
        }

      }

      if (this.smartLabelConfig.hasOwnProperty('ingredientProviderStatement') && this.smartLabelConfig.ingredientProviderStatement) {
        ingredientProviderStatement = {
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16 p-b-0  m-l-15 m-r-15",
            "content": this.smartLabelConfig.ingredientProviderStatement
          }
        };
      }
    }

    let tabs = {
      "ctrlName": "tabs",
      "config": {
        "class": 'smartlabel-tabs',
        "value": this.getTabName(1),
        "layout": 'icon-top',
        "scrollable": false,
        "tabs": 5,
        "sticky": true,
      },
      "controls": [
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
            "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
            "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
            "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
            "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": [
            ...(jumbotronConfig ? [jumbotronConfig] : []),
            ...(ingredientPurposeConfig ? [ingredientPurposeConfig] : []),
            ...(ingredientProviderStatement ? [ingredientProviderStatement] : [])
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
            "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
            "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(3),  // feature-and-benefits
            "title": this.getTabTitle(3), // Features & Benefits
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
            "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
            "show": true
          },
          "controls": [
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(4),   // company-brand-and-sustainability,
            "title": this.getTabTitle(4),  // Company, Brand & Sustainability
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
            "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
            "show": true
          },
          "controls": []
        }
      ]
    }
    let pageLayout = {
      "alias": "ingredient-supplement",
      "title": "test",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        ...[tabs],
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
    return pageLayout;
  }

  /**
   * SUB-INGREDIENT-SUPPLEMENT detail page
   * @author Radhika Agnihotri
   * @task QLIKTAG-3722
   */
  getSupSubIngredientDetailPageLayout() {
    let listIndex = this.pageService.listIndex;
    let jumbotronConfig;
    let ingredientPurposeConfig;
    let ingredientProviderStatement;

    if (listIndex) {
      const level: number = listIndex.split("_").length - 1;
      let levels = [
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%',
        'data.modules.FoodAndBeverageIngredientInformationModuleType.foodAndBeverageIngredient.%index0%.subIngredient.%index1%.subIngredient.%index2%'
      ]
      let pathMap;
      pathMap = {
        "key": levels[level]
      }
      //resolve index
      pathMap = this.appService.resolveIndex(pathMap, listIndex);

      let path: Array<string> = pathMap.key.split('.');
      let ingredient = this.appService.findObjectByPath(this.smartLabelData, path);

      //console.log("subIngredientItem", subIngredientItem)
      if (ingredient && ingredient.hasOwnProperty("ingredientName") && ingredient.ingredientName) {
        let ingredientName = this.multiLangPipe.transform(ingredient.ingredientName);
        //check if multilang pipe returns a value
        if (ingredientName) {

          let ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinition') ? ingredient.ingredientDefinition : "";

          if (!ingredientDefinition) {

            ingredientDefinition = ingredient.hasOwnProperty('ingredientDefinitionTypeCodeFood') ? this.shortCodePipe.transform(`{{SL.${pathMap.key}.ingredientDefinitionTypeCodeFood  | mapTo: additionalInformation | find:fieldCode:description | mapTo:fieldValue}}`) : "";

            ingredientDefinition ? ingredientDefinition : "";

          }

          jumbotronConfig = {
            "ctrlName": "jumbotron",
            "config": {
              "title": ingredientName,
              "content": ingredientDefinition,
              "class": "smartlabel-jumbotron m-l-15 m-r-15"
            }
          };
          // tab.push(jumbotronConfig);
        }
      }
      if (ingredient && ingredient.hasOwnProperty('ingredientPurpose') && ingredient.ingredientPurpose) {
        let purpose = this.multiLangPipe.transform(ingredient.ingredientPurpose);

        if (purpose) {
          ingredientPurposeConfig = {
            "ctrlName": "list",
            "config": {
              "class": "smartlabel-list m-l-15 m-r-15"
            },
            "controls": [
              {
                "ctrlName": "list-item",
                "config": {
                  "label": 'Purpose',
                  "content": purpose,
                  "class": " p-start-0 p-end-0 inner-p-start-15 inner-p-end-0"
                }
              }]
          }
        }

      }

      if (this.smartLabelConfig.hasOwnProperty('ingredientProviderStatement') && this.smartLabelConfig.ingredientProviderStatement) {
        ingredientProviderStatement = {
          "ctrlName": "rich-text",
          "config": {
            "class": "smartlabel-rich-text p-16 p-b-0  m-l-15 m-r-15",
            "content": this.smartLabelConfig.ingredientProviderStatement
          }
        };
      }
    }
    let tabs = {
      "ctrlName": "tabs",
      "config": {
        "class": 'smartlabel-tabs',
        "value": this.getTabName(1),
        "layout": 'icon-top',
        "scrollable": false,
        "tabs": 5,
        "sticky": true,
      },
      "controls": [
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(0),    // ingredients-and-contents, drug-facts || nutrition(FOOD)
            "title": this.getTabTitle(0),  // Ingredients & Contents, Drug Facts || nutrition(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(0)}`, // ingredients-and-contents , drug-facts
            "icon": this.getTabIcon(0),  // ['fas', 'list'],['fas', 'search'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(1),     // health-safety-and-environment || ingredients(FOOD)
            "title": this.getTabTitle(1),   // Health, Safety & Environment || ingredients(FOOD)
            "icon": this.getTabIcon(1),    // ['fas', 'exclamation-triangle'] || ['fas', 'list'] (FOOD)
            "show": true
          },
          "controls": [
            ...(jumbotronConfig ? [jumbotronConfig] : []),
            ...(ingredientPurposeConfig ? [ingredientPurposeConfig] : []),
            ...(ingredientProviderStatement ? [ingredientProviderStatement] : [])

          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(2), // usage-and-handling || allergens(FOOD)
            "title": this.getTabTitle(2), // Usage & Handling || allergens(FOOD)
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(2)}`, // usage-and-handling
            "icon": this.getTabIcon(2), // ['far', 'hand-point-up'] || ['fas', 'exclamation-triangle'](FOOD)
            "show": true
          },
          "controls": []
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(3),  // feature-and-benefits
            "title": this.getTabTitle(3), // Features & Benefits
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(3)}`, // feature-and-benefits
            "icon": this.getTabIcon(3),   // ['fas', 'info-circle']
            "show": true
          },
          "controls": [
          ]
        },
        {
          "ctrlName": "tab-item",
          "config": {
            "name": this.getTabName(4),   // company-brand-and-sustainability,
            "title": this.getTabTitle(4),  // Company, Brand & Sustainability
            "url": `p/${this.pageService.pageAlias}?resetQueryParams=true#${this.getTabName(4)}`, // company-brand-and-sustainability
            "icon": this.getTabIcon(4),   // ['fas', 'external-link-square-alt']
            "show": true
          },
          "controls": []
        }
      ]
    }
    let pageLayout = {
      "alias": "sub-ingredient-supplement",
      "title": "test",
      "mode": "smartlabel",
      "controls": [
        ...(this.headerConfig ? [this.headerConfig] : []),
        ...(this.productHeaderConfig ? [this.productHeaderConfig] : []),
        ...(this.badgesConfig ? [this.badgesConfig] : []),
        ...[tabs],
        ...(this.footer1Config ? [this.footer1Config] : []),
        ...(this.footer2Config ? [this.footer2Config] : []),
        ...(this.informationAccuracyConfig ? [this.informationAccuracyConfig] : []),
        ...(this.footerLinksConfig ? [this.footerLinksConfig] : []),
        ...(this.socialLinksConfig ? [this.socialLinksConfig] : [])
      ]
    };
    return pageLayout;
  }

  /**
   * get tooltipText for i icon
   * @author Radhika Agnihotri
   * @task QLIKTAG-3889
   */
  getTooltipText() {
    let result: string = '';
    if (this.smartLabelConfig && this.smartLabelConfig.hasOwnProperty('tooltipText') && Array.isArray(this.smartLabelConfig.tooltipText)) {
      result = this.multiLangPipe.transform(this.smartLabelConfig.tooltipText).toString();
    }
    return result;
  }
}
