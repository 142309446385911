import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { get, groupBy } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DI_DATA, DI_LANG, DI_TRANS } from '../mocks/mock';
import { Code2txtPipe } from '../pipes/code2txt.pipe';
import { DiDatePipe } from '../pipes/di-date.pipe';
import { ApiService } from './api.service';
import { PageService } from './page.service';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  public listTranslated: boolean = false; //Flag to be set once list codes are translated to prevent a repeat
  public isSandboxMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading: boolean = false;
  searchText$: Subject<string> = new Subject<string>();

  constructor(
    private code2txt: Code2txtPipe,
    private pageService: PageService,
    private diDatePipe: DiDatePipe,
    private apiService: ApiService,
    private toast: ToastController) {
  }
  /**
   * [resolveIndex description]
   * @param  path [description]
   * @return      [description]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  resolveIndex(mappings: object = {}, listIndex?: string): object {
    //console.log(mappings);
    let result = {};
    //added by @Radhika on Aug 23 2019 
    //@task QLIKTAG-3197
    //use parameter value of listIndex to resolve index
    const lid = listIndex ? listIndex : this.pageService.listIndex;
    if (!lid) {
      return mappings;
    }
    const codeLegend = lid.split('_').reduce((acc, curr, index) => {
      acc[`index${index}`] = curr;
      return acc;
    }, {});
    // To test whether it contains % or not
    const regex = /\%\w+\%/;
    for (const key in mappings) {
      if (key) {
        const path = mappings[key];
        if (path && regex.test(path)) {
          result[key] = this.code2txt.transform(path, codeLegend);
        }
      }
    }
    return result;
  }

  /**
   * [App initializer]
   * @return [nothing]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  appInit() {
    // console.log('APP_INIT');
  }

  /**
   * [findObjectByPath2  Rewriiten]
   * @param obj valid javascript object
   * @param path property array [property1, property2, ...propertyn]
   * @return value on that path or false
   * @author - SwapnilP
   */
  // findObjectByPath(obj = {}, path: Array<string>) {
  //   let data: any = obj;
  //   for (const prop of path) {
  //     // Base case if data is not object return false
  //     if (!(typeof data === 'object')) {
  //       return false;
  //     }
  //     // step in
  //     if (data && data.hasOwnProperty(prop)) {
  //       data = data[prop];
  //     } else {
  //       return false;
  //     }
  //   }
  //   return data;
  // }

  /**
     * [findObjectByPath using _.get() method from lodash ]
     * @param obj valid javascript object
     * @param path property array [property1, property2, ...propertyn]
     * @return value on that path or false
     * @author - Lalitkumar Saindane
     */
  findObjectByPath(obj = {}, path: Array<string>) {
    // console.log("FIND ME")
    // console.log(obj+'-'+ path)
    const strPath = path.join('.');
    const data = get(obj, strPath);
    return data;
  }

  /**
   * [This method returns an object which has key from legend and value from data]
   * @param legend [legend is an Array of objects with keys: key, path, source and pipe]
   * @param data [data has customGetAPI and entityInformation data]
   * @author Sachin Jagtap
   * @task QLIKTAG-2983
   */
  public resolveLegend(legend: Array<any>, data: Object): Object {
    let resolvedLegend: Object = {};



    resolvedLegend = legend.reduce((accumulator, item) => {

      if (item.hasOwnProperty('key') && item['key']) {

        if (item.hasOwnProperty('value') && item['value'] !== null && item['value'] !== '') {

          accumulator[item['key']] = item['value'];

        } else if (item.hasOwnProperty('path') && item.hasOwnProperty('source')) {
          const path = item['path'].split('.');
          let dataSource: string;

          if (item['source'] === 'customGetAPI') {
            dataSource = 'customGetAPI';

          } else {
            dataSource = 'entityInformation';
          }

          if (data && data.hasOwnProperty(dataSource)) {
            let result = this.findObjectByPath(data[dataSource], path);

            if (item.hasOwnProperty('pipe') && item['pipe'] && result) {
              if (item['pipe'] === 'dateTime') {

                result = this.diDatePipe.transform(result, 'MM/DD/YYYY HH:MM:SS');
              }
            }
            accumulator[item['key']] = result;
          }
        }
      }

      return accumulator;
    }, {});

    return resolvedLegend;
  }



  /**
   * [resolveItemLegend description]
   * @param  legend [description]
   * @param  data   [description]
   * @author NEIL
   * @return        [description]
   */

  public resolveItemLegend(legend: Array<any>, data: Object): Object {
    let resolvedLegend: Object = {};

    resolvedLegend = legend.reduce((accumulator, item) => {

      if (item.hasOwnProperty('key') && item['key']) {
        if (item.hasOwnProperty('value')) {

          accumulator[item['key']] = item['value'];

        } else if (item.hasOwnProperty('path')) {
          const path = item['path'].split('.');

          if (data) {
            let result = this.findObjectByPath(data, path);

            if (item.hasOwnProperty('pipe') && item['pipe'] && result) {
              if (item['pipe'] === 'dateTime') {

                result = this.diDatePipe.transform(result, 'MM/DD/YYYY HH:MM:SS');
              }
            }
            accumulator[item['key']] = result;
          }
        }
      }

      return accumulator;
    }, {});

    return resolvedLegend;
  }


  /**
   * [replace listcodes in data with corresponding values from _listInformation array]
   * @param [data: diData, customGetAPI]
   * @return [translated data]
   * @author Neil, Lalitkumar
   */
  listCodeTranslate(data) {
    //console.log('BEFORE listCodeTranslate', data);
    //console.log('this.listTranslated', this.listTranslated);

    return data; //NO TRANSLATE by NeilC 30/7/19

  }

  /**
  * [Return icon url for corresponding list code ]
  * @param  listCode [list code string]
  * @param  type     [cloudinaryurl or iconurl]
  * @author NeilC
  * @return          [string]
  */
  getListCodeIcon(item, type: string = 'cloudinaryurl'): string {
    //console.log('getListCodeIcon');
    //console.log(item);
    //console.log(type);
    const lang = this.pageService.appLang$.getValue() || 'en';
    let iconURL = '';
    let match = [];
    let iconItem;

    if (item.hasOwnProperty('additionalInformation')) {
      match = item.additionalInformation.filter(item => item.fieldCode === type);

      if (match.length > 0) {
        iconItem = match.shift();
      }
      //console.log('iconItem',iconItem);
      if (iconItem && iconItem.hasOwnProperty('fieldValue')) {

        iconURL = iconItem.fieldValue.filter(item => item.languageCode.toLowerCase() === lang)
          .reduce((acc, icon) => {
            acc = icon.value;
            return acc;
          }, '');

      }

    }

    return iconURL;
  }




  /**
  * [return list code text from additional information]
  * @param  listCode [list code string]
  * @param  type     [description keu]
  * @author NeilC
  * @return          [string]
  */
  getListCodeText(item, type: string = 'description'): string {
    const lang = this.pageService.appLang$.getValue() || 'en';
    let text = '';
    let match = [];
    let textItem;

    if (item.hasOwnProperty('additionalInformation')) {
      match = item.additionalInformation.filter(item => item.fieldCode === type);

      if (match.length > 0) {
        textItem = match.shift();
      }
      //console.log('iconItem',iconItem);
      if (textItem && textItem.hasOwnProperty('fieldValue')) {

        text = textItem.fieldValue.filter(item => item.languageCode.toLowerCase() === lang)
          .reduce((acc, icon) => {
            acc = icon.value;
            return acc;
          }, '');

      }

    }

    return text;
  }

  /**
   * [Check if an array is multi lingual array if the item contains a languageCode property]
   * @param  data [array to be checked]
   * @author Neil
   * @return      boolean value
   */
  isMultiLangArray(data: Array<any>): boolean {

    let result = false;

    const isMultiLang = (item) => {
      return (item.hasOwnProperty('languageCode') && item.hasOwnProperty('value'))
    };

    result = data.some(isMultiLang);

    //console.log('isMulti', result);

    return result;
  }

  /**
  * [isAssetType checks object or array , if it is assetType ]
  * @param  node [ object / array ]
  * @return      [ if isAssetType true else false ]
  * @task - QLIKTAG-3795
  * @author - SwapnilP
  */
  isAssetType(node: any): boolean {
    let result = false;
    if (Array.isArray(node)) {
      result = node.some(item => {
        return item.hasOwnProperty('type') && item.hasOwnProperty('url') && item.hasOwnProperty('languageCode');
      });
    } else {
      if (node && node.hasOwnProperty('type') && node.hasOwnProperty('url') && node.hasOwnProperty('languageCode')) {
        result = true;
      }
    }
    return result;
  }

  /**
   * [getSegments converts string url to object]
   * @param  url [string url p/home?q=1#abc ]
   * @return     [object {url: 'p/home' , queryParams: 'q=1' , fragment: 'abc' }]
   * @author - SwapnilP
   * @task - QLIKTAG-2922
   */
  getSegments(url: string): { url?: string, queryParams?: string, fragment?: string } {
    let result: any = {};
    if (url.includes('?') && url.includes('#')) {
      const segments = url.split(/[?|#]/);
      result = {
        url: segments[0],
        queryParams: segments[1],
        fragment: segments[2]
      };
    } else if (url.includes('?')) {
      const segments = url.split('?');
      result = {
        url: segments[0],
        queryParams: segments[1]
      };
    } else if (url.includes('#')) {
      const segments = url.split('#');
      result = {
        url: segments[0],
        fragment: segments[1]
      };
    } else {
      result = {
        url: url
      };
    }
    return result;
  }

  /**
  * [InternetExplorerVersion detection 10 or 11]
  * @return  [boolean value ]
  * @author - Lalitkumar Saindane
  * @task - QLIKTAG-3152
  */
  getInternetExplorerVersion() {
    let rv = -1;
    const userAgent = navigator.userAgent;

    if (navigator.appName === 'Microsoft Internet Explorer') {
      const re = new RegExp('MSIE ([0-9]{1,}[\\.0-9]{0,})');

      if (re.exec(userAgent) !== null) {
        rv = parseFloat(RegExp.$1);
      }
    } else if (navigator.appName === 'Netscape') {
      const re = new RegExp('Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})');

      if (re.exec(userAgent) !== null) {
        rv = parseFloat(RegExp.$1);
      }
    }
    return rv;
  }

  /**
   * [getListItemMap - Flattens listInformation Array and thens groups them by listitemCode ]
   * @param  listInformation [ listInformation Array ]
   * @return                 [ Map of listItemCode => [ listitem, listitem ] ]
   * @author - SwapnilP
   * @task - QLIKTAG-3292
   */
  getListItemMap(listInformation: Array<any> = []): { [key: string]: Array<any> } {
    const listItems = listInformation.reduce((accumulator, item) => {
      accumulator = accumulator.concat(item.listItems);
      return accumulator;
    }, []);
    const listItemMap = groupBy(listItems, 'listItemCode');
    //console.log('FLAT MAP', listItemMap);
    return listItemMap;
  }

  /**
   * Method to get list of all the languages supported by the system and their names with languageCode and assign it to pageService diLanguageList
   * @return [ Array<any> ]
   * @author Abhishek
   * @task QLIKTAG-3253
   */
  getAvailableLanguages(): Observable<any> {

    //Debug starts
    // const { data } = DI_LANG
    // return of(data.languages);
    //Debug ends

    let result = this.apiService.get(`languages`)
      .pipe(
        map(response => {
          const data = response.data;
          return data.languages;
        }),
        catchError((error) => {
          return of([{ "code": "en", "name": "English", "rtl": false }])
        })
      );

    return result;
  }

  /**
   * Make Toast notification
   * @author Neil C 
   * @param message 
   * @param config 
   */
  async makeToast(message, config?) {
    const settings = {
      duration: 3000,
      position: "top",
      message,
      ...config
    }
    const toast = await this.toast.create(settings);

    toast.present();


  }

  /**
   * GET DI trans data
   * @author Neil C
   * @returns 
   */
  getTrans(): Observable<any> {

    //DEBUG starts
    // const { data } = DI_TRANS
    // if (data.hasOwnProperty('listItem')) {
    //   const { listItem } = data;
    //   return of(listItem)
    // }
    //DEBUG ends

    let result = this.apiService.get(`list/_ditrans`)
      .pipe(
        map(({ data }) => {
          //console.log(data)
          if (data.hasOwnProperty('listItem')) {
            const { listItem } = data;
            return listItem
          }
        }),
        catchError((error) => {
          return of([])
        })
      );

    return result;
  }
}
