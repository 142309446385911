
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
// import WalletConnect from "@walletconnect/client";
// import QRCodeModal from "@walletconnect/qrcode-modal";

import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class NftService {

  private _state;
  public connector;
  public isWalletConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public openseaAPIEndpoint = 'https://api.opensea.io/api/v2/'; //https://testnets-api.opensea.io/api/v1/
  private _walletAddress = null
  private nftApiKey = environment.nftApiKey;

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = { ...state };
  }

  get walletAddress() {
    return this._walletAddress;
  }

  set walletAddress(addr) {
    this._walletAddress = addr;
  }


  constructor(private apiService: ApiService) { }

  /**
   * Initialize WalletConnect v1
   * @author Neil C
   */
  init() {

    this.openseaAPIEndpoint = `https://testnets-api.opensea.io/api/v2/`;





  }






  /**
   * 
   * @param asset_contract_address 
   * @param token_id 
   * @returns 
   */
  getAsset(token_id: string, asset_contract_address: string, account_address: string, chainValue: any) {
    console.log('openseaAPI', this.openseaAPIEndpoint);
    let tokenId = token_id.replace(/"/g, "");
    let chainName = chainValue.toLowerCase();
    if(chainName === 'polygon'){
      chainName = 'matic'
    }else if(chainName === 'Arbitrum One'){
      chainName = 'arbitrum'
    }
    const path = `${this.openseaAPIEndpoint}chain/${chainName}/contract/${asset_contract_address}/nfts/${tokenId}`;
    console.log({ path })
    return this.apiService.getByPath(path, new HttpParams(), null, { 'X-API-KEY': this.nftApiKey })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      )

  }

}
