import { Component, HostBinding, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import firebase from 'firebase/compat/app';
import { GoogleAuthProvider } from 'firebase/auth';
import { Subject } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  @HostBinding('class') class = 'ion-page';
  inProgress: boolean = true;
  interactionUrl: string; //redirect post login
  hasError: boolean = false;

  user;
  token;
  provider;

  constructor(
    private authService: AuthService,
    public angularFireAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService
  ) {
    this.angularFireAuth.user.subscribe((user) => {
      //console.log('user', user)
      if (user) {
        this.user = user;
      }
    })
  }

  ngOnInit() {

    // this.angularFireAuth.auth.signOut();
    // return;
    this.authService.authDomain = environment.authDomain;

    console.log(this.authService.authDomain)


    const { _p, _r } = this.activatedRoute.snapshot.queryParams;
    if (_p) { //provider
      if (this.authService.providers.indexOf(_p.toLowerCase()) != -1) {
        this.provider = _p;
      } else {
        this.hasError = true;
      }

    }

    if (_r) { //redirect url
      this.interactionUrl = decodeURIComponent(_r);
    }

    this.getRedirectResult();


    this.angularFireAuth.idToken.subscribe((token) => {
      if (token) {
        // console.log('token present', token);
        this.diLogin(this.provider, this.user, token);
      } else {
        if (this.provider) {

          if (!this.hasError) {
            this.signInWithRedirect(this.provider);
          }

        }

      }
    });







  }

  async getRedirectResult() {
    //console.log('get redirect result')
    let result;
    try {
      result = await this.angularFireAuth.getRedirectResult();
    } catch (e) {
      console.log(e)
      this.hasError = true;
      this.inProgress = false;
    }

  }

  /**
   * Firebase signIn with a popup handler
   * @author Neil C 
   * @task QLIKTAG-4052
   * @param provider 
   */
  async signInWithRedirect(provider) {

    this.provider = provider;
    this.inProgress = true;
    let authProvider;
    let result;
    if (provider == 'google') {

      authProvider = new firebase.auth.GoogleAuthProvider();
    } else if (provider == 'twitter') {
      authProvider = new firebase.auth.TwitterAuthProvider();
    } else if (provider == 'facebook') {
      authProvider = new firebase.auth.FacebookAuthProvider();
    }

    //NOTE: HANDLE OTHER PROVIDERS

    //console.log('signInWithRedirect');

    try {

      //const persistance = await this.angularFireAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      // console.log({persistance});
      //result = await this.angularFireAuth.auth.signInWithPopup(authProvider);
      const signInWithRedirect = await this.angularFireAuth.signInWithRedirect(authProvider);
      //console.log({signInWithRedirect});
      this.inProgress = false;
    } catch (err) {
      console.log(err);
      this.inProgress = false;
      const { code, message } = err;
      console.log(message);
      this.hasError = true;
      //NOTE HANDLE ERRORS
    }

  }


  /**
   * DI oauth
   * @param provider 
   * @param user 
   * @param idToken 
   */
  diLogin(provider, user, idToken) {
    //console.log(provider, user, idToken)
    if (!provider || !user || !idToken) {
      //NOTE: Error Handler

    }
    this.inProgress = true;
    const params = {
      data: {
        "grantType": "dilogin",
        "userType": "external",
        "username": user.email,
        "authProvider": provider,
        "externalAuthToken": idToken
      }
    }

    this.authService.diLogin$(params)
      .subscribe(({ data }) => {
        const { ukey } = data;
        //console.log(ukey, data);
        if (this.interactionUrl) {
          const url = new URL(this.interactionUrl);

          //debugger;
          url.searchParams.set('_ukey', ukey);

          //console.log(url.toString());
          this.inProgress = false;
          this.authService.isLoggedIn = true;

          location.href = url.toString();



        } else {
          this.hasError = true;
        }


      }, (err) => {
        this.inProgress = false;
        this.authService.isLoggedIn = false;
        console.log(err);
        this.hasError = true;

      });

  }


  backToInteraction() {

    location.href = this.interactionUrl;
  }

}
