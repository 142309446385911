import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ActionSheetController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LANGUAGE_CODE_FALLBACKS } from 'src/data/mapping';
import { PushNotificationsAccessComponent } from '../components/push-notifications-access/push-notifications-access.component';
import { MultiLangPipe } from '../pipes/multi-lang.pipe';
import { TransPipe } from '../pipes/trans.pipe';
import { AppService } from '../services/app.service';
import { FcmService } from '../services/fcm.service';
import { GatemanService } from '../services/gateman.service';
import { PageService } from '../services/page.service';
import { PushNotificationsService } from '../services/push-notifications.service';
import { LayoutService } from './layout.service';
import { NftService } from '../services/nft.service';
import { WalletConnectService } from '../services/wallet-connect.service';

type ReadingDirection = 'ltr' | 'rtl';

declare const ga: any;




@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription;
  @HostBinding('class') class = 'ion-page';


  actionSheetMenuItems: Array<ActionSheetButton> = [];
  actionSheetMenuConfig = {
    header: 'Menu'
  }

  readingDirection: ReadingDirection = 'ltr' // Default to 'ltr'
  showSearchBox = false;
  vdi: any = {};

  constructor(
    private menuController: MenuController,
    private gatemanService: GatemanService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public location: Location,
    public pageService: PageService,
    private activatedRoute: ActivatedRoute,
    public actionSheetController: ActionSheetController,
    public appService: AppService,
    private multiLangPipe: MultiLangPipe,
    public layoutService: LayoutService,
    private renderer2: Renderer2,
    private meta: Meta,
    private fcm: FcmService,
    private pushNotificationsService: PushNotificationsService,
    public modalController: ModalController,
    private toastController: ToastController,
    public transPipe: TransPipe,
    private swUpdate: SwUpdate,
    private nftService: NftService,
    public walletConnectService: WalletConnectService

  ) {

    if (swUpdate.isEnabled) {
      swUpdate.checkForUpdate()
        .then(() => {
          console.log('checked for update')
        })
        .catch((e) => {
          console.log('check for update error', e)
          console.log({ e });
        });

      swUpdate.available
        .subscribe((event) => {
          console.log(`sw available`);
          swUpdate.activateUpdate().then(() => document.location.reload());
          console.log({ event });
        }, (e) => {
          console.log(`sw available error`);
          console.log({ e });
        })
    }
  }
  /**
  * @author - SwapnilP
  * @task- QLIKTAG-2925
   */
  ngOnInit() {

    //this.pageService.appLanguages = this.activatedRoute.snapshot.data.appLanguages;
    const sandbox = this.activatedRoute.snapshot.queryParams.sandbox;

    let _ukey = this.activatedRoute.snapshot.queryParams._ukey;
    if (_ukey) {
      //remove _ukey from url
      this.router.navigate([], { queryParams: { _ukey: null }, queryParamsHandling: 'merge' });
    }



    if (sandbox) {        // If sandbox, listen for iframe messages.
      this.subscription = fromEvent(window, 'message').subscribe((event: MessageEvent) => {
        //console.log(event.origin, environment.parentOrigin);
        if (event.data) {
          console.log('WEB > IFRAME', event);
          this.layoutService.vdi = event.data;
          this.setVdi(event.data);
        } else {
          //console.log('NO DATA');
        }

        // TEMPORARILY COMMENTED
        // if (event.origin === environment.parentOrigin) {
        //   console.log('MESSAGE', event);
        //   if (event.data) {
        //     // console.log(event.data);
        //     this.setVdi(event.data);
        //   }
        // } else {
        //   console.log('Incorrect Origin');
        //   return false;
        // }
      });

      // Inform parent that iframe is ready to listen.
      if (this.subscription) {
        parent.postMessage({ key: 'APP_READY' }, '*');
      }
    } else {      // If not load data given by resolver.

      const { interactionData } = this.activatedRoute.snapshot.data;
      const { template } = interactionData;
      const { vdi, nfc } = template;
      this.layoutService.vdi = vdi;
      this.pageService.lv = nfc;
      if (vdi && Object.keys(vdi).length > 0) {


        if (!this.digitalLinkRedirect(vdi)) {
          this.setVdi(vdi);
          this.setupAnalytics();
          const { diLayout = {} } = vdi || {};
          //console.log({ vdi, diLayout })
          this.pushNotificationsSetup(vdi);
        }



      } else {
        //DEBUG
        this.router.navigate(['error', 404]);
      }
    }
    // Listen for language change and reset vdi data
    this.activatedRoute.queryParams.pipe(
      map(params => params['lang']),
      distinctUntilChanged()
    ).subscribe((params) => {
      //console.log(params);
      this.setVdi(this.layoutService.vdi);
    });



    // setTimeout(() => {
    //   this.checkUpdates(); 
    // },5000)
  }


  /**
   * digitalLinkRedirect - checks if redirectionURL is present in response and sets variables in page service
   * @param vdi
   * @author - SwapnilP
   * @task - QLIKTAG-3948
   */
  digitalLinkRedirect(vdi: Params = {}) {
    // console.log('redirection', vdi);
    this.pageService.renderPage = true;
    this.pageService.redirectionURL = '';
    if (vdi.hasOwnProperty('redirectionUrl') && vdi.redirectionUrl) {
      this.pageService.redirectionURL = vdi.redirectionUrl;
      this.pageService.renderPage = false;

      if (document.location.href !== this.pageService.redirectionURL) {
        console.log('redirecting to ', this.pageService.redirectionURL)
        document.location.href = this.pageService.redirectionURL;
        return true;
      }


    }

    return false;
  }

  /**
   * [resetVdi - common function to set diLayout, diData on page service and renderHeader and renderFooter]
   * @param  vdi [vdi object]
   * @return     [nothing]
   * @author - SwapnilP
   * @task- QLIKTAG-2925
   */
  setVdi(vdi: any = {}) {

    //console.log('set vdi',{vdi})
    // Before rendering anything, set language on DI side
    const { lang, sandbox } = this.activatedRoute.snapshot.queryParams;
    this.showSearchBox = this.activatedRoute.snapshot.queryParams.searchbox;
    this.vdi = vdi;
    // console.log(vdi);
    if (vdi.hasOwnProperty('diLayout')) {
      if (vdi.diLayout && vdi.diLayout.hasOwnProperty('pageZoom') && !vdi.diLayout.pageZoom) {
        this.meta.updateTag({ name: 'viewport', content: "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" });
        //console.log(this.meta.getTag('name=viewport'));
      }

    }


    if (vdi.hasOwnProperty('diLanguages') && vdi.diLanguages) {
      this.pageService.diLanguages = vdi.diLanguages;
    }

    // Language Fallback Rules
    this.setAppLanguage(lang);
    //console.log('Language set', this.pageService.appLang$.getValue());
    // After getting language code, set language and reading direction on page
    //Abhishek
    this.setReadingDirection(this.pageService.appLang$.getValue());

    if (vdi.hasOwnProperty('diData') && vdi.diData) {
      this.pageService.diData = vdi.diData || {};
      // Creating listItemMap from _listInformation
      if (vdi.diData && vdi.diData.hasOwnProperty('_listInformation') && vdi.diData._listInformation) {
        this.pageService.listItemMap = this.appService.getListItemMap(vdi.diData._listInformation);
        // console.log(this.pageService.listItemMap);
      }

      const { entityCode = null } = vdi.diData;
      if (entityCode) {
        this.pageService.diinstanceId = vdi.diData[`_${entityCode}Id`];
        this.pageService.entityCode = entityCode;
        // console.log(this.pageService.diinstanceId);
      }



    }

    if (vdi.hasOwnProperty('customGetAPIData') && vdi.customGetAPIData) {
      this.pageService.customGetAPIData = vdi.customGetAPIData || {};
    }

    if (vdi.hasOwnProperty('diLayout') && vdi.diLayout) {
      this.pageService.diLayout = vdi.diLayout || {};

      if (this.pageService.diLayout.hasOwnProperty("navigationType")) {
        this.pageService.navigationType = this.pageService.diLayout.navigationType;
      }
      if (this.pageService.navigationType == "2") {
        this.configureMenu()
      }
      this.pageService.renderHeader();
      this.pageService.renderFooter();

      if (vdi.diLayout.hasOwnProperty('styles')) {
        this.pageService.styles = vdi.diLayout.styles;



      }



      // If sandbox, set loading true for 1 second
      if (sandbox) {
        this.appService.isLoading = true;
        setTimeout(() => {
          this.appService.isLoading = false;
        }, 1000);
      }
    }

    if (vdi.hasOwnProperty('ditemplateId') && vdi.ditemplateId) {
      this.pageService.ditemplateId = vdi.ditemplateId;
    }

    //console.log(this.pageService);
  }


  /**
   * setupAnalytics - setup Google Analytics if isAnalyticsEnabled is true
   * @author - SwapnilP
   * @task - QLIKTAG-2722
   */
  setupAnalytics() {
    const { advanced } = this.pageService.diLayout;
    if (advanced) {
      const { analytics: { isAnalyticsEnabled, google } } = advanced;
      if (isAnalyticsEnabled && google) {
        const { trackingId } = google;
        if (trackingId) {
          console.log('Google Analytics Started');
          ga('create', trackingId, 'auto');
          ga('require', 'urlChangeTracker');
          ga('send', 'pageview');
        }
      }
    }
  }

  /**
   * [goToPage - close menu and navigate to clicked page]
   * @param  alias [alias of the clicked page]
   * @return       [description]
   * @author - SwapnilP
   * @task- QLIKTAG-3149
   */
  goToPage(alias: string) {
    this.menuController.close();
    this.gatemanService.routeHub('p/' + alias, { queryParams: { resetQueryParams: 1 } });
  }

  /**
   * [getClass returns classes to be applied on ion-item]
   * @param  page [page object]
   * @return      [class name in string format]
   * @author - SwapnilP
   * @task- QLIKTAG-3149
   */
  getClass(page) {
    setTimeout(() => {
      if (page.alias === this.pageService.pageAlias) {
        return 'active';
      }
    })
  }

  /**
   * Method to generate buttons and add links for actionsheet
   * @return [nothing]
   * @author - Abhishek
   * @task - QLIKTAG-3150
   */
  configureMenu() {

    this.actionSheetMenuItems.length = 0
    for (let page of this.pageService.diLayout.pages) {
      this.actionSheetMenuItems.push(
        {
          text: `${this.multiLangPipe.transform(page.title)}`,
          icon: `${this.pageService.actionSheetIcon(page.icon)}`,
          handler: () => {
            this.gatemanService.routeHub('p/' + page.alias)
          }
        }
      )
    }
    let cancelButtonText = 'Cancel';
    const cancelButtonTrans = this.transPipe.transform('_cancel');

    if (cancelButtonTrans) {
      cancelButtonText = this.multiLangPipe.transform(cancelButtonTrans) as string;
    }

    const headerTrans = this.transPipe.transform('_menu');
    if (headerTrans) {
      this.actionSheetMenuConfig.header = this.multiLangPipe.transform(headerTrans) as string;
    }

    this.actionSheetMenuItems.push({
      text: cancelButtonText || 'Cancel',
      role: 'cancel',
      icon: '',
      handler: () => {
        // console.log('Cancel!');
      }
    });

  }



  /**
   * [Method to set current language & reading direction]
   * @return nothing
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  setReadingDirection(languageCode: string) {
    //console.log('set reading direction', languageCode);
    //console.log(this.pageService.appLanguages);
    let currentLanguageObj = null;
    if (this.pageService.hasOwnProperty('appLanguages') && this.pageService.appLanguages.length) {
      currentLanguageObj = this.pageService.appLanguages.find(lang => {
        if (lang.code.toLowerCase() == languageCode.toLowerCase()) {
          return lang;
        }
      });
    }


    // console.log({currentLanguageObj})

    if (currentLanguageObj) {
      if (currentLanguageObj.hasOwnProperty('rtl')) {
        this.readingDirection = currentLanguageObj.rtl ? 'rtl' : 'ltr'
      }
    }

    this.renderer2.setAttribute(document.querySelector('html'), 'lang', languageCode);
    this.renderer2.setAttribute(document.querySelector('html'), 'dir', this.readingDirection);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit() {

    this.cd.detectChanges();
    //this.updateStyles();
  }

  /**
   * Called when input in search bar changes.
   * @param $event input text hange event from search-bar component
   * @author SwapnilP
   */
  searchtextChange($event) {
    console.log($event);
    const text = $event.target.value;
    this.appService.searchText$.next(text);
  }

  /**
   * setAppLanguage - Apply langauge code based on following rules
   * 1. Present and supported => apply the given.
   * 2. Present and not supported => get first from the fallback that is supported and apply it.
   * 3. Present and not supported and no fallback is supported => apply Template Primary Language.
   * 4. Not present => Apply Template primary Language.
   * @param languageCode language code to pass throeugh rules
   * @author - SwapnilP
   * @task - QLIKTAG-3513
   */
  setAppLanguage(languageCode: string = '') {
    let supportedCodeFound = false;
    if (languageCode) {
      languageCode = languageCode.toLowerCase();
      if (this.isLanguageCodeSupported(this.pageService.diLanguages, languageCode)) {
        supportedCodeFound = true;
        this.pageService.appLang$.next(languageCode);
      } else {
        const fallbackList = this.getFallbackList(languageCode);
        if (fallbackList.length) {
          const supportedFallback = fallbackList.map(lang => lang.toLowerCase()).find((fallbackCode: string) => {
            return this.isLanguageCodeSupported(this.pageService.diLanguages, fallbackCode);
          });
          if (supportedFallback) {
            supportedCodeFound = true;
            this.pageService.appLang$.next(supportedFallback);
          }
        }
      }
    }

    if (!supportedCodeFound) {
      this.applyTemplatePrimaryLanguage();
    }
  }

  /**
   * getFallbackList - Returns list of fall back language codes for the given language code
   * @param languageCode - language code for which fallback list to be returned
   * @returns - List of fallback language codes
   * @author - SwapnilP
   * @task - QLIKTAG-3513
   */
  getFallbackList(languageCode: string): Array<string> {
    let result = [];
    result = LANGUAGE_CODE_FALLBACKS[languageCode] || [];
    return result;
  }

  /**
   * isLanguageCodeSupported - To check if given language code exists in supported list
   * @param supportedList Array of string containing supported language codes
   * @param languageCode Given language code
   * @return if language code supported or not
   * @author - SwapnilP
   * @task - QLIKTAG-3513
   */
  isLanguageCodeSupported(supportedList: Array<string> = [], languageCode: string): boolean {
    return supportedList.map(lang => lang.toLowerCase()).includes(languageCode.toLowerCase());
  }

  /**
   * applyTemplatePrimaryLanguage - Try to apply template's primary language, if not set use default i.e. 'en'
   * @author - SwapnilP
   * @task - QLIKTAG-3513
   */
  applyTemplatePrimaryLanguage() {
    if (this.vdi && this.vdi.hasOwnProperty('diPrimaryLanguage') && this.vdi.diPrimaryLanguage) {
      const primaryLanguage = this.vdi.diPrimaryLanguage;
      this.pageService.appLang$.next(primaryLanguage.toLowerCase());
    } else {
      this.pageService.appLang$.next('en');
    }
  }





  async pushNotificationsSetup(vdi) {

    this.fcm.isSubscribed = false;
    this.pushNotificationsService.isSupported = true;
    //CHECK IF NOTIFICATION AND PUSH MANAGER IS AVAILABLE
    if ('serviceWorker' in navigator && 'PushManager' in window) {

      //CHECK IF NOTIFICATION.PERMISSION IS ALLOWED
      //PERMISSION DENIED
      if (Notification.permission === 'denied') {
        //console.log('PUSH NOTIFICATION PERMISSION DENIED');
        this.pushNotificationsService.isSupported = false;
        return;
      }

      //PERMISSION DEFAULT
      if (Notification.permission === 'default') {
        //console.log('PUSH NOTIFICATION PERMISSION DEFAULT');
        this.fcm.isSubscribed = false;

      }

      //PERMISSION GRANTED
      if (Notification.permission === 'granted') {
        //console.log('PUSH NOTIFICATION PERMISSION GRANTED');
        this.fcm.isSubscribed = true;

        this.onForegroundMessages();
      }


      const { diLayout: layout, diData: data = {}, environment = null, controllerCode = null, ditemplateId = null } = vdi;

      const { advanced: { pushNotifications: config = {} } = {} } = layout;

      //console.log({ config });

      const { isPushNotificationsEnabled = false, delayTimeOfModalDisplay: delay = 5 } = config;

      this.pushNotificationsService.config = {
        config,
        data,
        controllerCode,
        environment,
        ditemplateId
      }

      //CHECK VDI IF PUSH NOTIFICATIONS IS ENABLED
      if (!isPushNotificationsEnabled) {
        this.pushNotificationsService.isSupported = false
        return;
      }

      //GET SAVED LIST FROM LOCAL STORAGE



      //PRESENT MODAL IF PERMISSION = DEFAULT 
      if (!this.fcm.isSubscribed) {

        // CHECK DELAY IF SET AND NOT ALREADY OPEN

        setTimeout(() => {
          if (!this.pushNotificationsService.isPushNotificationModalOpen) {
            this.showPushNotificationModal(this.pushNotificationsService.config)
          }
        }, (delay * 1000))



      }

    } else {
      //NOT SUPPORTED
      console.log('PUSH NOTIFICATIONS NOT SUPPORTED');
      this.pushNotificationsService.isSupported = false
    }
  }


  async showPushNotificationModal({ config, data, controllerCode, environment, ditemplateId }) {

    const modal = await this.modalController.create({
      component: PushNotificationsAccessComponent,
      cssClass: 'push-notifications-access-modal',
      componentProps: {
        config,
        data,
        controllerCode,
        environment,
        ditemplateId
      }
    });
    return await modal.present();
  }


  async onForegroundMessages() {

    this.fcm.onMessage()
      .subscribe(async (payload: any) => {
        console.log('onForegroundMessages payload', payload)

        const { body = '', title = '', click_action = null, image: icon = '' } = payload.data;
        const config = {
          body,
          title,
          icon,
          image: icon,
          requireInteraction: true
        }
        const notification = new Notification(title, config);

        notification.onclick = ($event) => {
          $event.preventDefault();
          console.log($event);
          try {
            const url = new URL(click_action);
            if (url) {
              window.open(url.toString())
            }

          } catch (e) {
            console.log('INVALID URL', e);
          }


          notification.close();

        }
      })
  }


  async checkUpdates() {
    console.log(`checking for a new version ... ${this.swUpdate.isEnabled}`);

    this.swUpdate.checkForUpdate()
      .then(() => {
        console.log('check for update without await')
      })

    try {
      const status = await this.swUpdate.checkForUpdate();
      console.log(`check for update status with await: ${status}`);
    } catch (e) {
      console.log(`sw check for update err: ${e}`)
    }

    //console.log(this.swUpdate)



    this.swUpdate.available
      .subscribe((event) => {
        console.log(`update available?  ${event}`);
        window.location.reload();
      }, (error) => {
        console.log('check for update error', error)
      })




  }

}
